import REPORT_ATTENDANCE_ACTION_TYPES from "./report-attendance.type";

export const REPORT_ATTENDANCE_INITIAL_STATE = {
  reportTotals: null,
  reportAttendances: [],

  isReportAttendancesHasMore: true,

  fetchReportsFilterMarketId: null,
  fetchReportsFilterBranchId: null,
  fetchReportsFilterUserId: null,
  fetchReportsFilterStartAtAfter: null,
  fetchReportsFilterEndAtBefore: null,

  fetchReportTotalsLoading: false,
  fetchReportTotalsSuccess: null,
  fetchReportTotalsFailed: null,

  fetchReportAttendancesSearch: null,
  fetchReportAttendancesSort: null,
  fetchReportAttendancesKeyBy: null,
  fetchReportAttendancesPage: 1,
  fetchReportAttendancesPerPage: null,
  fetchReportAttendancesIncludes: null,
  fetchReportAttendancesLoading: false,
  fetchReportAttendancesSuccess: null,
  fetchReportAttendancesFailed: null,

  isFetchReportTotalsHitted: false,
  isFetchReportAttendancesHitted: false,
};

export const reportAttendanceReducer = (
  state = REPORT_ATTENDANCE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT_ATTENDANCE_ACTION_TYPES.SET_REPORT_TOTALS:
      return { ...state, reportTotals: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.SET_REPORT_ATTENDANCES:
      return { ...state, reportAttendances: payload };

    case REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_REPORT_ATTENDANCES_HAS_MORE:
      return { ...state, isReportAttendancesHasMore: payload };

    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_MARKET_ID:
      return { ...state, fetchReportsFilterMarketId: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_BRANCH_ID:
      return { ...state, fetchReportsFilterBranchId: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_USER_ID:
      return { ...state, fetchReportsFilterUserId: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_START_AT_AFTER:
      return { ...state, fetchReportsFilterStartAtAfter: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORTS_FILTER_END_AT_BEFORE:
      return { ...state, fetchReportsFilterEndAtBefore: payload };

    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_LOADING:
      return { ...state, fetchReportTotalsLoading: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_SUCCESS:
      return { ...state, fetchReportTotalsSuccess: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_FAILED:
      return { ...state, fetchReportTotalsFailed: payload };

    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_SEARCH:
      return { ...state, fetchReportAttendancesSearch: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_SORT:
      return { ...state, fetchReportAttendancesSort: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_KEY_BY:
      return { ...state, fetchReportAttendancesKeyBy: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_PAGE:
      return { ...state, fetchReportAttendancesPage: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_PER_PAGE:
      return { ...state, fetchReportAttendancesPerPage: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_INCLUDES:
      return { ...state, fetchReportAttendancesIncludes: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_LOADING:
      return { ...state, fetchReportAttendancesLoading: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_SUCCESS:
      return { ...state, fetchReportAttendancesSuccess: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_FAILED:
      return { ...state, fetchReportAttendancesFailed: payload };

    case REPORT_ATTENDANCE_ACTION_TYPES.APPEND_REPORT_ATTENDANCES:
      return {
        ...state,
        reportAttendances: [...state.reportAttendances, ...payload],
      };

    case REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_FETCH_REPORT_TOTALS_HITTED:
      return { ...state, isFetchReportTotalsHitted: payload };
    case REPORT_ATTENDANCE_ACTION_TYPES.SET_IS_FETCH_REPORT_ATTENDANCES_HITTED:
      return { ...state, isFetchReportAttendancesHitted: payload };

    case REPORT_ATTENDANCE_ACTION_TYPES.RESET_REPORT_ATTENDANCE_REDUCER:
      return REPORT_ATTENDANCE_INITIAL_STATE;
    default:
      return state;
  }
};
