const DASHBOARD_ACTION_TYPES = {
  RESET_DASHBOARD_REDUCER: "dashboard/RESET_DASHBOARD_REDUCER",

  SET_DASHBOARD: "dashboard/SET_DASHBOARD",

  SET_FETCH_DASHBOARD_FILTER_MARKET_ID: "dashboard/SET_FETCH_DASHBOARD_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_FILTER_BRANCH_ID: "dashboard/SET_FETCH_DASHBOARD_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_FILTER_TIME_PERIOD: "dashboard/SET_FETCH_DASHBOARD_FILTER_TIME_PERIOD",
  SET_FETCH_DASHBOARD_FILTER_CREATED_AT_BEFORE: "dashboard/SET_FETCH_DASHBOARD_FILTER_CREATED_AT_BEFORE",
  SET_FETCH_DASHBOARD_FILTER_CREATED_AT_AFTER: "dashboard/SET_FETCH_DASHBOARD_FILTER_CREATED_AT_AFTER",
  SET_FETCH_DASHBOARD_FILTER_CURRENCY: "dashboard/SET_FETCH_DASHBOARD_FILTER_CURRENCY",
  SET_FETCH_DASHBOARD_LOADING: "dashboard/SET_FETCH_DASHBOARD_LOADING",
  SET_FETCH_DASHBOARD_SUCCESS: "dashboard/SET_FETCH_DASHBOARD_SUCCESS",
  SET_FETCH_DASHBOARD_FAILED: "dashboard/SET_FETCH_DASHBOARD_FAILED",

  SET_IS_FETCH_DASHBOARD_HITTED: "dashboard/SET_IS_FETCH_DASHBOARD_HITTED",

  FETCH_DASHBOARD_START: "dashboard/FETCH_DASHBOARD_START",
};

export default DASHBOARD_ACTION_TYPES;
