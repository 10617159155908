const DASHBOARD_SUBSCRIBER_ACTION_TYPES = {
  RESET_DASHBOARD_SUBSCRIBER_REDUCER:
    "dashboard-subscriber/RESET_DASHBOARD_SUBSCRIBER_REDUCER",

  SET_DASHBOARD_ACTIVE_BRANCHES:
    "dashboard-subscriber/SET_DASHBOARD_ACTIVE_BRANCHES",
  SET_DASHBOARD_LATEST_ORDERS:
    "dashboard-subscriber/SET_DASHBOARD_LATEST_ORDERS",
  SET_DASHBOARD_LATEST_REVIEWS:
    "dashboard-subscriber/SET_DASHBOARD_LATEST_REVIEWS",
  SET_DASHBOARD_ORDERS_CHART: "dashboard-subscriber/SET_DASHBOARD_ORDERS_CHART",
  SET_DASHBOARD_POPULAR_PRODUCTS:
    "dashboard-subscriber/SET_DASHBOARD_POPULAR_PRODUCTS",

  SET_DASHBOARD_ACTIVE_BRANCHES_LOADING:
    "dashboard-subscriber/SET_DASHBOARD_ACTIVE_BRANCHES_LOADING",
  SET_DASHBOARD_LATEST_ORDERS_LOADING:
    "dashboard-subscriber/SET_DASHBOARD_LATEST_ORDERS_LOADING",
  SET_DASHBOARD_LATEST_REVIEWS_LOADING:
    "dashboard-subscriber/SET_DASHBOARD_LATEST_REVIEWS_LOADING",
  SET_DASHBOARD_ORDERS_CHART_LOADING:
    "dashboard-subscriber/SET_DASHBOARD_ORDERS_CHART_LOADING",
  SET_DASHBOARD_POPULAR_PRODUCTS_LOADING:
    "dashboard-subscriber/SET_DASHBOARD_POPULAR_PRODUCTS_LOADING",

  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TIME_PERIOD:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TIME_PERIOD",
  SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING",
  SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS",
  SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED:
    "dashboard-subscriber/SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED",

  SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED:
    "dashboard-subscriber/SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED",

  FETCH_DASHBOARD_SUBSCRIBER_START:
    "dashboard-subscriber/FETCH_DASHBOARD_SUBSCRIBER_START",
};

export default DASHBOARD_SUBSCRIBER_ACTION_TYPES;
