import DASHBOARD_ACTION_TYPES from "./dashboard.type";

export const DASHBOARD_INITIAL_STATE = {
  dashboard: null,

  fetchDashboardFilterMarketId: null,
  fetchDashboardFilterBranchId: null,
  fetchDashboardFilterTimePeriod: null,
  fetchDashboardFilterCreatedAtBefore: null,
  fetchDashboardFilterCreatedAtAfter: null,
  fetchDashboardFilterCurrency: null,
  fetchDashboardLoading: false,
  fetchDashboardSuccess: null,
  fetchDashboardFailed: null,

  isFetchDashboardHitted: false,
};

export const dashboardReducer = (state = DASHBOARD_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_ACTION_TYPES.SET_DASHBOARD:
      return { ...state, dashboard: payload };

    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_MARKET_ID:
      return { ...state, fetchDashboardFilterMarketId: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_BRANCH_ID:
      return { ...state, fetchDashboardFilterBranchId: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_TIME_PERIOD:
      return { ...state, fetchDashboardFilterTimePeriod: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchDashboardFilterCreatedAtBefore: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchDashboardFilterCreatedAtAfter: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FILTER_CURRENCY:
      return { ...state, fetchDashboardFilterCurrency: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_LOADING:
      return { ...state, fetchDashboardLoading: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_SUCCESS:
      return { ...state, fetchDashboardSuccess: payload };
    case DASHBOARD_ACTION_TYPES.SET_FETCH_DASHBOARD_FAILED:
      return { ...state, fetchDashboardFailed: payload };

    case DASHBOARD_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_HITTED:
      return { ...state, isFetchDashboardHitted: payload };

    case DASHBOARD_ACTION_TYPES.RESET_DASHBOARD_REDUCER:
      return DASHBOARD_INITIAL_STATE;
    default:
      return state;
  }
};
