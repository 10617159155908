import SELECT_COMMON_ACTION_TYPES from "./select-common.type";

const SELECT_COMMON_INITIAL_STATE = {
  orderStatuses: [],

  subscriptionPlans: [],
  subscriptionPeriods: [],
  subscriptionStatuses: [],

  reportExportTypes: [],

  discountTypes: [],

  productTypes: [],
  productOptionPrices: [],
  productAdditionalPrices: [],
};
export const selectCommonReducer = (
  state = SELECT_COMMON_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_COMMON_ACTION_TYPES.SET_ORDER_STATUSES:
      return { ...state, orderStatuses: payload };

    case SELECT_COMMON_ACTION_TYPES.SET_SUBSCRIPTION_PLANS:
      return { ...state, subscriptionPlans: payload };
    case SELECT_COMMON_ACTION_TYPES.SET_SUBSCRIPTION_PERIODS:
      return { ...state, subscriptionPeriods: payload };
    case SELECT_COMMON_ACTION_TYPES.SET_SUBSCRIPTION_STATUSES:
      return { ...state, subscriptionStatuses: payload };

    case SELECT_COMMON_ACTION_TYPES.SET_REPORT_EXPORT_TYPES:
      return { ...state, reportExportTypes: payload };

    case SELECT_COMMON_ACTION_TYPES.SET_DISCOUNT_TYPES:
      return { ...state, discountTypes: payload };

    case SELECT_COMMON_ACTION_TYPES.SET_PRODUCT_TYPES:
      return { ...state, productTypes: payload };
    case SELECT_COMMON_ACTION_TYPES.SET_PRODUCT_OPTION_PRICES:
      return { ...state, productOptionPrices: payload };
    case SELECT_COMMON_ACTION_TYPES.SET_PRODUCT_ADDITIONAL_PRICES:
      return { ...state, productAdditionalPrices: payload };

    case SELECT_COMMON_ACTION_TYPES.RESET_SELECT_COMMON_REDUCER:
      return SELECT_COMMON_INITIAL_STATE;
    default:
      return state;
  }
};
