import { LANGUAGE_DATAS, LANGUAGES } from "../../constants/common.constant";

import GLOBAL_ACTION_TYPES from "./global.type";

export const GLOBAL_INITIAL_STATE = {
  isRightToLeft: false,
  isGlobalInitialized: false,
  isGlobalLoading: false,
  currentLanguage: LANGUAGE_DATAS[LANGUAGES.EN],

  authorizePages: [],
  isMarketAdministrator: false,
  isCenterAdministrator: false,

  currentMarket: null,
  currentBranch: null,
  currentMerchant: null,
  currentPermission: null,
  currentSchedules: null,

  currentMarketId: null,
  currentBranchId: null,
  currentMerchantId: null,
  currentPermissionId: null,

  permissionLimitedPages: [],
  isCurrentKitchenActive: false,

  todaySchedule: null,
  isTodayInSchedule: false,

  todayAttendance: null,
  isTodayHasAttendance: false,

  marketPlans: [],
  branchPlans: [],

  isHasActivePlan: false,
  isHasActiveAdvancedPlan: false,
  isHasActiveBasicPlan: false,
  isHasActiveTrialPlan: false,

  isMarketHasPlan: false,
  isMarketHasAdvancedPlan: false,
  isMarketHasBasicPlan: false,
  isMarketHasTrialPlan: false,

  isBranchHasPlan: false,
  isBranchHasAdvancedPlan: false,
  isBranchHasBasicPlan: false,
  isBranchHasTrialPlan: false,

  planBillingCycles: [],
  globalSubscriptions: {},
  isGlobalSubscriptionsHasMore: true,

  fetchCurrentMarketLoading: false,
  fetchCurrentMarketSuccess: null,
  fetchCurrentMarketFailed: null,

  fetchCurrentBranchLoading: false,
  fetchCurrentBranchSuccess: null,
  fetchCurrentBranchFailed: null,

  fetchCurrentMerchantLoading: false,
  fetchCurrentMerchantSuccess: null,
  fetchCurrentMerchantFailed: null,

  fetchCurrentPermissionLoading: false,
  fetchCurrentPermissionSuccess: null,
  fetchCurrentPermissionFailed: null,

  fetchCurrentSchedulesIncludes: null,
  fetchCurrentSchedulesFilterMarketId: null,
  fetchCurrentSchedulesFilterBranchId: null,
  fetchCurrentSchedulesLoading: false,
  fetchCurrentSchedulesSuccess: null,
  fetchCurrentSchedulesFailed: null,

  fetchTodayAttendanceMarketId: null,
  fetchTodayAttendanceBranchId: null,
  fetchTodayAttendanceLoading: false,
  fetchTodayAttendanceSuccess: null,
  fetchTodayAttendanceFailed: null,

  fetchGlobalSubscriptionsSearch: null,
  fetchGlobalSubscriptionsSort: null,
  fetchGlobalSubscriptionsKeyBy: null,
  fetchGlobalSubscriptionsPage: 1,
  fetchGlobalSubscriptionsPerPage: null,
  fetchGlobalSubscriptionsIncludes: null,
  fetchGlobalSubscriptionsLoading: false,
  fetchGlobalSubscriptionsSuccess: null,
  fetchGlobalSubscriptionsFailed: null,

  isFetchCurrentMarketHitted: false,
  isFetchCurrentBranchHitted: false,
  isFetchCurrentMerchantHitted: false,
  isFetchCurrentPermissionHitted: false,
  isFetchCurrentSchedulesHitted: false,
  isFetchTodayAttendanceHitted: false,
  isFetchGlobalSubscriptionsHitted: false,
};

export const globalReducer = (state = GLOBAL_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT:
      return { ...state, isRightToLeft: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_INITIALIZED:
      return { ...state, isGlobalInitialized: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_LOADING:
      return { ...state, isGlobalLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_LANGUAGE:
      return { ...state, currentLanguage: payload };

    case GLOBAL_ACTION_TYPES.SET_AUTHORIZE_PAGES:
      return { ...state, authorizePages: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_ADMINISTRATOR:
      return { ...state, isMarketAdministrator: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_CENTER_ADMINISTRATOR:
      return { ...state, isCenterAdministrator: payload };

    case GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET:
      return { ...state, currentMarket: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH:
      return { ...state, currentBranch: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT:
      return { ...state, currentMerchant: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION:
      return { ...state, currentPermission: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_SCHEDULES:
      return { ...state, currentSchedules: payload };

    case GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET_ID:
      return { ...state, currentMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH_ID:
      return { ...state, currentBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT_ID:
      return { ...state, currentMerchantId: payload };
    case GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION_ID:
      return { ...state, currentPermissionId: payload };

    case GLOBAL_ACTION_TYPES.SET_PERMISSION_LIMITED_PAGES:
      return { ...state, permissionLimitedPages: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_CURRENT_KITCHEN_ACTIVE:
      return { ...state, isCurrentKitchenActive: payload };

    case GLOBAL_ACTION_TYPES.SET_TODAY_SCHEDULE:
      return { ...state, todaySchedule: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_TODAY_IN_SCHEDULE:
      return { ...state, isTodayInSchedule: payload };

    case GLOBAL_ACTION_TYPES.SET_TODAY_ATTENDANCE:
      return { ...state, todayAttendance: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_TODAY_HAS_ATTENDANCE:
      return { ...state, isTodayHasAttendance: payload };

    case GLOBAL_ACTION_TYPES.SET_MARKET_PLANS:
      return { ...state, marketPlans: payload };
    case GLOBAL_ACTION_TYPES.SET_BRANCH_PLANS:
      return { ...state, branchPlans: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_PLAN:
      return { ...state, isHasActivePlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_ADVANCED_PLAN:
      return { ...state, isHasActiveAdvancedPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_BASIC_PLAN:
      return { ...state, isHasActiveBasicPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_TRIAL_PLAN:
      return { ...state, isHasActiveTrialPlan: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_PLAN:
      return { ...state, isMarketHasPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_ADVANCED_PLAN:
      return { ...state, isMarketHasAdvancedPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_BASIC_PLAN:
      return { ...state, isMarketHasBasicPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_TRIAL_PLAN:
      return { ...state, isMarketHasTrialPlan: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_PLAN:
      return { ...state, isBranchHasPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_ADVANCED_PLAN:
      return { ...state, isBranchHasAdvancedPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_BASIC_PLAN:
      return { ...state, isBranchHasBasicPlan: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_TRIAL_PLAN:
      return { ...state, isBranchHasTrialPlan: payload };

    case GLOBAL_ACTION_TYPES.SET_GLOBAL_SUBSCRIPTIONS:
      return { ...state, globalSubscriptions: payload };
    case GLOBAL_ACTION_TYPES.APPEND_GLOBAL_SUBSCRIPTIONS:
      return {
        ...state,
        globalSubscriptions: { ...state.globalSubscriptions, ...payload },
      };
    case GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_SUBSCRIPTIONS_HAS_MORE:
      return { ...state, isGlobalSubscriptionsHasMore: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_LOADING:
      return { ...state, fetchCurrentMarketLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_SUCCESS:
      return { ...state, fetchCurrentMarketSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_FAILED:
      return { ...state, fetchCurrentMarketFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_LOADING:
      return { ...state, fetchCurrentBranchLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_SUCCESS:
      return { ...state, fetchCurrentBranchSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_FAILED:
      return { ...state, fetchCurrentBranchFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_LOADING:
      return { ...state, fetchCurrentMerchantLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_SUCCESS:
      return { ...state, fetchCurrentMerchantSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_FAILED:
      return { ...state, fetchCurrentMerchantFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_LOADING:
      return { ...state, fetchCurrentPermissionLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_SUCCESS:
      return { ...state, fetchCurrentPermissionSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_FAILED:
      return { ...state, fetchCurrentPermissionFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_INCLUDES:
      return { ...state, fetchCurrentSchedulesIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID:
      return { ...state, fetchCurrentSchedulesFilterMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID:
      return { ...state, fetchCurrentSchedulesFilterBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_LOADING:
      return { ...state, fetchCurrentSchedulesLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_SUCCESS:
      return { ...state, fetchCurrentSchedulesSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FAILED:
      return { ...state, fetchCurrentSchedulesFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_MARKET_ID:
      return { ...state, fetchTodayAttendanceMarketId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID:
      return { ...state, fetchTodayAttendanceBranchId: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_LOADING:
      return { ...state, fetchTodayAttendanceLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_SUCCESS:
      return { ...state, fetchTodayAttendanceSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_FAILED:
      return { ...state, fetchTodayAttendanceFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SEARCH:
      return { ...state, fetchGlobalSubscriptionsSearch: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SORT:
      return { ...state, fetchGlobalSubscriptionsSort: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_KEY_BY:
      return { ...state, fetchGlobalSubscriptionsKeyBy: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PAGE:
      return { ...state, fetchGlobalSubscriptionsPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PER_PAGE:
      return { ...state, fetchGlobalSubscriptionsPerPage: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_INCLUDES:
      return { ...state, fetchGlobalSubscriptionsIncludes: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_LOADING:
      return { ...state, fetchGlobalSubscriptionsLoading: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SUCCESS:
      return { ...state, fetchGlobalSubscriptionsSuccess: payload };
    case GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_FAILED:
      return { ...state, fetchGlobalSubscriptionsFailed: payload };

    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MARKET_HITTED:
      return { ...state, isFetchCurrentMarketHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_BRANCH_HITTED:
      return { ...state, isFetchCurrentBranchHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MERCHANT_HITTED:
      return { ...state, isFetchCurrentMerchantHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_PERMISSION_HITTED:
      return { ...state, isFetchCurrentPermissionHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_SCHEDULES_HITTED:
      return { ...state, isFetchCurrentSchedulesHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_TODAY_ATTENDANCE_HITTED:
      return { ...state, isFetchTodayAttendanceHitted: payload };
    case GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_SUBSCRIPTIONS_HITTED:
      return { ...state, isFetchGlobalSubscriptionsHitted: payload };

    case GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER:
      return GLOBAL_INITIAL_STATE;
    default:
      return state;
  }
};
