import ORDER_ACTION_TYPES from "./order.type";
import { createAction } from "../../utils/store.utils";

export const setOrderTableColumns = (orderTableColumns) =>
  createAction(ORDER_ACTION_TYPES.SET_ORDER_TABLE_COLUMNS, orderTableColumns);
export const setOrderTab = (orderTab) => createAction(ORDER_ACTION_TYPES.SET_ORDER_TAB, orderTab);

export const setOrders = (orders) => createAction(ORDER_ACTION_TYPES.SET_ORDERS, orders);
export const setWaitingOrders = (waitingOrders) => createAction(ORDER_ACTION_TYPES.SET_WAITING_ORDERS, waitingOrders);
export const setProcessOrders = (processOrders) => createAction(ORDER_ACTION_TYPES.SET_PROCESS_ORDERS, processOrders);
export const setCompletedOrders = (completedOrders) =>
  createAction(ORDER_ACTION_TYPES.SET_COMPLETED_ORDERS, completedOrders);
export const setReportOrders = (reportOrders) => createAction(ORDER_ACTION_TYPES.SET_REPORT_ORDERS, reportOrders);
export const setCashierOrders = (cashierOrders) => createAction(ORDER_ACTION_TYPES.SET_CASHIER_ORDERS, cashierOrders);
export const setCustomerOrders = (customerOrders) =>
  createAction(ORDER_ACTION_TYPES.SET_CUSTOMER_ORDERS, customerOrders);
export const setLatestOrders = (latestOrders) => createAction(ORDER_ACTION_TYPES.SET_LATEST_ORDERS, latestOrders);
export const setTableOrders = (tableOrders) => createAction(ORDER_ACTION_TYPES.SET_TABLE_ORDERS, tableOrders);

export const setOrder = (order) => createAction(ORDER_ACTION_TYPES.SET_ORDER, order);
export const setCreateOrder = (createOrder) => createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER, createOrder);
export const setUpdateOrder = (updateOrder) => createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER, updateOrder);
export const setCalculateOrder = (calculateOrder) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_ORDER, calculateOrder);
export const setCustomerOrder = (customerOrder) => createAction(ORDER_ACTION_TYPES.SET_CUSTOMER_ORDER, customerOrder);
export const setCheckoutOrder = (checkoutOrder) => createAction(ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER, checkoutOrder);
export const setCalculateCheckoutOrder = (calculateCheckoutOrder) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER, calculateCheckoutOrder);
export const setReturnOrder = (returnOrder) => createAction(ORDER_ACTION_TYPES.SET_RETURN_ORDER, returnOrder);
export const setCalculateReturnOrder = (calculateReturnOrder) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER, calculateReturnOrder);

export const setIsOrdersHasMore = (isOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_ORDERS_HAS_MORE, isOrdersHasMore);
export const setIsWaitingOrdersHasMore = (isWaitingOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_WAITING_ORDERS_HAS_MORE, isWaitingOrdersHasMore);
export const setIsProcessOrdersHasMore = (isProcessOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_PROCESS_ORDERS_HAS_MORE, isProcessOrdersHasMore);
export const setIsCompletedOrdersHasMore = (isCompletedOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_COMPLETED_ORDERS_HAS_MORE, isCompletedOrdersHasMore);
export const setIsReportOrdersHasMore = (isReportOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_REPORT_ORDERS_HAS_MORE, isReportOrdersHasMore);
export const setIsCashierOrdersHasMore = (isCashierOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CASHIER_ORDERS_HAS_MORE, isCashierOrdersHasMore);
export const setIsCustomerOrdersHasMore = (isCustomerOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CUSTOMER_ORDERS_HAS_MORE, isCustomerOrdersHasMore);
export const setIsLatestOrdersHasMore = (isLatestOrdersHasMore) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_LATEST_ORDERS_HAS_MORE, isLatestOrdersHasMore);

export const setFetchOrdersSearch = (fetchOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SEARCH, fetchOrdersSearch);
export const setFetchOrdersSort = (fetchOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SORT, fetchOrdersSort);
export const setFetchOrdersPage = (fetchOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PAGE, fetchOrdersPage);
export const setFetchOrdersPerPage = (fetchOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_PER_PAGE, fetchOrdersPerPage);
export const setFetchOrdersIncludes = (fetchOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_INCLUDES, fetchOrdersIncludes);
export const setFetchOrdersFilterPeriodType = (fetchOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_PERIOD_TYPE, fetchOrdersFilterPeriodType);
export const setFetchOrdersFilterStartAt = (fetchOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_START_AT, fetchOrdersFilterStartAt);
export const setFetchOrdersFilterEndAt = (fetchOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_END_AT, fetchOrdersFilterEndAt);
export const setFetchOrdersFilterTimePeriod = (fetchOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TIME_PERIOD, fetchOrdersFilterTimePeriod);
export const setFetchOrdersFilterCreatedAtBefore = (fetchOrdersFilterCreatedAtBefore) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_BEFORE, fetchOrdersFilterCreatedAtBefore);
export const setFetchOrdersFilterCreatedAtAfter = (fetchOrdersFilterCreatedAtAfter) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CREATED_AT_AFTER, fetchOrdersFilterCreatedAtAfter);
export const setFetchOrdersFilterMarketId = (fetchOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_MARKET_ID, fetchOrdersFilterMarketId);
export const setFetchOrdersFilterBranchId = (fetchOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_BRANCH_ID, fetchOrdersFilterBranchId);
export const setFetchOrdersFilterProductCategoriesIds = (fetchOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchOrdersFilterProductCategoriesIds
  );
export const setFetchOrdersFilterSources = (fetchOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_SOURCES, fetchOrdersFilterSources);
export const setFetchOrdersFilterTypes = (fetchOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TYPES, fetchOrdersFilterTypes);
export const setFetchOrdersFilterStatuses = (fetchOrdersFilterStatuses) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_STATUSES, fetchOrdersFilterStatuses);
export const setFetchOrdersFilterTableId = (fetchOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_TABLE_ID, fetchOrdersFilterTableId);
export const setFetchOrdersFilterCashierId = (fetchOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FILTER_CASHIER_ID, fetchOrdersFilterCashierId);
export const setFetchOrdersLoading = (fetchOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_LOADING, fetchOrdersLoading);
export const setFetchOrdersSuccess = (fetchOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_SUCCESS, fetchOrdersSuccess);
export const setFetchOrdersFailed = (fetchOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDERS_FAILED, fetchOrdersFailed);

export const setFetchWaitingOrdersSearch = (fetchWaitingOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_SEARCH, fetchWaitingOrdersSearch);
export const setFetchWaitingOrdersSort = (fetchWaitingOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_SORT, fetchWaitingOrdersSort);
export const setFetchWaitingOrdersPage = (fetchWaitingOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_PAGE, fetchWaitingOrdersPage);
export const setFetchWaitingOrdersPerPage = (fetchWaitingOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_PER_PAGE, fetchWaitingOrdersPerPage);
export const setFetchWaitingOrdersIncludes = (fetchWaitingOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_INCLUDES, fetchWaitingOrdersIncludes);
export const setFetchWaitingOrdersFilterPeriodType = (fetchWaitingOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_PERIOD_TYPE, fetchWaitingOrdersFilterPeriodType);
export const setFetchWaitingOrdersFilterStartAt = (fetchWaitingOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_START_AT, fetchWaitingOrdersFilterStartAt);
export const setFetchWaitingOrdersFilterEndAt = (fetchWaitingOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_END_AT, fetchWaitingOrdersFilterEndAt);
export const setFetchWaitingOrdersFilterTimePeriod = (fetchWaitingOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_TIME_PERIOD, fetchWaitingOrdersFilterTimePeriod);
export const setFetchWaitingOrdersFilterCreatedAtBefore = (fetchWaitingOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchWaitingOrdersFilterCreatedAtBefore
  );
export const setFetchWaitingOrdersFilterCreatedAtAfter = (fetchWaitingOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchWaitingOrdersFilterCreatedAtAfter
  );
export const setFetchWaitingOrdersFilterMarketId = (fetchWaitingOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_MARKET_ID, fetchWaitingOrdersFilterMarketId);
export const setFetchWaitingOrdersFilterBranchId = (fetchWaitingOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_BRANCH_ID, fetchWaitingOrdersFilterBranchId);
export const setFetchWaitingOrdersFilterProductCategoriesIds = (fetchWaitingOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchWaitingOrdersFilterProductCategoriesIds
  );
export const setFetchWaitingOrdersFilterSources = (fetchWaitingOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_SOURCES, fetchWaitingOrdersFilterSources);
export const setFetchWaitingOrdersFilterTypes = (fetchWaitingOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_TYPES, fetchWaitingOrdersFilterTypes);
export const setFetchWaitingOrdersFilterTableId = (fetchWaitingOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_TABLE_ID, fetchWaitingOrdersFilterTableId);
export const setFetchWaitingOrdersFilterCashierId = (fetchWaitingOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FILTER_CASHIER_ID, fetchWaitingOrdersFilterCashierId);
export const setFetchWaitingOrdersLoading = (fetchWaitingOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_LOADING, fetchWaitingOrdersLoading);
export const setFetchWaitingOrdersSuccess = (fetchWaitingOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_SUCCESS, fetchWaitingOrdersSuccess);
export const setFetchWaitingOrdersFailed = (fetchWaitingOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_WAITING_ORDERS_FAILED, fetchWaitingOrdersFailed);

export const setFetchProcessOrdersSearch = (fetchProcessOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SEARCH, fetchProcessOrdersSearch);
export const setFetchProcessOrdersSort = (fetchProcessOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SORT, fetchProcessOrdersSort);
export const setFetchProcessOrdersPage = (fetchProcessOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PAGE, fetchProcessOrdersPage);
export const setFetchProcessOrdersPerPage = (fetchProcessOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_PER_PAGE, fetchProcessOrdersPerPage);
export const setFetchProcessOrdersIncludes = (fetchProcessOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_INCLUDES, fetchProcessOrdersIncludes);
export const setFetchProcessOrdersFilterPeriodType = (fetchProcessOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PERIOD_TYPE, fetchProcessOrdersFilterPeriodType);
export const setFetchProcessOrdersFilterStartAt = (fetchProcessOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_START_AT, fetchProcessOrdersFilterStartAt);
export const setFetchProcessOrdersFilterEndAt = (fetchProcessOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_END_AT, fetchProcessOrdersFilterEndAt);
export const setFetchProcessOrdersFilterTimePeriod = (fetchProcessOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TIME_PERIOD, fetchProcessOrdersFilterTimePeriod);
export const setFetchProcessOrdersFilterCreatedAtBefore = (fetchProcessOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchProcessOrdersFilterCreatedAtBefore
  );
export const setFetchProcessOrdersFilterCreatedAtAfter = (fetchProcessOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchProcessOrdersFilterCreatedAtAfter
  );
export const setFetchProcessOrdersFilterMarketId = (fetchProcessOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_MARKET_ID, fetchProcessOrdersFilterMarketId);
export const setFetchProcessOrdersFilterBranchId = (fetchProcessOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_BRANCH_ID, fetchProcessOrdersFilterBranchId);
export const setFetchProcessOrdersFilterProductCategoriesIds = (fetchProcessOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchProcessOrdersFilterProductCategoriesIds
  );
export const setFetchProcessOrdersFilterSources = (fetchProcessOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_SOURCES, fetchProcessOrdersFilterSources);
export const setFetchProcessOrdersFilterTypes = (fetchProcessOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TYPES, fetchProcessOrdersFilterTypes);
export const setFetchProcessOrdersFilterTableId = (fetchProcessOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_TABLE_ID, fetchProcessOrdersFilterTableId);
export const setFetchProcessOrdersFilterCashierId = (fetchProcessOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FILTER_CASHIER_ID, fetchProcessOrdersFilterCashierId);
export const setFetchProcessOrdersLoading = (fetchProcessOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_LOADING, fetchProcessOrdersLoading);
export const setFetchProcessOrdersSuccess = (fetchProcessOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_SUCCESS, fetchProcessOrdersSuccess);
export const setFetchProcessOrdersFailed = (fetchProcessOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_PROCESS_ORDERS_FAILED, fetchProcessOrdersFailed);

export const setFetchCompletedOrdersSearch = (fetchCompletedOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SEARCH, fetchCompletedOrdersSearch);
export const setFetchCompletedOrdersSort = (fetchCompletedOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SORT, fetchCompletedOrdersSort);
export const setFetchCompletedOrdersPage = (fetchCompletedOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PAGE, fetchCompletedOrdersPage);
export const setFetchCompletedOrdersPerPage = (fetchCompletedOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_PER_PAGE, fetchCompletedOrdersPerPage);
export const setFetchCompletedOrdersIncludes = (fetchCompletedOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_INCLUDES, fetchCompletedOrdersIncludes);
export const setFetchCompletedOrdersFilterPeriodType = (fetchCompletedOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PERIOD_TYPE, fetchCompletedOrdersFilterPeriodType);
export const setFetchCompletedOrdersFilterStartAt = (fetchCompletedOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_START_AT, fetchCompletedOrdersFilterStartAt);
export const setFetchCompletedOrdersFilterEndAt = (fetchCompletedOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_END_AT, fetchCompletedOrdersFilterEndAt);
export const setFetchCompletedOrdersFilterTimePeriod = (fetchCompletedOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TIME_PERIOD, fetchCompletedOrdersFilterTimePeriod);
export const setFetchCompletedOrdersFilterCreatedAtBefore = (fetchCompletedOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchCompletedOrdersFilterCreatedAtBefore
  );
export const setFetchCompletedOrdersFilterCreatedAtAfter = (fetchCompletedOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchCompletedOrdersFilterCreatedAtAfter
  );
export const setFetchCompletedOrdersFilterMarketId = (fetchCompletedOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_MARKET_ID, fetchCompletedOrdersFilterMarketId);
export const setFetchCompletedOrdersFilterBranchId = (fetchCompletedOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_BRANCH_ID, fetchCompletedOrdersFilterBranchId);
export const setFetchCompletedOrdersFilterProductCategoriesIds = (fetchCompletedOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchCompletedOrdersFilterProductCategoriesIds
  );
export const setFetchCompletedOrdersFilterSources = (fetchCompletedOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_SOURCES, fetchCompletedOrdersFilterSources);
export const setFetchCompletedOrdersFilterTypes = (fetchCompletedOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TYPES, fetchCompletedOrdersFilterTypes);
export const setFetchCompletedOrdersFilterTableId = (fetchCompletedOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_TABLE_ID, fetchCompletedOrdersFilterTableId);
export const setFetchCompletedOrdersFilterCashierId = (fetchCompletedOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FILTER_CASHIER_ID, fetchCompletedOrdersFilterCashierId);
export const setFetchCompletedOrdersLoading = (fetchCompletedOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_LOADING, fetchCompletedOrdersLoading);
export const setFetchCompletedOrdersSuccess = (fetchCompletedOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_SUCCESS, fetchCompletedOrdersSuccess);
export const setFetchCompletedOrdersFailed = (fetchCompletedOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_COMPLETED_ORDERS_FAILED, fetchCompletedOrdersFailed);

export const setFetchReportOrdersSearch = (fetchReportOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_SEARCH, fetchReportOrdersSearch);
export const setFetchReportOrdersSort = (fetchReportOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_SORT, fetchReportOrdersSort);
export const setFetchReportOrdersPage = (fetchReportOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_PAGE, fetchReportOrdersPage);
export const setFetchReportOrdersPerPage = (fetchReportOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_PER_PAGE, fetchReportOrdersPerPage);
export const setFetchReportOrdersIncludes = (fetchReportOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_INCLUDES, fetchReportOrdersIncludes);
export const setFetchReportOrdersFilterPeriodType = (fetchReportOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_PERIOD_TYPE, fetchReportOrdersFilterPeriodType);
export const setFetchReportOrdersFilterStartAt = (fetchReportOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_START_AT, fetchReportOrdersFilterStartAt);
export const setFetchReportOrdersFilterEndAt = (fetchReportOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_END_AT, fetchReportOrdersFilterEndAt);
export const setFetchReportOrdersFilterTimePeriod = (fetchReportOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_TIME_PERIOD, fetchReportOrdersFilterTimePeriod);
export const setFetchReportOrdersFilterCreatedAtBefore = (fetchReportOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchReportOrdersFilterCreatedAtBefore
  );
export const setFetchReportOrdersFilterCreatedAtAfter = (fetchReportOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchReportOrdersFilterCreatedAtAfter
  );
export const setFetchReportOrdersFilterMarketId = (fetchReportOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_MARKET_ID, fetchReportOrdersFilterMarketId);
export const setFetchReportOrdersFilterBranchId = (fetchReportOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_BRANCH_ID, fetchReportOrdersFilterBranchId);
export const setFetchReportOrdersFilterPaymentMethodKey = (fetchReportOrdersFilterPaymentMethodKey) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_PAYMENT_METHOD_KEY,
    fetchReportOrdersFilterPaymentMethodKey
  );
export const setFetchReportOrdersFilterProductCategoriesIds = (fetchReportOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchReportOrdersFilterProductCategoriesIds
  );
export const setFetchReportOrdersFilterSources = (fetchReportOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_SOURCES, fetchReportOrdersFilterSources);
export const setFetchReportOrdersFilterTypes = (fetchReportOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_TYPES, fetchReportOrdersFilterTypes);
export const setFetchReportOrdersFilterStatuses = (fetchReportOrdersFilterStatuses) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_STATUSES, fetchReportOrdersFilterStatuses);
export const setFetchReportOrdersFilterTableId = (fetchReportOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_TABLE_ID, fetchReportOrdersFilterTableId);
export const setFetchReportOrdersFilterCashierId = (fetchReportOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FILTER_CASHIER_ID, fetchReportOrdersFilterCashierId);
export const setFetchReportOrdersLoading = (fetchReportOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_LOADING, fetchReportOrdersLoading);
export const setFetchReportOrdersSuccess = (fetchReportOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_SUCCESS, fetchReportOrdersSuccess);
export const setFetchReportOrdersFailed = (fetchReportOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_REPORT_ORDERS_FAILED, fetchReportOrdersFailed);

export const setFetchCashierOrdersSearch = (fetchCashierOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_SEARCH, fetchCashierOrdersSearch);
export const setFetchCashierOrdersSort = (fetchCashierOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_SORT, fetchCashierOrdersSort);
export const setFetchCashierOrdersPage = (fetchCashierOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_PAGE, fetchCashierOrdersPage);
export const setFetchCashierOrdersPerPage = (fetchCashierOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_PER_PAGE, fetchCashierOrdersPerPage);
export const setFetchCashierOrdersIncludes = (fetchCashierOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_INCLUDES, fetchCashierOrdersIncludes);
export const setFetchCashierOrdersFilterPeriodType = (fetchCashierOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_PERIOD_TYPE, fetchCashierOrdersFilterPeriodType);
export const setFetchCashierOrdersFilterStartAt = (fetchCashierOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_START_AT, fetchCashierOrdersFilterStartAt);
export const setFetchCashierOrdersFilterEndAt = (fetchCashierOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_END_AT, fetchCashierOrdersFilterEndAt);
export const setFetchCashierOrdersFilterTimePeriod = (fetchCashierOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_TIME_PERIOD, fetchCashierOrdersFilterTimePeriod);
export const setFetchCashierOrdersFilterCreatedAtBefore = (fetchCashierOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchCashierOrdersFilterCreatedAtBefore
  );
export const setFetchCashierOrdersFilterCreatedAtAfter = (fetchCashierOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchCashierOrdersFilterCreatedAtAfter
  );
export const setFetchCashierOrdersFilterMarketId = (fetchCashierOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_MARKET_ID, fetchCashierOrdersFilterMarketId);
export const setFetchCashierOrdersFilterBranchId = (fetchCashierOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_BRANCH_ID, fetchCashierOrdersFilterBranchId);
export const setFetchCashierOrdersFilterPaymentMethodKey = (fetchCashierOrdersFilterPaymentMethodKey) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_PAYMENT_METHOD_KEY,
    fetchCashierOrdersFilterPaymentMethodKey
  );
export const setFetchCashierOrdersFilterProductCategoriesIds = (fetchCashierOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchCashierOrdersFilterProductCategoriesIds
  );
export const setFetchCashierOrdersFilterSources = (fetchCashierOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_SOURCES, fetchCashierOrdersFilterSources);
export const setFetchCashierOrdersFilterTypes = (fetchCashierOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_TYPES, fetchCashierOrdersFilterTypes);
export const setFetchCashierOrdersFilterStatuses = (fetchCashierOrdersFilterStatuses) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_STATUSES, fetchCashierOrdersFilterStatuses);
export const setFetchCashierOrdersFilterTableId = (fetchCashierOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_TABLE_ID, fetchCashierOrdersFilterTableId);
export const setFetchCashierOrdersFilterCashierId = (fetchCashierOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FILTER_CASHIER_ID, fetchCashierOrdersFilterCashierId);
export const setFetchCashierOrdersLoading = (fetchCashierOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_LOADING, fetchCashierOrdersLoading);
export const setFetchCashierOrdersSuccess = (fetchCashierOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_SUCCESS, fetchCashierOrdersSuccess);
export const setFetchCashierOrdersFailed = (fetchCashierOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CASHIER_ORDERS_FAILED, fetchCashierOrdersFailed);

export const setFetchCustomerOrdersSearch = (fetchCustomerOrdersSearch) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_SEARCH, fetchCustomerOrdersSearch);
export const setFetchCustomerOrdersSort = (fetchCustomerOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_SORT, fetchCustomerOrdersSort);
export const setFetchCustomerOrdersPage = (fetchCustomerOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_PAGE, fetchCustomerOrdersPage);
export const setFetchCustomerOrdersPerPage = (fetchCustomerOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_PER_PAGE, fetchCustomerOrdersPerPage);
export const setFetchCustomerOrdersIncludes = (fetchCustomerOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_INCLUDES, fetchCustomerOrdersIncludes);
export const setFetchCustomerOrdersFilterPeriodType = (fetchCustomerOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_PERIOD_TYPE, fetchCustomerOrdersFilterPeriodType);
export const setFetchCustomerOrdersFilterStartAt = (fetchCustomerOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_START_AT, fetchCustomerOrdersFilterStartAt);
export const setFetchCustomerOrdersFilterEndAt = (fetchCustomerOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_END_AT, fetchCustomerOrdersFilterEndAt);
export const setFetchCustomerOrdersFilterTimePeriod = (fetchCustomerOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_TIME_PERIOD, fetchCustomerOrdersFilterTimePeriod);
export const setFetchCustomerOrdersFilterCreatedAtBefore = (fetchCustomerOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchCustomerOrdersFilterCreatedAtBefore
  );
export const setFetchCustomerOrdersFilterCreatedAtAfter = (fetchCustomerOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchCustomerOrdersFilterCreatedAtAfter
  );
export const setFetchCustomerOrdersFilterMarketId = (fetchCustomerOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_MARKET_ID, fetchCustomerOrdersFilterMarketId);
export const setFetchCustomerOrdersFilterBranchId = (fetchCustomerOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_BRANCH_ID, fetchCustomerOrdersFilterBranchId);
export const setFetchCustomerOrdersFilterPaymentMethodKey = (fetchCustomerOrdersFilterPaymentMethodKey) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_PAYMENT_METHOD_KEY,
    fetchCustomerOrdersFilterPaymentMethodKey
  );
export const setFetchCustomerOrdersFilterCustomerId = (fetchCustomerOrdersFilterCustomerId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CUSTOMER_ID, fetchCustomerOrdersFilterCustomerId);
export const setFetchCustomerOrdersFilterProductCategoriesIds = (fetchCustomerOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchCustomerOrdersFilterProductCategoriesIds
  );
export const setFetchCustomerOrdersFilterSources = (fetchCustomerOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_SOURCES, fetchCustomerOrdersFilterSources);
export const setFetchCustomerOrdersFilterTypes = (fetchCustomerOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_TYPES, fetchCustomerOrdersFilterTypes);
export const setFetchCustomerOrdersFilterStatuses = (fetchCustomerOrdersFilterStatuses) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_STATUSES, fetchCustomerOrdersFilterStatuses);
export const setFetchCustomerOrdersFilterTableId = (fetchCustomerOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_TABLE_ID, fetchCustomerOrdersFilterTableId);
export const setFetchCustomerOrdersFilterCashierId = (fetchCustomerOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FILTER_CASHIER_ID, fetchCustomerOrdersFilterCashierId);
export const setFetchCustomerOrdersLoading = (fetchCustomerOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_LOADING, fetchCustomerOrdersLoading);
export const setFetchCustomerOrdersSuccess = (fetchCustomerOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_SUCCESS, fetchCustomerOrdersSuccess);
export const setFetchCustomerOrdersFailed = (fetchCustomerOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_CUSTOMER_ORDERS_FAILED, fetchCustomerOrdersFailed);

export const setFetchLatestOrdersSort = (fetchLatestOrdersSort) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_SORT, fetchLatestOrdersSort);
export const setFetchLatestOrdersPage = (fetchLatestOrdersPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_PAGE, fetchLatestOrdersPage);
export const setFetchLatestOrdersPerPage = (fetchLatestOrdersPerPage) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_PER_PAGE, fetchLatestOrdersPerPage);
export const setFetchLatestOrdersIncludes = (fetchLatestOrdersIncludes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_INCLUDES, fetchLatestOrdersIncludes);
export const setFetchLatestOrdersFilterPeriodType = (fetchLatestOrdersFilterPeriodType) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PERIOD_TYPE, fetchLatestOrdersFilterPeriodType);
export const setFetchLatestOrdersFilterStartAt = (fetchLatestOrdersFilterStartAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_START_AT, fetchLatestOrdersFilterStartAt);
export const setFetchLatestOrdersFilterEndAt = (fetchLatestOrdersFilterEndAt) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_END_AT, fetchLatestOrdersFilterEndAt);
export const setFetchLatestOrdersFilterTimePeriod = (fetchLatestOrdersFilterTimePeriod) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_TIME_PERIOD, fetchLatestOrdersFilterTimePeriod);
export const setFetchLatestOrdersFilterCreatedAtBefore = (fetchLatestOrdersFilterCreatedAtBefore) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_BEFORE,
    fetchLatestOrdersFilterCreatedAtBefore
  );
export const setFetchLatestOrdersFilterCreatedAtAfter = (fetchLatestOrdersFilterCreatedAtAfter) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CREATED_AT_AFTER,
    fetchLatestOrdersFilterCreatedAtAfter
  );
export const setFetchLatestOrdersFilterMarketId = (fetchLatestOrdersFilterMarketId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_MARKET_ID, fetchLatestOrdersFilterMarketId);
export const setFetchLatestOrdersFilterBranchId = (fetchLatestOrdersFilterBranchId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_BRANCH_ID, fetchLatestOrdersFilterBranchId);
export const setFetchLatestOrdersFilterProductCategoriesIds = (fetchLatestOrdersFilterProductCategoriesIds) =>
  createAction(
    ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_PRODUCT_CATEGORIES_IDS,
    fetchLatestOrdersFilterProductCategoriesIds
  );
export const setFetchLatestOrdersFilterSources = (fetchLatestOrdersFilterSources) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_SOURCES, fetchLatestOrdersFilterSources);
export const setFetchLatestOrdersFilterTypes = (fetchLatestOrdersFilterTypes) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_TYPES, fetchLatestOrdersFilterTypes);
export const setFetchLatestOrdersFilterStatuses = (fetchLatestOrdersFilterStatuses) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_STATUSES, fetchLatestOrdersFilterStatuses);
export const setFetchLatestOrdersFilterTableId = (fetchLatestOrdersFilterTableId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_TABLE_ID, fetchLatestOrdersFilterTableId);
export const setFetchLatestOrdersFilterCashierId = (fetchLatestOrdersFilterCashierId) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FILTER_CASHIER_ID, fetchLatestOrdersFilterCashierId);
export const setFetchLatestOrdersLoading = (fetchLatestOrdersLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_LOADING, fetchLatestOrdersLoading);
export const setFetchLatestOrdersSuccess = (fetchLatestOrdersSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_SUCCESS, fetchLatestOrdersSuccess);
export const setFetchLatestOrdersFailed = (fetchLatestOrdersFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_LATEST_ORDERS_FAILED, fetchLatestOrdersFailed);

export const setFetchOrderLoading = (fetchOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDER_LOADING, fetchOrderLoading);
export const setFetchOrderSuccess = (fetchOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDER_SUCCESS, fetchOrderSuccess);
export const setFetchOrderFailed = (fetchOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_FETCH_ORDER_FAILED, fetchOrderFailed);

export const setCalculateOrderLoading = (calculateOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_LOADING, calculateOrderLoading);
export const setCalculateOrderSuccess = (calculateOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_SUCCESS, calculateOrderSuccess);
export const setCalculateOrderFailed = (calculateOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_ORDER_FAILED, calculateOrderFailed);

export const setCreateOrderLoading = (createOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER_LOADING, createOrderLoading);
export const setCreateOrderSuccess = (createOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER_SUCCESS, createOrderSuccess);
export const setCreateOrderFailed = (createOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_CREATE_ORDER_FAILED, createOrderFailed);

export const setUpdateOrderLoading = (updateOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER_LOADING, updateOrderLoading);
export const setUpdateOrderSuccess = (updateOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER_SUCCESS, updateOrderSuccess);
export const setUpdateOrderFailed = (updateOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_UPDATE_ORDER_FAILED, updateOrderFailed);

export const setDeleteOrderLoading = (deleteOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_DELETE_ORDER_LOADING, deleteOrderLoading);
export const setDeleteOrderSuccess = (deleteOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_DELETE_ORDER_SUCCESS, deleteOrderSuccess);
export const setDeleteOrderFailed = (deleteOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_DELETE_ORDER_FAILED, deleteOrderFailed);

export const setCheckoutOrderLoading = (checkoutOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER_LOADING, checkoutOrderLoading);
export const setCheckoutOrderSuccess = (checkoutOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER_SUCCESS, checkoutOrderSuccess);
export const setCheckoutOrderFailed = (checkoutOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_CHECKOUT_ORDER_FAILED, checkoutOrderFailed);

export const setCalculateCheckoutOrderLoading = (calculateCheckoutOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER_LOADING, calculateCheckoutOrderLoading);
export const setCalculateCheckoutOrderSuccess = (calculateCheckoutOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER_SUCCESS, calculateCheckoutOrderSuccess);
export const setCalculateCheckoutOrderFailed = (calculateCheckoutOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_CHECKOUT_ORDER_FAILED, calculateCheckoutOrderFailed);

export const setReturnOrderLoading = (returnOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_RETURN_ORDER_LOADING, returnOrderLoading);
export const setReturnOrderSuccess = (returnOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_RETURN_ORDER_SUCCESS, returnOrderSuccess);
export const setReturnOrderFailed = (returnOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_RETURN_ORDER_FAILED, returnOrderFailed);

export const setCalculateReturnOrderLoading = (calculateReturnOrderLoading) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER_LOADING, calculateReturnOrderLoading);
export const setCalculateReturnOrderSuccess = (calculateReturnOrderSuccess) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER_SUCCESS, calculateReturnOrderSuccess);
export const setCalculateReturnOrderFailed = (calculateReturnOrderFailed) =>
  createAction(ORDER_ACTION_TYPES.SET_CALCULATE_RETURN_ORDER_FAILED, calculateReturnOrderFailed);

export const appendOrders = (orders) => createAction(ORDER_ACTION_TYPES.APPEND_ORDERS, orders);
export const appendWaitingOrders = (waitingOrders) =>
  createAction(ORDER_ACTION_TYPES.APPEND_WAITING_ORDERS, waitingOrders);
export const appendProcessOrders = (processOrders) =>
  createAction(ORDER_ACTION_TYPES.APPEND_PROCESS_ORDERS, processOrders);
export const appendCompletedOrders = (completedOrders) =>
  createAction(ORDER_ACTION_TYPES.APPEND_COMPLETED_ORDERS, completedOrders);
export const appendReportOrders = (reportOrders) => createAction(ORDER_ACTION_TYPES.APPEND_REPORT_ORDERS, reportOrders);
export const appendCashierOrders = (cashierOrders) =>
  createAction(ORDER_ACTION_TYPES.APPEND_CASHIER_ORDERS, cashierOrders);
export const appendCustomerOrders = (customerOrders) =>
  createAction(ORDER_ACTION_TYPES.APPEND_CUSTOMER_ORDERS, customerOrders);

export const appendLatestOrders = (latestOrders) => createAction(ORDER_ACTION_TYPES.APPEND_LATEST_ORDERS, latestOrders);
export const fetchOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_ORDERS_START);
export const fetchWaitingOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_WAITING_ORDERS_START);
export const fetchProcessOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_PROCESS_ORDERS_START);
export const fetchCompletedOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_COMPLETED_ORDERS_START);
export const fetchReportOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_REPORT_ORDERS_START);
export const fetchCashierOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_CASHIER_ORDERS_START);
export const fetchCustomerOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_CUSTOMER_ORDERS_START);
export const fetchLatestOrdersStart = () => createAction(ORDER_ACTION_TYPES.FETCH_LATEST_ORDERS_START);

export const fetchOrderStart = (id) => createAction(ORDER_ACTION_TYPES.FETCH_ORDER_START, id);
export const calculateOrderStart = (request) => createAction(ORDER_ACTION_TYPES.CALCULATE_ORDER_START, request);
export const createOrderStart = (request) => createAction(ORDER_ACTION_TYPES.CREATE_ORDER_START, request);
export const updateOrderStart = (id, request) => createAction(ORDER_ACTION_TYPES.UPDATE_ORDER_START, { id, request });
export const deleteOrderStart = (id) => createAction(ORDER_ACTION_TYPES.DELETE_ORDER_START, id);
export const checkoutOrderStart = (id, request) =>
  createAction(ORDER_ACTION_TYPES.CHECKOUT_ORDER_START, { id, request });
export const calculateCheckoutOrderStart = (request) =>
  createAction(ORDER_ACTION_TYPES.CALCULATE_CHECKOUT_ORDER_START, request);
export const returnOrderStart = (id, request) => createAction(ORDER_ACTION_TYPES.RETURN_ORDER_START, { id, request });
export const calculateReturnOrderStart = (request) =>
  createAction(ORDER_ACTION_TYPES.CALCULATE_RETURN_ORDER_START, request);

export const setIsFetchOrdersHitted = (isFetchOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_ORDERS_HITTED, isFetchOrdersHitted);
export const setIsFetchWaitingOrdersHitted = (isFetchWaitingOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_WAITING_ORDERS_HITTED, isFetchWaitingOrdersHitted);
export const setIsFetchProcessOrdersHitted = (isFetchProcessOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_PROCESS_ORDERS_HITTED, isFetchProcessOrdersHitted);
export const setIsFetchCompletedOrdersHitted = (isFetchCompletedOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_COMPLETED_ORDERS_HITTED, isFetchCompletedOrdersHitted);
export const setIsFetchReportOrdersHitted = (isFetchReportOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_REPORT_ORDERS_HITTED, isFetchReportOrdersHitted);
export const setIsFetchCashierOrdersHitted = (isFetchCashierOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_CASHIER_ORDERS_HITTED, isFetchCashierOrdersHitted);
export const setIsFetchCustomerOrdersHitted = (isFetchCustomerOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_CUSTOMER_ORDERS_HITTED, isFetchCustomerOrdersHitted);
export const setIsFetchLatestOrdersHitted = (isFetchLatestOrdersHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_LATEST_ORDERS_HITTED, isFetchLatestOrdersHitted);

export const setIsFetchOrderHitted = (isFetchOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_FETCH_ORDER_HITTED, isFetchOrderHitted);
export const setIsCalculateOrderHitted = (isCalculateOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CALCULATE_ORDER_HITTED, isCalculateOrderHitted);
export const setIsCreateOrderHitted = (isCreateOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CREATE_ORDER_HITTED, isCreateOrderHitted);
export const setIsUpdateOrderHitted = (isUpdateOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_UPDATE_ORDER_HITTED, isUpdateOrderHitted);
export const setIsDeleteOrderHitted = (isDeleteOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_DELETE_ORDER_HITTED, isDeleteOrderHitted);
export const setIsCheckoutOrderHitted = (isCheckoutOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CHECKOUT_ORDER_HITTED, isCheckoutOrderHitted);
export const setIsCalculateCheckoutOrderHitted = (isCalculateCheckoutOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CALCULATE_CHECKOUT_ORDER_HITTED, isCalculateCheckoutOrderHitted);
export const setIsReturnOrderHitted = (isReturnOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_RETURN_ORDER_HITTED, isReturnOrderHitted);
export const setIsCalculateReturnOrderHitted = (isCalculateReturnOrderHitted) =>
  createAction(ORDER_ACTION_TYPES.SET_IS_CALCULATE_RETURN_ORDER_HITTED, isCalculateReturnOrderHitted);

export const resetOrderReducer = () => createAction(ORDER_ACTION_TYPES.RESET_ORDER_REDUCER);
