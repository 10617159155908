import { createSelector } from "reselect";

const orderSelector = ({ order }) => order;

export const getOrderTableColumns = createSelector(
  [orderSelector],
  ({ orderTableColumns }) => orderTableColumns
);
export const getOrderSources = createSelector(
  [orderSelector],
  ({ orderSources }) => orderSources
);
export const getOrderServiceTypes = createSelector(
  [orderSelector],
  ({ orderServiceTypes }) => orderServiceTypes
);
export const getOrderTab = createSelector(
  [orderSelector],
  ({ orderTab }) => orderTab
);

export const getOrders = createSelector(
  [orderSelector],
  ({ orders }) => orders
);
export const getWaitingOrders = createSelector(
  [orderSelector],
  ({ waitingOrders }) => waitingOrders
);
export const getProcessOrders = createSelector(
  [orderSelector],
  ({ processOrders }) => processOrders
);
export const getCompletedOrders = createSelector(
  [orderSelector],
  ({ completedOrders }) => completedOrders
);
export const getReportOrders = createSelector(
  [orderSelector],
  ({ reportOrders }) => reportOrders
);
export const getCashierOrders = createSelector(
  [orderSelector],
  ({ cashierOrders }) => cashierOrders
);
export const getCustomerOrders = createSelector(
  [orderSelector],
  ({ customerOrders }) => customerOrders
);
export const getLatestOrders = createSelector(
  [orderSelector],
  ({ latestOrders }) => latestOrders
);
export const getTableOrders = createSelector(
  [orderSelector],
  ({ tableOrders }) => tableOrders
);

export const getOrder = createSelector([orderSelector], ({ order }) => order);
export const getCreateOrder = createSelector(
  [orderSelector],
  ({ createOrder }) => createOrder
);
export const getUpdateOrder = createSelector(
  [orderSelector],
  ({ updateOrder }) => updateOrder
);
export const getCalculateOrder = createSelector(
  [orderSelector],
  ({ calculateOrder }) => calculateOrder
);
export const getCustomerOrder = createSelector(
  [orderSelector],
  ({ customerOrder }) => customerOrder
);
export const getCheckoutOrder = createSelector(
  [orderSelector],
  ({ checkoutOrder }) => checkoutOrder
);
export const getCalculateCheckoutOrder = createSelector(
  [orderSelector],
  ({ calculateCheckoutOrder }) => calculateCheckoutOrder
);
export const getReturnOrder = createSelector(
  [orderSelector],
  ({ returnOrder }) => returnOrder
);
export const getCalculateReturnOrder = createSelector(
  [orderSelector],
  ({ calculateReturnOrder }) => calculateReturnOrder
);

export const getIsOrdersHasMore = createSelector(
  [orderSelector],
  ({ isOrdersHasMore }) => isOrdersHasMore
);
export const getIsWaitingOrdersHasMore = createSelector(
  [orderSelector],
  ({ isWaitingOrdersHasMore }) => isWaitingOrdersHasMore
);
export const getIsProcessOrdersHasMore = createSelector(
  [orderSelector],
  ({ isProcessOrdersHasMore }) => isProcessOrdersHasMore
);
export const getIsCompletedOrdersHasMore = createSelector(
  [orderSelector],
  ({ isCompletedOrdersHasMore }) => isCompletedOrdersHasMore
);
export const getIsReportOrdersHasMore = createSelector(
  [orderSelector],
  ({ isReportOrdersHasMore }) => isReportOrdersHasMore
);
export const getIsCashierOrdersHasMore = createSelector(
  [orderSelector],
  ({ isCashierOrdersHasMore }) => isCashierOrdersHasMore
);
export const getIsCustomerOrdersHasMore = createSelector(
  [orderSelector],
  ({ isCustomerOrdersHasMore }) => isCustomerOrdersHasMore
);
export const getIsLatestOrdersHasMore = createSelector(
  [orderSelector],
  ({ isLatestOrdersHasMore }) => isLatestOrdersHasMore
);

export const getFetchOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchOrdersSearch }) => fetchOrdersSearch
);
export const getFetchOrdersSort = createSelector(
  [orderSelector],
  ({ fetchOrdersSort }) => fetchOrdersSort
);
export const getFetchOrdersPage = createSelector(
  [orderSelector],
  ({ fetchOrdersPage }) => fetchOrdersPage
);
export const getFetchOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchOrdersPerPage }) => fetchOrdersPerPage
);
export const getFetchOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchOrdersIncludes }) => fetchOrdersIncludes
);
export const getFetchOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterPeriodType }) => fetchOrdersFilterPeriodType
);
export const getFetchOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterStartAt }) => fetchOrdersFilterStartAt
);
export const getFetchOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterEndAt }) => fetchOrdersFilterEndAt
);
export const getFetchOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterTimePeriod }) => fetchOrdersFilterTimePeriod
);
export const getFetchOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterCreatedAtBefore }) => fetchOrdersFilterCreatedAtBefore
);
export const getFetchOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterCreatedAtAfter }) => fetchOrdersFilterCreatedAtAfter
);
export const getFetchOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterMarketId }) => fetchOrdersFilterMarketId
);
export const getFetchOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterBranchId }) => fetchOrdersFilterBranchId
);
export const getFetchOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterProductCategoriesIds }) =>
    fetchOrdersFilterProductCategoriesIds
);
export const getFetchOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterSources }) => fetchOrdersFilterSources
);
export const getFetchOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterTypes }) => fetchOrdersFilterTypes
);
export const getFetchOrdersFilterStatuses = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterStatuses }) => fetchOrdersFilterStatuses
);
export const getFetchOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterTableId }) => fetchOrdersFilterTableId
);
export const getFetchOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchOrdersFilterCashierId }) => fetchOrdersFilterCashierId
);
export const getFetchOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchOrdersLoading }) => fetchOrdersLoading
);
export const getFetchOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchOrdersSuccess }) => fetchOrdersSuccess
);
export const getFetchOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchOrdersFailed }) => fetchOrdersFailed
);

export const getFetchWaitingOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersSearch }) => fetchWaitingOrdersSearch
);
export const getFetchWaitingOrdersSort = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersSort }) => fetchWaitingOrdersSort
);
export const getFetchWaitingOrdersPage = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersPage }) => fetchWaitingOrdersPage
);
export const getFetchWaitingOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersPerPage }) => fetchWaitingOrdersPerPage
);
export const getFetchWaitingOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersIncludes }) => fetchWaitingOrdersIncludes
);
export const getFetchWaitingOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterPeriodType }) => fetchWaitingOrdersFilterPeriodType
);
export const getFetchWaitingOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterStartAt }) => fetchWaitingOrdersFilterStartAt
);
export const getFetchWaitingOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterEndAt }) => fetchWaitingOrdersFilterEndAt
);
export const getFetchWaitingOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterTimePeriod }) => fetchWaitingOrdersFilterTimePeriod
);
export const getFetchWaitingOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterCreatedAtBefore }) =>
    fetchWaitingOrdersFilterCreatedAtBefore
);
export const getFetchWaitingOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterCreatedAtAfter }) =>
    fetchWaitingOrdersFilterCreatedAtAfter
);
export const getFetchWaitingOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterMarketId }) => fetchWaitingOrdersFilterMarketId
);
export const getFetchWaitingOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterBranchId }) => fetchWaitingOrdersFilterBranchId
);
export const getFetchWaitingOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterProductCategoriesIds }) =>
    fetchWaitingOrdersFilterProductCategoriesIds
);
export const getFetchWaitingOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterSources }) => fetchWaitingOrdersFilterSources
);
export const getFetchWaitingOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterTypes }) => fetchWaitingOrdersFilterTypes
);
export const getFetchWaitingOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterTableId }) => fetchWaitingOrdersFilterTableId
);
export const getFetchWaitingOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFilterCashierId }) => fetchWaitingOrdersFilterCashierId
);
export const getFetchWaitingOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersLoading }) => fetchWaitingOrdersLoading
);
export const getFetchWaitingOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersSuccess }) => fetchWaitingOrdersSuccess
);
export const getFetchWaitingOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchWaitingOrdersFailed }) => fetchWaitingOrdersFailed
);

export const getFetchProcessOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersSearch }) => fetchProcessOrdersSearch
);
export const getFetchProcessOrdersSort = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersSort }) => fetchProcessOrdersSort
);
export const getFetchProcessOrdersPage = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersPage }) => fetchProcessOrdersPage
);
export const getFetchProcessOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersPerPage }) => fetchProcessOrdersPerPage
);
export const getFetchProcessOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersIncludes }) => fetchProcessOrdersIncludes
);
export const getFetchProcessOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterPeriodType }) => fetchProcessOrdersFilterPeriodType
);
export const getFetchProcessOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterStartAt }) => fetchProcessOrdersFilterStartAt
);
export const getFetchProcessOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterEndAt }) => fetchProcessOrdersFilterEndAt
);
export const getFetchProcessOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterTimePeriod }) => fetchProcessOrdersFilterTimePeriod
);
export const getFetchProcessOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterCreatedAtBefore }) =>
    fetchProcessOrdersFilterCreatedAtBefore
);
export const getFetchProcessOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterCreatedAtAfter }) =>
    fetchProcessOrdersFilterCreatedAtAfter
);
export const getFetchProcessOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterMarketId }) => fetchProcessOrdersFilterMarketId
);
export const getFetchProcessOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterBranchId }) => fetchProcessOrdersFilterBranchId
);
export const getFetchProcessOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterProductCategoriesIds }) =>
    fetchProcessOrdersFilterProductCategoriesIds
);
export const getFetchProcessOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterSources }) => fetchProcessOrdersFilterSources
);
export const getFetchProcessOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterTypes }) => fetchProcessOrdersFilterTypes
);
export const getFetchProcessOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterTableId }) => fetchProcessOrdersFilterTableId
);
export const getFetchProcessOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFilterCashierId }) => fetchProcessOrdersFilterCashierId
);
export const getFetchProcessOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersLoading }) => fetchProcessOrdersLoading
);
export const getFetchProcessOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersSuccess }) => fetchProcessOrdersSuccess
);
export const getFetchProcessOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchProcessOrdersFailed }) => fetchProcessOrdersFailed
);

export const getFetchCompletedOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersSearch }) => fetchCompletedOrdersSearch
);
export const getFetchCompletedOrdersSort = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersSort }) => fetchCompletedOrdersSort
);
export const getFetchCompletedOrdersPage = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersPage }) => fetchCompletedOrdersPage
);
export const getFetchCompletedOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersPerPage }) => fetchCompletedOrdersPerPage
);
export const getFetchCompletedOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersIncludes }) => fetchCompletedOrdersIncludes
);
export const getFetchCompletedOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterPeriodType }) =>
    fetchCompletedOrdersFilterPeriodType
);
export const getFetchCompletedOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterStartAt }) => fetchCompletedOrdersFilterStartAt
);
export const getFetchCompletedOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterEndAt }) => fetchCompletedOrdersFilterEndAt
);
export const getFetchCompletedOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterTimePeriod }) =>
    fetchCompletedOrdersFilterTimePeriod
);
export const getFetchCompletedOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterCreatedAtBefore }) =>
    fetchCompletedOrdersFilterCreatedAtBefore
);
export const getFetchCompletedOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterCreatedAtAfter }) =>
    fetchCompletedOrdersFilterCreatedAtAfter
);
export const getFetchCompletedOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterMarketId }) => fetchCompletedOrdersFilterMarketId
);
export const getFetchCompletedOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterBranchId }) => fetchCompletedOrdersFilterBranchId
);
export const getFetchCompletedOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterProductCategoriesIds }) =>
    fetchCompletedOrdersFilterProductCategoriesIds
);
export const getFetchCompletedOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterSources }) => fetchCompletedOrdersFilterSources
);
export const getFetchCompletedOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterTypes }) => fetchCompletedOrdersFilterTypes
);
export const getFetchCompletedOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterTableId }) => fetchCompletedOrdersFilterTableId
);
export const getFetchCompletedOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFilterCashierId }) =>
    fetchCompletedOrdersFilterCashierId
);
export const getFetchCompletedOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersLoading }) => fetchCompletedOrdersLoading
);
export const getFetchCompletedOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersSuccess }) => fetchCompletedOrdersSuccess
);
export const getFetchCompletedOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchCompletedOrdersFailed }) => fetchCompletedOrdersFailed
);

export const getFetchReportOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchReportOrdersSearch }) => fetchReportOrdersSearch
);
export const getFetchReportOrdersSort = createSelector(
  [orderSelector],
  ({ fetchReportOrdersSort }) => fetchReportOrdersSort
);
export const getFetchReportOrdersPage = createSelector(
  [orderSelector],
  ({ fetchReportOrdersPage }) => fetchReportOrdersPage
);
export const getFetchReportOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchReportOrdersPerPage }) => fetchReportOrdersPerPage
);
export const getFetchReportOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchReportOrdersIncludes }) => fetchReportOrdersIncludes
);
export const getFetchReportOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterPeriodType }) => fetchReportOrdersFilterPeriodType
);
export const getFetchReportOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterStartAt }) => fetchReportOrdersFilterStartAt
);
export const getFetchReportOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterEndAt }) => fetchReportOrdersFilterEndAt
);
export const getFetchReportOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterTimePeriod }) => fetchReportOrdersFilterTimePeriod
);
export const getFetchReportOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterCreatedAtBefore }) =>
    fetchReportOrdersFilterCreatedAtBefore
);
export const getFetchReportOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterCreatedAtAfter }) =>
    fetchReportOrdersFilterCreatedAtAfter
);
export const getFetchReportOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterMarketId }) => fetchReportOrdersFilterMarketId
);
export const getFetchReportOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterBranchId }) => fetchReportOrdersFilterBranchId
);
export const getFetchReportOrdersFilterPaymentMethodKey = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterPaymentMethodKey }) =>
    fetchReportOrdersFilterPaymentMethodKey
);
export const getFetchReportOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterProductCategoriesIds }) =>
    fetchReportOrdersFilterProductCategoriesIds
);
export const getFetchReportOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterSources }) => fetchReportOrdersFilterSources
);
export const getFetchReportOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterTypes }) => fetchReportOrdersFilterTypes
);
export const getFetchReportOrdersFilterStatuses = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterStatuses }) => fetchReportOrdersFilterStatuses
);
export const getFetchReportOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterTableId }) => fetchReportOrdersFilterTableId
);
export const getFetchReportOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFilterCashierId }) => fetchReportOrdersFilterCashierId
);
export const getFetchReportOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchReportOrdersLoading }) => fetchReportOrdersLoading
);
export const getFetchReportOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchReportOrdersSuccess }) => fetchReportOrdersSuccess
);
export const getFetchReportOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchReportOrdersFailed }) => fetchReportOrdersFailed
);

export const getFetchCashierOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersSearch }) => fetchCashierOrdersSearch
);

export const getFetchCashierOrdersSort = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersSort }) => fetchCashierOrdersSort
);
export const getFetchCashierOrdersPage = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersPage }) => fetchCashierOrdersPage
);
export const getFetchCashierOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersPerPage }) => fetchCashierOrdersPerPage
);
export const getFetchCashierOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersIncludes }) => fetchCashierOrdersIncludes
);
export const getFetchCashierOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterPeriodType }) => fetchCashierOrdersFilterPeriodType
);
export const getFetchCashierOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterStartAt }) => fetchCashierOrdersFilterStartAt
);
export const getFetchCashierOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterEndAt }) => fetchCashierOrdersFilterEndAt
);
export const getFetchCashierOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterTimePeriod }) => fetchCashierOrdersFilterTimePeriod
);
export const getFetchCashierOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterCreatedAtBefore }) =>
    fetchCashierOrdersFilterCreatedAtBefore
);
export const getFetchCashierOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterCreatedAtAfter }) =>
    fetchCashierOrdersFilterCreatedAtAfter
);
export const getFetchCashierOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterMarketId }) => fetchCashierOrdersFilterMarketId
);
export const getFetchCashierOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterBranchId }) => fetchCashierOrdersFilterBranchId
);
export const getFetchCashierOrdersFilterPaymentMethodKey = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterPaymentMethodKey }) =>
    fetchCashierOrdersFilterPaymentMethodKey
);
export const getFetchCashierOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterProductCategoriesIds }) =>
    fetchCashierOrdersFilterProductCategoriesIds
);
export const getFetchCashierOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterSources }) => fetchCashierOrdersFilterSources
);
export const getFetchCashierOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterTypes }) => fetchCashierOrdersFilterTypes
);
export const getFetchCashierOrdersFilterStatuses = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterStatuses }) => fetchCashierOrdersFilterStatuses
);
export const getFetchCashierOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterTableId }) => fetchCashierOrdersFilterTableId
);
export const getFetchCashierOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFilterCashierId }) => fetchCashierOrdersFilterCashierId
);
export const getFetchCashierOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersLoading }) => fetchCashierOrdersLoading
);
export const getFetchCashierOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersSuccess }) => fetchCashierOrdersSuccess
);
export const getFetchCashierOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchCashierOrdersFailed }) => fetchCashierOrdersFailed
);

export const getFetchCustomerOrdersSearch = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersSearch }) => fetchCustomerOrdersSearch
);
export const getFetchCustomerOrdersSort = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersSort }) => fetchCustomerOrdersSort
);
export const getFetchCustomerOrdersPage = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersPage }) => fetchCustomerOrdersPage
);
export const getFetchCustomerOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersPerPage }) => fetchCustomerOrdersPerPage
);
export const getFetchCustomerOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersIncludes }) => fetchCustomerOrdersIncludes
);
export const getFetchCustomerOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterPeriodType }) =>
    fetchCustomerOrdersFilterPeriodType
);
export const getFetchCustomerOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterStartAt }) => fetchCustomerOrdersFilterStartAt
);
export const getFetchCustomerOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterEndAt }) => fetchCustomerOrdersFilterEndAt
);
export const getFetchCustomerOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterTimePeriod }) =>
    fetchCustomerOrdersFilterTimePeriod
);
export const getFetchCustomerOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterCreatedAtBefore }) =>
    fetchCustomerOrdersFilterCreatedAtBefore
);
export const getFetchCustomerOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterCreatedAtAfter }) =>
    fetchCustomerOrdersFilterCreatedAtAfter
);
export const getFetchCustomerOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterMarketId }) => fetchCustomerOrdersFilterMarketId
);
export const getFetchCustomerOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterBranchId }) => fetchCustomerOrdersFilterBranchId
);
export const getFetchCustomerOrdersFilterPaymentMethodKey = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterPaymentMethodKey }) =>
    fetchCustomerOrdersFilterPaymentMethodKey
);
export const getFetchCustomerOrdersFilterCustomerId = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterCustomerId }) =>
    fetchCustomerOrdersFilterCustomerId
);
export const getFetchCustomerOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterProductCategoriesIds }) =>
    fetchCustomerOrdersFilterProductCategoriesIds
);
export const getFetchCustomerOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterSources }) => fetchCustomerOrdersFilterSources
);
export const getFetchCustomerOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterTypes }) => fetchCustomerOrdersFilterTypes
);
export const getFetchCustomerOrdersFilterStatuses = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterStatuses }) => fetchCustomerOrdersFilterStatuses
);
export const getFetchCustomerOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterTableId }) => fetchCustomerOrdersFilterTableId
);
export const getFetchCustomerOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFilterCashierId }) => fetchCustomerOrdersFilterCashierId
);
export const getFetchCustomerOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersLoading }) => fetchCustomerOrdersLoading
);
export const getFetchCustomerOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersSuccess }) => fetchCustomerOrdersSuccess
);
export const getFetchCustomerOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchCustomerOrdersFailed }) => fetchCustomerOrdersFailed
);

export const getFetchLatestOrdersSort = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersSort }) => fetchLatestOrdersSort
);
export const getFetchLatestOrdersPage = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersPage }) => fetchLatestOrdersPage
);
export const getFetchLatestOrdersPerPage = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersPerPage }) => fetchLatestOrdersPerPage
);
export const getFetchLatestOrdersIncludes = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersIncludes }) => fetchLatestOrdersIncludes
);
export const getFetchLatestOrdersFilterPeriodType = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterPeriodType }) => fetchLatestOrdersFilterPeriodType
);
export const getFetchLatestOrdersFilterStartAt = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterStartAt }) => fetchLatestOrdersFilterStartAt
);
export const getFetchLatestOrdersFilterEndAt = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterEndAt }) => fetchLatestOrdersFilterEndAt
);
export const getFetchLatestOrdersFilterTimePeriod = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterTimePeriod }) => fetchLatestOrdersFilterTimePeriod
);
export const getFetchLatestOrdersFilterCreatedAtBefore = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterCreatedAtBefore }) =>
    fetchLatestOrdersFilterCreatedAtBefore
);
export const getFetchLatestOrdersFilterCreatedAtAfter = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterCreatedAtAfter }) =>
    fetchLatestOrdersFilterCreatedAtAfter
);
export const getFetchLatestOrdersFilterMarketId = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterMarketId }) => fetchLatestOrdersFilterMarketId
);
export const getFetchLatestOrdersFilterBranchId = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterBranchId }) => fetchLatestOrdersFilterBranchId
);
export const getFetchLatestOrdersFilterProductCategoriesIds = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterProductCategoriesIds }) =>
    fetchLatestOrdersFilterProductCategoriesIds
);
export const getFetchLatestOrdersFilterSources = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterSources }) => fetchLatestOrdersFilterSources
);
export const getFetchLatestOrdersFilterTypes = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterTypes }) => fetchLatestOrdersFilterTypes
);
export const getFetchLatestOrdersFilterStatuses = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterStatuses }) => fetchLatestOrdersFilterStatuses
);
export const getFetchLatestOrdersFilterTableId = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterTableId }) => fetchLatestOrdersFilterTableId
);
export const getFetchLatestOrdersFilterCashierId = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFilterCashierId }) => fetchLatestOrdersFilterCashierId
);
export const getFetchLatestOrdersLoading = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersLoading }) => fetchLatestOrdersLoading
);
export const getFetchLatestOrdersSuccess = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersSuccess }) => fetchLatestOrdersSuccess
);
export const getFetchLatestOrdersFailed = createSelector(
  [orderSelector],
  ({ fetchLatestOrdersFailed }) => fetchLatestOrdersFailed
);

export const getFetchOrderLoading = createSelector(
  [orderSelector],
  ({ fetchOrderLoading }) => fetchOrderLoading
);
export const getFetchOrderSuccess = createSelector(
  [orderSelector],
  ({ fetchOrderSuccess }) => fetchOrderSuccess
);
export const getFetchOrderFailed = createSelector(
  [orderSelector],
  ({ fetchOrderFailed }) => fetchOrderFailed
);

export const getCalculateOrderLoading = createSelector(
  [orderSelector],
  ({ calculateOrderLoading }) => calculateOrderLoading
);
export const getCalculateOrderSuccess = createSelector(
  [orderSelector],
  ({ calculateOrderSuccess }) => calculateOrderSuccess
);
export const getCalculateOrderFailed = createSelector(
  [orderSelector],
  ({ calculateOrderFailed }) => calculateOrderFailed
);

export const getCreateOrderLoading = createSelector(
  [orderSelector],
  ({ createOrderLoading }) => createOrderLoading
);
export const getCreateOrderSuccess = createSelector(
  [orderSelector],
  ({ createOrderSuccess }) => createOrderSuccess
);
export const getCreateOrderFailed = createSelector(
  [orderSelector],
  ({ createOrderFailed }) => createOrderFailed
);

export const getUpdateOrderLoading = createSelector(
  [orderSelector],
  ({ updateOrderLoading }) => updateOrderLoading
);
export const getUpdateOrderSuccess = createSelector(
  [orderSelector],
  ({ updateOrderSuccess }) => updateOrderSuccess
);
export const getUpdateOrderFailed = createSelector(
  [orderSelector],
  ({ updateOrderFailed }) => updateOrderFailed
);

export const getDeleteOrderLoading = createSelector(
  [orderSelector],
  ({ deleteOrderLoading }) => deleteOrderLoading
);
export const getDeleteOrderSuccess = createSelector(
  [orderSelector],
  ({ deleteOrderSuccess }) => deleteOrderSuccess
);
export const getDeleteOrderFailed = createSelector(
  [orderSelector],
  ({ deleteOrderFailed }) => deleteOrderFailed
);

export const getCheckoutOrderLoading = createSelector(
  [orderSelector],
  ({ checkoutOrderLoading }) => checkoutOrderLoading
);
export const getCheckoutOrderSuccess = createSelector(
  [orderSelector],
  ({ checkoutOrderSuccess }) => checkoutOrderSuccess
);
export const getCheckoutOrderFailed = createSelector(
  [orderSelector],
  ({ checkoutOrderFailed }) => checkoutOrderFailed
);

export const getCalculateCheckoutOrderLoading = createSelector(
  [orderSelector],
  ({ calculateCheckoutOrderLoading }) => calculateCheckoutOrderLoading
);
export const getCalculateCheckoutOrderSuccess = createSelector(
  [orderSelector],
  ({ calculateCheckoutOrderSuccess }) => calculateCheckoutOrderSuccess
);
export const getCalculateCheckoutOrderFailed = createSelector(
  [orderSelector],
  ({ calculateCheckoutOrderFailed }) => calculateCheckoutOrderFailed
);

export const getReturnOrderLoading = createSelector(
  [orderSelector],
  ({ returnOrderLoading }) => returnOrderLoading
);
export const getReturnOrderSuccess = createSelector(
  [orderSelector],
  ({ returnOrderSuccess }) => returnOrderSuccess
);
export const getReturnOrderFailed = createSelector(
  [orderSelector],
  ({ returnOrderFailed }) => returnOrderFailed
);

export const getCalculateReturnOrderLoading = createSelector(
  [orderSelector],
  ({ calculateReturnOrderLoading }) => calculateReturnOrderLoading
);
export const getCalculateReturnOrderSuccess = createSelector(
  [orderSelector],
  ({ calculateReturnOrderSuccess }) => calculateReturnOrderSuccess
);
export const getCalculateReturnOrderFailed = createSelector(
  [orderSelector],
  ({ calculateReturnOrderFailed }) => calculateReturnOrderFailed
);

export const getIsFetchOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchOrdersHitted }) => isFetchOrdersHitted
);
export const getIsFetchWaitingOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchWaitingOrdersHitted }) => isFetchWaitingOrdersHitted
);
export const getIsFetchProcessOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchProcessOrdersHitted }) => isFetchProcessOrdersHitted
);
export const getIsFetchCompletedOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchCompletedOrdersHitted }) => isFetchCompletedOrdersHitted
);
export const getIsFetchReportOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchReportOrdersHitted }) => isFetchReportOrdersHitted
);
export const getIsFetchCashierOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchCashierOrdersHitted }) => isFetchCashierOrdersHitted
);
export const getIsFetchCustomerOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchCustomerOrdersHitted }) => isFetchCustomerOrdersHitted
);
export const getIsFetchLatestOrdersHitted = createSelector(
  [orderSelector],
  ({ isFetchLatestOrdersHitted }) => isFetchLatestOrdersHitted
);
export const getIsFetchOrderHitted = createSelector(
  [orderSelector],
  ({ isFetchOrderHitted }) => isFetchOrderHitted
);
export const getIsCalculateOrderHitted = createSelector(
  [orderSelector],
  ({ isCalculateOrderHitted }) => isCalculateOrderHitted
);
export const getIsCreateOrderHitted = createSelector(
  [orderSelector],
  ({ isCreateOrderHitted }) => isCreateOrderHitted
);
export const getIsUpdateOrderHitted = createSelector(
  [orderSelector],
  ({ isUpdateOrderHitted }) => isUpdateOrderHitted
);
export const getIsDeleteOrderHitted = createSelector(
  [orderSelector],
  ({ isDeleteOrderHitted }) => isDeleteOrderHitted
);
export const getIsCheckoutOrderHitted = createSelector(
  [orderSelector],
  ({ isCheckoutOrderHitted }) => isCheckoutOrderHitted
);
export const getIsCalculateCheckoutOrderHitted = createSelector(
  [orderSelector],
  ({ isCalculateCheckoutOrderHitted }) => isCalculateCheckoutOrderHitted
);
export const getIsReturnOrderHitted = createSelector(
  [orderSelector],
  ({ isReturnOrderHitted }) => isReturnOrderHitted
);
export const getIsCalculateReturnOrderHitted = createSelector(
  [orderSelector],
  ({ isCalculateReturnOrderHitted }) => isCalculateReturnOrderHitted
);
