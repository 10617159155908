import { takeLatest, put, all, call, select } from "redux-saga/effects";

import ORDER_ACTION_TYPES from "./order.type";

import { ORDER_STATUSES } from "../../constants/order.constant";

import {
  appendCashierOrders,
  appendCompletedOrders,
  appendCustomerOrders,
  appendLatestOrders,
  appendOrders,
  appendProcessOrders,
  appendReportOrders,
  appendWaitingOrders,
  setCalculateCheckoutOrder,
  setCalculateCheckoutOrderFailed,
  setCalculateCheckoutOrderLoading,
  setCalculateCheckoutOrderSuccess,
  setCalculateOrder,
  setCalculateOrderFailed,
  setCalculateOrderLoading,
  setCalculateOrderSuccess,
  setCalculateReturnOrder,
  setCalculateReturnOrderFailed,
  setCalculateReturnOrderLoading,
  setCalculateReturnOrderSuccess,
  setCashierOrders,
  setCheckoutOrderFailed,
  setCheckoutOrderLoading,
  setCheckoutOrderSuccess,
  setCompletedOrders,
  setCreateOrder,
  setCreateOrderFailed,
  setCreateOrderLoading,
  setCreateOrderSuccess,
  setCustomerOrders,
  setDeleteOrderFailed,
  setDeleteOrderLoading,
  setDeleteOrderSuccess,
  setFetchCashierOrdersFailed,
  setFetchCashierOrdersLoading,
  setFetchCashierOrdersPage,
  setFetchCashierOrdersSuccess,
  setFetchCompletedOrdersFailed,
  setFetchCompletedOrdersLoading,
  setFetchCompletedOrdersPage,
  setFetchCompletedOrdersSuccess,
  setFetchCustomerOrdersFailed,
  setFetchCustomerOrdersLoading,
  setFetchCustomerOrdersPage,
  setFetchCustomerOrdersSuccess,
  setFetchLatestOrdersFailed,
  setFetchLatestOrdersLoading,
  setFetchLatestOrdersPage,
  setFetchLatestOrdersSuccess,
  setFetchOrderFailed,
  setFetchOrderLoading,
  setFetchOrdersFailed,
  setFetchOrdersLoading,
  setFetchOrdersPage,
  setFetchOrdersSuccess,
  setFetchOrderSuccess,
  setFetchProcessOrdersFailed,
  setFetchProcessOrdersLoading,
  setFetchProcessOrdersPage,
  setFetchProcessOrdersSuccess,
  setFetchReportOrdersFailed,
  setFetchReportOrdersLoading,
  setFetchReportOrdersPage,
  setFetchReportOrdersSuccess,
  setFetchWaitingOrdersFailed,
  setFetchWaitingOrdersLoading,
  setFetchWaitingOrdersPage,
  setFetchWaitingOrdersSuccess,
  setIsCalculateCheckoutOrderHitted,
  setIsCalculateOrderHitted,
  setIsCalculateReturnOrderHitted,
  setIsCashierOrdersHasMore,
  setIsCheckoutOrderHitted,
  setIsCompletedOrdersHasMore,
  setIsCreateOrderHitted,
  setIsCustomerOrdersHasMore,
  setIsDeleteOrderHitted,
  setIsFetchCashierOrdersHitted,
  setIsFetchCompletedOrdersHitted,
  setIsFetchCustomerOrdersHitted,
  setIsFetchLatestOrdersHitted,
  setIsFetchOrderHitted,
  setIsFetchOrdersHitted,
  setIsFetchProcessOrdersHitted,
  setIsFetchReportOrdersHitted,
  setIsFetchWaitingOrdersHitted,
  setIsLatestOrdersHasMore,
  setIsOrdersHasMore,
  setIsProcessOrdersHasMore,
  setIsReportOrdersHasMore,
  setIsReturnOrderHitted,
  setIsUpdateOrderHitted,
  setIsWaitingOrdersHasMore,
  setLatestOrders,
  setOrder,
  setOrders,
  setProcessOrders,
  setReportOrders,
  setReturnOrder,
  setReturnOrderFailed,
  setReturnOrderLoading,
  setReturnOrderSuccess,
  setUpdateOrder,
  setUpdateOrderFailed,
  setUpdateOrderLoading,
  setUpdateOrderSuccess,
  setWaitingOrders,
} from "./order.action";
import {
  getFetchCashierOrdersFilterBranchId,
  getFetchCashierOrdersFilterCashierId,
  getFetchCashierOrdersFilterEndAt,
  getFetchCashierOrdersFilterMarketId,
  getFetchCashierOrdersFilterPaymentMethodKey,
  getFetchCashierOrdersFilterPeriodType,
  getFetchCashierOrdersFilterProductCategoriesIds,
  getFetchCashierOrdersFilterStartAt,
  getFetchCashierOrdersFilterStatuses,
  getFetchCashierOrdersIncludes,
  getFetchCashierOrdersPage,
  getFetchCashierOrdersPerPage,
  getFetchCashierOrdersSearch,
  getFetchCashierOrdersSort,
  getFetchCompletedOrdersFilterBranchId,
  getFetchCompletedOrdersFilterEndAt,
  getFetchCompletedOrdersFilterMarketId,
  getFetchCompletedOrdersFilterPeriodType,
  getFetchCompletedOrdersFilterProductCategoriesIds,
  getFetchCompletedOrdersFilterStartAt,
  getFetchCompletedOrdersIncludes,
  getFetchCompletedOrdersPage,
  getFetchCompletedOrdersPerPage,
  getFetchCompletedOrdersSearch,
  getFetchCompletedOrdersSort,
  getFetchCustomerOrdersFilterBranchId,
  getFetchCustomerOrdersFilterCustomerId,
  getFetchCustomerOrdersFilterEndAt,
  getFetchCustomerOrdersFilterMarketId,
  getFetchCustomerOrdersFilterPaymentMethodKey,
  getFetchCustomerOrdersFilterPeriodType,
  getFetchCustomerOrdersFilterProductCategoriesIds,
  getFetchCustomerOrdersFilterStartAt,
  getFetchCustomerOrdersFilterStatuses,
  getFetchCustomerOrdersIncludes,
  getFetchCustomerOrdersPage,
  getFetchCustomerOrdersPerPage,
  getFetchCustomerOrdersSearch,
  getFetchCustomerOrdersSort,
  getFetchLatestOrdersFilterBranchId,
  getFetchLatestOrdersFilterEndAt,
  getFetchLatestOrdersFilterMarketId,
  getFetchLatestOrdersFilterPeriodType,
  getFetchLatestOrdersFilterProductCategoriesIds,
  getFetchLatestOrdersFilterStartAt,
  getFetchLatestOrdersFilterStatuses,
  getFetchLatestOrdersIncludes,
  getFetchLatestOrdersPage,
  getFetchLatestOrdersPerPage,
  getFetchLatestOrdersSort,
  getFetchOrdersFilterBranchId,
  getFetchOrdersFilterEndAt,
  getFetchOrdersFilterMarketId,
  getFetchOrdersFilterPeriodType,
  getFetchOrdersFilterProductCategoriesIds,
  getFetchOrdersFilterStartAt,
  getFetchOrdersFilterStatuses,
  getFetchOrdersIncludes,
  getFetchOrdersPage,
  getFetchOrdersPerPage,
  getFetchOrdersSearch,
  getFetchOrdersSort,
  getFetchProcessOrdersFilterBranchId,
  getFetchProcessOrdersFilterEndAt,
  getFetchProcessOrdersFilterMarketId,
  getFetchProcessOrdersFilterPeriodType,
  getFetchProcessOrdersFilterProductCategoriesIds,
  getFetchProcessOrdersFilterStartAt,
  getFetchProcessOrdersIncludes,
  getFetchProcessOrdersPage,
  getFetchProcessOrdersPerPage,
  getFetchProcessOrdersSearch,
  getFetchProcessOrdersSort,
  getFetchReportOrdersFilterBranchId,
  getFetchReportOrdersFilterCashierId,
  getFetchReportOrdersFilterEndAt,
  getFetchReportOrdersFilterMarketId,
  getFetchReportOrdersFilterPaymentMethodKey,
  getFetchReportOrdersFilterPeriodType,
  getFetchReportOrdersFilterProductCategoriesIds,
  getFetchReportOrdersFilterStartAt,
  getFetchReportOrdersFilterStatuses,
  getFetchReportOrdersIncludes,
  getFetchReportOrdersPage,
  getFetchReportOrdersPerPage,
  getFetchReportOrdersSearch,
  getFetchReportOrdersSort,
  getFetchWaitingOrdersFilterBranchId,
  getFetchWaitingOrdersFilterEndAt,
  getFetchWaitingOrdersFilterMarketId,
  getFetchWaitingOrdersFilterPeriodType,
  getFetchWaitingOrdersFilterProductCategoriesIds,
  getFetchWaitingOrdersFilterStartAt,
  getFetchWaitingOrdersIncludes,
  getFetchWaitingOrdersPage,
  getFetchWaitingOrdersPerPage,
  getFetchWaitingOrdersSearch,
  getFetchWaitingOrdersSort,
  getFetchProcessOrdersFilterTypes,
  getFetchOrdersFilterTypes,
  getFetchWaitingOrdersFilterTypes,
  getFetchCompletedOrdersFilterTypes,
  getFetchReportOrdersFilterTypes,
  getFetchCashierOrdersFilterTypes,
  getFetchCustomerOrdersFilterTypes,
  getFetchLatestOrdersFilterTypes,
  getIsFetchOrdersHitted,
  getIsFetchCompletedOrdersHitted,
  getIsFetchProcessOrdersHitted,
  getIsFetchWaitingOrdersHitted,
  getIsFetchReportOrdersHitted,
  getIsFetchLatestOrdersHitted,
  getIsFetchCashierOrdersHitted,
  getIsFetchCustomerOrdersHitted,
  getFetchOrdersFilterCashierId,
  getFetchWaitingOrdersFilterCashierId,
  getFetchProcessOrdersFilterCashierId,
  getFetchCompletedOrdersFilterCashierId,
  getFetchCustomerOrdersFilterCashierId,
  getFetchLatestOrdersFilterCashierId,
  getFetchOrdersFilterSources,
  getFetchWaitingOrdersFilterSources,
  getFetchProcessOrdersFilterSources,
  getFetchCompletedOrdersFilterSources,
  getFetchReportOrdersFilterSources,
  getFetchCashierOrdersFilterSources,
  getFetchCustomerOrdersFilterSources,
  getFetchLatestOrdersFilterSources,
  getFetchOrdersFilterTableId,
  getFetchWaitingOrdersFilterTableId,
  getFetchProcessOrdersFilterTableId,
  getFetchCompletedOrdersFilterTableId,
  getFetchReportOrdersFilterTableId,
  getFetchCashierOrdersFilterTableId,
  getFetchCustomerOrdersFilterTableId,
  getFetchLatestOrdersFilterTableId,
  getFetchOrdersFilterTimePeriod,
  getFetchOrdersFilterCreatedAtBefore,
  getFetchOrdersFilterCreatedAtAfter,
  getFetchWaitingOrdersFilterTimePeriod,
  getFetchWaitingOrdersFilterCreatedAtBefore,
  getFetchWaitingOrdersFilterCreatedAtAfter,
  getFetchProcessOrdersFilterTimePeriod,
  getFetchProcessOrdersFilterCreatedAtBefore,
  getFetchProcessOrdersFilterCreatedAtAfter,
  getFetchCompletedOrdersFilterTimePeriod,
  getFetchCompletedOrdersFilterCreatedAtBefore,
  getFetchCompletedOrdersFilterCreatedAtAfter,
  getFetchReportOrdersFilterTimePeriod,
  getFetchReportOrdersFilterCreatedAtBefore,
  getFetchReportOrdersFilterCreatedAtAfter,
  getFetchCashierOrdersFilterTimePeriod,
  getFetchCashierOrdersFilterCreatedAtBefore,
  getFetchCashierOrdersFilterCreatedAtAfter,
  getFetchCustomerOrdersFilterTimePeriod,
  getFetchCustomerOrdersFilterCreatedAtBefore,
  getFetchCustomerOrdersFilterCreatedAtAfter,
  getFetchLatestOrdersFilterTimePeriod,
  getFetchLatestOrdersFilterCreatedAtBefore,
  getFetchLatestOrdersFilterCreatedAtAfter,
} from "./order.selector";
import {
  setFetchCompletedOrdersPage as setFetchKitchenCompletedOrdersPage,
  setFetchProcessOrdersPage as setFetchKitchenProcessOrdersPage,
} from "../kitchen/kitchen.action";
import {
  getIsFetchCompletedOrdersHitted as getIsFetchKitchenCompletedOrdersHitted,
  getIsFetchProcessOrdersHitted as getIsFetchKitchenProcessOrdersHitted,
} from "../kitchen/kitchen.selector";
import {
  _getCompletedOrders as getKitchenCompletedOrders,
  _getProcessOrders as getKitchenProcessOrders,
} from "../kitchen/kitchen.saga";

import {
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  deleteOrder,
  calculateOrder,
  checkoutOrder,
  returnOrder,
} from "../../api/order.api";

export function* _getOrders() {
  try {
    yield put(setFetchOrdersLoading(true));

    const search = yield select(getFetchOrdersSearch);
    const sort = yield select(getFetchOrdersSort);
    const page = yield select(getFetchOrdersPage);
    const per_page = yield select(getFetchOrdersPerPage);
    const includes = yield select(getFetchOrdersIncludes);
    const period_type = yield select(getFetchOrdersFilterPeriodType);
    const start_at = yield select(getFetchOrdersFilterStartAt);
    const end_at = yield select(getFetchOrdersFilterEndAt);
    const time_period = yield select(getFetchOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchOrdersFilterMarketId);
    const branch_id = yield select(getFetchOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchOrdersFilterSources);
    const types = yield select(getFetchOrdersFilterTypes);
    const statuses = yield select(getFetchOrdersFilterStatuses);
    const table_id = yield select(getFetchOrdersFilterTableId);
    const cashier_id = yield select(getFetchOrdersFilterCashierId);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchOrdersHitted(true));
    yield put(setIsOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendOrders(orders));
    } else {
      yield put(setOrders(orders));
    }

    yield put(setFetchOrdersSuccess(message));
    yield put(setFetchOrdersLoading(false));
  } catch (error) {
    yield put(setFetchOrdersFailed(error));
    yield put(setFetchOrdersLoading(false));
  }
}

export function* _getWaitingOrders() {
  try {
    yield put(setFetchWaitingOrdersLoading(true));

    const search = yield select(getFetchWaitingOrdersSearch);
    const sort = yield select(getFetchWaitingOrdersSort);
    const page = yield select(getFetchWaitingOrdersPage);
    const per_page = yield select(getFetchWaitingOrdersPerPage);
    const includes = yield select(getFetchWaitingOrdersIncludes);
    const period_type = yield select(getFetchWaitingOrdersFilterPeriodType);
    const start_at = yield select(getFetchWaitingOrdersFilterStartAt);
    const end_at = yield select(getFetchWaitingOrdersFilterEndAt);
    const time_period = yield select(getFetchWaitingOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchWaitingOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchWaitingOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchWaitingOrdersFilterMarketId);
    const branch_id = yield select(getFetchWaitingOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchWaitingOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchWaitingOrdersFilterSources);
    const types = yield select(getFetchWaitingOrdersFilterTypes);
    const table_id = yield select(getFetchWaitingOrdersFilterTableId);
    const cashier_id = yield select(getFetchWaitingOrdersFilterCashierId);
    const statuses = [ORDER_STATUSES.WAITING];

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchWaitingOrdersHitted(true));
    yield put(setIsWaitingOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendWaitingOrders(orders));
    } else {
      yield put(setWaitingOrders(orders));
    }

    yield put(setFetchWaitingOrdersSuccess(message));
    yield put(setFetchWaitingOrdersLoading(false));
  } catch (error) {
    yield put(setFetchWaitingOrdersFailed(error));
    yield put(setFetchWaitingOrdersLoading(false));
  }
}

export function* _getProcessOrders() {
  try {
    yield put(setFetchProcessOrdersLoading(true));

    const search = yield select(getFetchProcessOrdersSearch);
    const sort = yield select(getFetchProcessOrdersSort);
    const page = yield select(getFetchProcessOrdersPage);
    const per_page = yield select(getFetchProcessOrdersPerPage);
    const includes = yield select(getFetchProcessOrdersIncludes);
    const period_type = yield select(getFetchProcessOrdersFilterPeriodType);
    const start_at = yield select(getFetchProcessOrdersFilterStartAt);
    const end_at = yield select(getFetchProcessOrdersFilterEndAt);
    const time_period = yield select(getFetchProcessOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchProcessOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchProcessOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchProcessOrdersFilterMarketId);
    const branch_id = yield select(getFetchProcessOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchProcessOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchProcessOrdersFilterSources);
    const types = yield select(getFetchProcessOrdersFilterTypes);
    const table_id = yield select(getFetchProcessOrdersFilterTableId);
    const cashier_id = yield select(getFetchProcessOrdersFilterCashierId);
    const statuses = [ORDER_STATUSES.PROCESS];

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchProcessOrdersHitted(true));
    yield put(setIsProcessOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendProcessOrders(orders));
    } else {
      yield put(setProcessOrders(orders));
    }

    yield put(setFetchProcessOrdersSuccess(message));
    yield put(setFetchProcessOrdersLoading(false));
  } catch (error) {
    yield put(setFetchProcessOrdersFailed(error));
    yield put(setFetchProcessOrdersLoading(false));
  }
}

export function* _getCompletedOrders() {
  try {
    yield put(setFetchCompletedOrdersLoading(true));

    const search = yield select(getFetchCompletedOrdersSearch);
    const sort = yield select(getFetchCompletedOrdersSort);
    const page = yield select(getFetchCompletedOrdersPage);
    const per_page = yield select(getFetchCompletedOrdersPerPage);
    const includes = yield select(getFetchCompletedOrdersIncludes);
    const period_type = yield select(getFetchCompletedOrdersFilterPeriodType);
    const start_at = yield select(getFetchCompletedOrdersFilterStartAt);
    const end_at = yield select(getFetchCompletedOrdersFilterEndAt);
    const time_period = yield select(getFetchCompletedOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchCompletedOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchCompletedOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchCompletedOrdersFilterMarketId);
    const branch_id = yield select(getFetchCompletedOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchCompletedOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchCompletedOrdersFilterSources);
    const types = yield select(getFetchCompletedOrdersFilterTypes);
    const table_id = yield select(getFetchCompletedOrdersFilterTableId);
    const cashier_id = yield select(getFetchCompletedOrdersFilterCashierId);
    const statuses = [ORDER_STATUSES.COMPLETED];

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchCompletedOrdersHitted(true));
    yield put(setIsCompletedOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendCompletedOrders(orders));
    } else {
      yield put(setCompletedOrders(orders));
    }

    yield put(setFetchCompletedOrdersSuccess(message));
    yield put(setFetchCompletedOrdersLoading(false));
  } catch (error) {
    yield put(setFetchCompletedOrdersFailed(error));
    yield put(setFetchCompletedOrdersLoading(false));
  }
}

export function* _getReportOrders() {
  try {
    yield put(setFetchReportOrdersLoading(true));

    const search = yield select(getFetchReportOrdersSearch);
    const sort = yield select(getFetchReportOrdersSort);
    const page = yield select(getFetchReportOrdersPage);
    const per_page = yield select(getFetchReportOrdersPerPage);
    const includes = yield select(getFetchReportOrdersIncludes);
    const period_type = yield select(getFetchReportOrdersFilterPeriodType);
    const start_at = yield select(getFetchReportOrdersFilterStartAt);
    const end_at = yield select(getFetchReportOrdersFilterEndAt);
    const time_period = yield select(getFetchReportOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchReportOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchReportOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchReportOrdersFilterMarketId);
    const branch_id = yield select(getFetchReportOrdersFilterBranchId);
    const payment_method_key = yield select(getFetchReportOrdersFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchReportOrdersFilterCashierId);
    const product_categories_ids = yield select(getFetchReportOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchReportOrdersFilterSources);
    const types = yield select(getFetchReportOrdersFilterTypes);
    const statuses = yield select(getFetchReportOrdersFilterStatuses);
    const table_id = yield select(getFetchReportOrdersFilterTableId);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchReportOrdersHitted(true));
    yield put(setIsReportOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendReportOrders(orders));
    } else {
      yield put(setReportOrders(orders));
    }

    yield put(setFetchReportOrdersSuccess(message));
    yield put(setFetchReportOrdersLoading(false));
  } catch (error) {
    yield put(setFetchReportOrdersFailed(error));
    yield put(setFetchReportOrdersLoading(false));
  }
}

export function* _getCashierOrders() {
  try {
    yield put(setFetchCashierOrdersLoading(true));

    const search = yield select(getFetchCashierOrdersSearch);
    const sort = yield select(getFetchCashierOrdersSort);
    const page = yield select(getFetchCashierOrdersPage);
    const per_page = yield select(getFetchCashierOrdersPerPage);
    const includes = yield select(getFetchCashierOrdersIncludes);
    const period_type = yield select(getFetchCashierOrdersFilterPeriodType);
    const start_at = yield select(getFetchCashierOrdersFilterStartAt);
    const end_at = yield select(getFetchCashierOrdersFilterEndAt);
    const time_period = yield select(getFetchCashierOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchCashierOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchCashierOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchCashierOrdersFilterMarketId);
    const branch_id = yield select(getFetchCashierOrdersFilterBranchId);
    const payment_method_key = yield select(getFetchCashierOrdersFilterPaymentMethodKey);
    const cashier_id = yield select(getFetchCashierOrdersFilterCashierId);
    const product_categories_ids = yield select(getFetchCashierOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchCashierOrdersFilterSources);
    const types = yield select(getFetchCashierOrdersFilterTypes);
    const statuses = yield select(getFetchCashierOrdersFilterStatuses);
    const table_id = yield select(getFetchCashierOrdersFilterTableId);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        payment_method_key,
        cashier_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchCashierOrdersHitted(true));
    yield put(setIsCashierOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendCashierOrders(orders));
    } else {
      yield put(setCashierOrders(orders));
    }

    yield put(setFetchCashierOrdersSuccess(message));
    yield put(setFetchCashierOrdersLoading(false));
  } catch (error) {
    yield put(setFetchCashierOrdersFailed(error));
    yield put(setFetchCashierOrdersLoading(false));
  }
}

export function* _getCustomerOrders() {
  try {
    yield put(setFetchCustomerOrdersLoading(true));

    const search = yield select(getFetchCustomerOrdersSearch);
    const sort = yield select(getFetchCustomerOrdersSort);
    const page = yield select(getFetchCustomerOrdersPage);
    const per_page = yield select(getFetchCustomerOrdersPerPage);
    const includes = yield select(getFetchCustomerOrdersIncludes);
    const period_type = yield select(getFetchCustomerOrdersFilterPeriodType);
    const start_at = yield select(getFetchCustomerOrdersFilterStartAt);
    const end_at = yield select(getFetchCustomerOrdersFilterEndAt);
    const time_period = yield select(getFetchCustomerOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchCustomerOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchCustomerOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchCustomerOrdersFilterMarketId);
    const branch_id = yield select(getFetchCustomerOrdersFilterBranchId);
    const payment_method_key = yield select(getFetchCustomerOrdersFilterPaymentMethodKey);
    const customer_id = yield select(getFetchCustomerOrdersFilterCustomerId);
    const product_categories_ids = yield select(getFetchCustomerOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchCustomerOrdersFilterSources);
    const types = yield select(getFetchCustomerOrdersFilterTypes);
    const statuses = yield select(getFetchCustomerOrdersFilterStatuses);
    const table_id = yield select(getFetchCustomerOrdersFilterTableId);
    const cashier_id = yield select(getFetchCustomerOrdersFilterCashierId);

    const parameters = {
      search,
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        payment_method_key,
        customer_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchCustomerOrdersHitted(true));
    yield put(setIsCustomerOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendCustomerOrders(orders));
    } else {
      yield put(setCustomerOrders(orders));
    }

    yield put(setFetchCustomerOrdersSuccess(message));
    yield put(setFetchCustomerOrdersLoading(false));
  } catch (error) {
    yield put(setFetchCustomerOrdersFailed(error));
    yield put(setFetchCustomerOrdersLoading(false));
  }
}

export function* _getLatestOrders() {
  try {
    yield put(setFetchLatestOrdersLoading(true));

    const sort = yield select(getFetchLatestOrdersSort);
    const page = yield select(getFetchLatestOrdersPage);
    const per_page = yield select(getFetchLatestOrdersPerPage);
    const includes = yield select(getFetchLatestOrdersIncludes);
    const period_type = yield select(getFetchLatestOrdersFilterPeriodType);
    const start_at = yield select(getFetchLatestOrdersFilterStartAt);
    const end_at = yield select(getFetchLatestOrdersFilterEndAt);
    const time_period = yield select(getFetchLatestOrdersFilterTimePeriod);
    const created_at_before = yield select(getFetchLatestOrdersFilterCreatedAtBefore);
    const created_at_after = yield select(getFetchLatestOrdersFilterCreatedAtAfter);
    const market_id = yield select(getFetchLatestOrdersFilterMarketId);
    const branch_id = yield select(getFetchLatestOrdersFilterBranchId);
    const product_categories_ids = yield select(getFetchLatestOrdersFilterProductCategoriesIds);
    const sources = yield select(getFetchLatestOrdersFilterSources);
    const types = yield select(getFetchLatestOrdersFilterTypes);
    const statuses = yield select(getFetchLatestOrdersFilterStatuses);
    const table_id = yield select(getFetchLatestOrdersFilterTableId);
    const cashier_id = yield select(getFetchLatestOrdersFilterCashierId);

    const parameters = {
      sort,
      page,
      per_page,
      includes,
      filter: {
        period_type,
        start_at,
        end_at,
        time_period,
        created_at_before,
        created_at_after,
        market_id,
        branch_id,
        product_categories_ids,
        sources,
        types,
        statuses,
        table_id,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: { data: orders },
    } = yield call(getOrders, parameters);

    yield put(setIsFetchLatestOrdersHitted(true));
    yield put(setIsLatestOrdersHasMore(orders.length > 0));

    if (page > 1) {
      yield put(appendLatestOrders(orders));
    } else {
      yield put(setLatestOrders(orders));
    }

    yield put(setFetchLatestOrdersSuccess(message));
    yield put(setFetchLatestOrdersLoading(false));
  } catch (error) {
    yield put(setFetchLatestOrdersFailed(error));
    yield put(setFetchLatestOrdersLoading(false));
  }
}

export function* _getOrder({ payload: id }) {
  try {
    yield put(setFetchOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(getOrder, id);

    yield put(setIsFetchOrderHitted(true));
    yield put(setOrder(order));

    yield put(setFetchOrderSuccess(message));
    yield put(setFetchOrderLoading(false));
  } catch (error) {
    yield put(setFetchOrderFailed(error));
    yield put(setFetchOrderLoading(false));
  }
}

export function* _calculateOrder({ payload: request }) {
  try {
    yield put(setCalculateOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(calculateOrder, request);

    yield put(setIsCalculateOrderHitted(true));
    yield put(setCalculateOrder(order));

    yield put(setCalculateOrderSuccess(message));
    yield put(setCalculateOrderLoading(false));
  } catch (error) {
    yield put(setCalculateOrderFailed(error));
    yield put(setCalculateOrderLoading(false));
  }
}

export function* _createOrder({ payload: request }) {
  try {
    yield put(setCreateOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(createOrder, request);

    yield put(setIsCreateOrderHitted(true));
    yield put(setCreateOrder(order));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);
    const isFetchWaitingOrdersHitted = yield select(getIsFetchWaitingOrdersHitted);
    const isFetchProcessOrdersHitted = yield select(getIsFetchProcessOrdersHitted);
    const isFetchCompletedOrdersHitted = yield select(getIsFetchCompletedOrdersHitted);
    const isFetchReportOrdersHitted = yield select(getIsFetchReportOrdersHitted);
    const isFetchCashierOrdersHitted = yield select(getIsFetchCashierOrdersHitted);
    const isFetchCustomerOrdersHitted = yield select(getIsFetchCustomerOrdersHitted);
    const isFetchLatestOrdersHitted = yield select(getIsFetchLatestOrdersHitted);
    const isFetchKitchenCompletedOrdersHitted = yield select(getIsFetchKitchenCompletedOrdersHitted);
    const isFetchKitchenProcessOrdersHitted = yield select(getIsFetchKitchenProcessOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }
    if (isFetchCompletedOrdersHitted) {
      yield put(setFetchCompletedOrdersPage(1));
      yield call(_getCompletedOrders);
    }
    if (isFetchProcessOrdersHitted) {
      yield put(setFetchProcessOrdersPage(1));
      yield call(_getProcessOrders);
    }
    if (isFetchWaitingOrdersHitted) {
      yield put(setFetchWaitingOrdersPage(1));
      yield call(_getWaitingOrders);
    }
    if (isFetchReportOrdersHitted) {
      yield put(setFetchReportOrdersPage(1));
      yield call(_getReportOrders);
    }
    if (isFetchCashierOrdersHitted) {
      yield put(setFetchCashierOrdersPage(1));
      yield call(_getCashierOrders);
    }
    if (isFetchCustomerOrdersHitted) {
      yield put(setFetchCustomerOrdersPage(1));
      yield call(_getCustomerOrders);
    }
    if (isFetchLatestOrdersHitted) {
      yield put(setFetchLatestOrdersPage(1));
      yield call(_getLatestOrders);
    }
    // if (isFetchKitchenCompletedOrdersHitted) {
    //   yield put(setFetchKitchenCompletedOrdersPage(1));
    //   yield call(getKitchenCompletedOrders);
    // }
    // if (isFetchKitchenProcessOrdersHitted) {
    //   yield put(setFetchKitchenProcessOrdersPage(1));
    //   yield call(getKitchenProcessOrders);
    // }

    yield put(setCreateOrderSuccess(message));
    yield put(setCreateOrderLoading(false));
  } catch (error) {
    yield put(setCreateOrderFailed(error));
    yield put(setCreateOrderLoading(false));
  }
}

export function* _updateOrder({ payload: { id, request } }) {
  try {
    yield put(setUpdateOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(updateOrder, id, request);

    yield put(setIsUpdateOrderHitted(true));
    yield put(setUpdateOrder(order));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);
    const isFetchWaitingOrdersHitted = yield select(getIsFetchWaitingOrdersHitted);
    const isFetchProcessOrdersHitted = yield select(getIsFetchProcessOrdersHitted);
    const isFetchCompletedOrdersHitted = yield select(getIsFetchCompletedOrdersHitted);
    const isFetchReportOrdersHitted = yield select(getIsFetchReportOrdersHitted);
    const isFetchCashierOrdersHitted = yield select(getIsFetchCashierOrdersHitted);
    const isFetchCustomerOrdersHitted = yield select(getIsFetchCustomerOrdersHitted);
    const isFetchLatestOrdersHitted = yield select(getIsFetchLatestOrdersHitted);
    const isFetchKitchenCompletedOrdersHitted = yield select(getIsFetchKitchenCompletedOrdersHitted);
    const isFetchKitchenProcessOrdersHitted = yield select(getIsFetchKitchenProcessOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }
    if (isFetchCompletedOrdersHitted) {
      yield put(setFetchCompletedOrdersPage(1));
      yield call(_getCompletedOrders);
    }
    if (isFetchProcessOrdersHitted) {
      yield put(setFetchProcessOrdersPage(1));
      yield call(_getProcessOrders);
    }
    if (isFetchWaitingOrdersHitted) {
      yield put(setFetchWaitingOrdersPage(1));
      yield call(_getWaitingOrders);
    }
    if (isFetchReportOrdersHitted) {
      yield put(setFetchReportOrdersPage(1));
      yield call(_getReportOrders);
    }
    if (isFetchCashierOrdersHitted) {
      yield put(setFetchCashierOrdersPage(1));
      yield call(_getCashierOrders);
    }
    if (isFetchCustomerOrdersHitted) {
      yield put(setFetchCustomerOrdersPage(1));
      yield call(_getCustomerOrders);
    }
    if (isFetchLatestOrdersHitted) {
      yield put(setFetchLatestOrdersPage(1));
      yield call(_getLatestOrders);
    }
    // if (isFetchKitchenCompletedOrdersHitted) {
    //   yield put(setFetchKitchenCompletedOrdersPage(1));
    //   yield call(getKitchenCompletedOrders);
    // }
    // if (isFetchKitchenProcessOrdersHitted) {
    //   yield put(setFetchKitchenProcessOrdersPage(1));
    //   yield call(getKitchenProcessOrders);
    // }

    yield put(setUpdateOrderSuccess(message));
    yield put(setUpdateOrderLoading(false));
  } catch (error) {
    yield put(setUpdateOrderFailed(error));
    yield put(setUpdateOrderLoading(false));
  }
}

export function* _deleteOrder({ payload: id }) {
  try {
    yield put(setDeleteOrderLoading(true));

    const {
      meta: { message },
    } = yield call(deleteOrder, id);

    yield put(setIsDeleteOrderHitted(true));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);
    const isFetchWaitingOrdersHitted = yield select(getIsFetchWaitingOrdersHitted);
    const isFetchProcessOrdersHitted = yield select(getIsFetchProcessOrdersHitted);
    const isFetchCompletedOrdersHitted = yield select(getIsFetchCompletedOrdersHitted);
    const isFetchReportOrdersHitted = yield select(getIsFetchReportOrdersHitted);
    const isFetchCashierOrdersHitted = yield select(getIsFetchCashierOrdersHitted);
    const isFetchCustomerOrdersHitted = yield select(getIsFetchCustomerOrdersHitted);
    const isFetchLatestOrdersHitted = yield select(getIsFetchLatestOrdersHitted);
    const isFetchKitchenCompletedOrdersHitted = yield select(getIsFetchKitchenCompletedOrdersHitted);
    const isFetchKitchenProcessOrdersHitted = yield select(getIsFetchKitchenProcessOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }
    if (isFetchCompletedOrdersHitted) {
      yield put(setFetchCompletedOrdersPage(1));
      yield call(_getCompletedOrders);
    }
    if (isFetchProcessOrdersHitted) {
      yield put(setFetchProcessOrdersPage(1));
      yield call(_getProcessOrders);
    }
    if (isFetchWaitingOrdersHitted) {
      yield put(setFetchWaitingOrdersPage(1));
      yield call(_getWaitingOrders);
    }
    if (isFetchReportOrdersHitted) {
      yield put(setFetchReportOrdersPage(1));
      yield call(_getReportOrders);
    }
    if (isFetchCashierOrdersHitted) {
      yield put(setFetchCashierOrdersPage(1));
      yield call(_getCashierOrders);
    }
    if (isFetchCustomerOrdersHitted) {
      yield put(setFetchCustomerOrdersPage(1));
      yield call(_getCustomerOrders);
    }
    if (isFetchLatestOrdersHitted) {
      yield put(setFetchLatestOrdersPage(1));
      yield call(_getLatestOrders);
    }
    // if (isFetchKitchenCompletedOrdersHitted) {
    //   yield put(setFetchKitchenCompletedOrdersPage(1));
    //   yield call(getKitchenCompletedOrders);
    // }
    // if (isFetchKitchenProcessOrdersHitted) {
    //   yield put(setFetchKitchenProcessOrdersPage(1));
    //   yield call(getKitchenProcessOrders);
    // }

    yield put(setDeleteOrderSuccess(message));
    yield put(setDeleteOrderLoading(false));
  } catch (error) {
    yield put(setDeleteOrderFailed(error));
    yield put(setDeleteOrderLoading(false));
  }
}

export function* _checkoutOrder({ payload: { id, request } }) {
  try {
    yield put(setCheckoutOrderLoading(true));

    const {
      meta: { message },
    } = yield call(checkoutOrder, id, request);

    yield put(setIsCheckoutOrderHitted(true));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);
    const isFetchWaitingOrdersHitted = yield select(getIsFetchWaitingOrdersHitted);
    const isFetchProcessOrdersHitted = yield select(getIsFetchProcessOrdersHitted);
    const isFetchCompletedOrdersHitted = yield select(getIsFetchCompletedOrdersHitted);
    const isFetchReportOrdersHitted = yield select(getIsFetchReportOrdersHitted);
    const isFetchCashierOrdersHitted = yield select(getIsFetchCashierOrdersHitted);
    const isFetchCustomerOrdersHitted = yield select(getIsFetchCustomerOrdersHitted);
    const isFetchLatestOrdersHitted = yield select(getIsFetchLatestOrdersHitted);
    const isFetchKitchenCompletedOrdersHitted = yield select(getIsFetchKitchenCompletedOrdersHitted);
    const isFetchKitchenProcessOrdersHitted = yield select(getIsFetchKitchenProcessOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }
    if (isFetchCompletedOrdersHitted) {
      yield put(setFetchCompletedOrdersPage(1));
      yield call(_getCompletedOrders);
    }
    if (isFetchProcessOrdersHitted) {
      yield put(setFetchProcessOrdersPage(1));
      yield call(_getProcessOrders);
    }
    if (isFetchWaitingOrdersHitted) {
      yield put(setFetchWaitingOrdersPage(1));
      yield call(_getWaitingOrders);
    }
    if (isFetchReportOrdersHitted) {
      yield put(setFetchReportOrdersPage(1));
      yield call(_getReportOrders);
    }
    if (isFetchCashierOrdersHitted) {
      yield put(setFetchCashierOrdersPage(1));
      yield call(_getCashierOrders);
    }
    if (isFetchCustomerOrdersHitted) {
      yield put(setFetchCustomerOrdersPage(1));
      yield call(_getCustomerOrders);
    }
    if (isFetchLatestOrdersHitted) {
      yield put(setFetchLatestOrdersPage(1));
      yield call(_getLatestOrders);
    }
    // if (isFetchKitchenCompletedOrdersHitted) {
    //   yield put(setFetchKitchenCompletedOrdersPage(1));
    //   yield call(getKitchenCompletedOrders);
    // }
    // if (isFetchKitchenProcessOrdersHitted) {
    //   yield put(setFetchKitchenProcessOrdersPage(1));
    //   yield call(getKitchenProcessOrders);
    // }

    yield put(setCheckoutOrderSuccess(message));
    yield put(setCheckoutOrderLoading(false));
  } catch (error) {
    yield put(setCheckoutOrderFailed(error));
    yield put(setCheckoutOrderLoading(false));
  }
}

export function* _calculateCheckoutOrder({ payload: request }) {
  try {
    yield put(setCalculateCheckoutOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(calculateOrder, request);

    yield put(setIsCalculateCheckoutOrderHitted(true));
    yield put(setCalculateCheckoutOrder(order));

    yield put(setCalculateCheckoutOrderSuccess(message));
    yield put(setCalculateCheckoutOrderLoading(false));
  } catch (error) {
    yield put(setCalculateCheckoutOrderFailed(error));
    yield put(setCalculateCheckoutOrderLoading(false));
  }
}

export function* _returnOrder({ payload: { id, request } }) {
  try {
    yield put(setReturnOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(returnOrder, id, request);

    yield put(setIsReturnOrderHitted(true));
    yield put(setReturnOrder(order));

    const isFetchOrdersHitted = yield select(getIsFetchOrdersHitted);
    const isFetchWaitingOrdersHitted = yield select(getIsFetchWaitingOrdersHitted);
    const isFetchProcessOrdersHitted = yield select(getIsFetchProcessOrdersHitted);
    const isFetchCompletedOrdersHitted = yield select(getIsFetchCompletedOrdersHitted);
    const isFetchReportOrdersHitted = yield select(getIsFetchReportOrdersHitted);
    const isFetchCashierOrdersHitted = yield select(getIsFetchCashierOrdersHitted);
    const isFetchCustomerOrdersHitted = yield select(getIsFetchCustomerOrdersHitted);
    const isFetchLatestOrdersHitted = yield select(getIsFetchLatestOrdersHitted);
    const isFetchKitchenCompletedOrdersHitted = yield select(getIsFetchKitchenCompletedOrdersHitted);
    const isFetchKitchenProcessOrdersHitted = yield select(getIsFetchKitchenProcessOrdersHitted);

    if (isFetchOrdersHitted) {
      yield put(setFetchOrdersPage(1));
      yield call(_getOrders);
    }
    if (isFetchCompletedOrdersHitted) {
      yield put(setFetchCompletedOrdersPage(1));
      yield call(_getCompletedOrders);
    }
    if (isFetchProcessOrdersHitted) {
      yield put(setFetchProcessOrdersPage(1));
      yield call(_getProcessOrders);
    }
    if (isFetchWaitingOrdersHitted) {
      yield put(setFetchWaitingOrdersPage(1));
      yield call(_getWaitingOrders);
    }
    if (isFetchReportOrdersHitted) {
      yield put(setFetchReportOrdersPage(1));
      yield call(_getReportOrders);
    }
    if (isFetchCashierOrdersHitted) {
      yield put(setFetchCashierOrdersPage(1));
      yield call(_getCashierOrders);
    }
    if (isFetchCustomerOrdersHitted) {
      yield put(setFetchCustomerOrdersPage(1));
      yield call(_getCustomerOrders);
    }
    if (isFetchLatestOrdersHitted) {
      yield put(setFetchLatestOrdersPage(1));
      yield call(_getLatestOrders);
    }
    // if (isFetchKitchenCompletedOrdersHitted) {
    //   yield put(setFetchKitchenCompletedOrdersPage(1));
    //   yield call(getKitchenCompletedOrders);
    // }
    // if (isFetchKitchenProcessOrdersHitted) {
    //   yield put(setFetchKitchenProcessOrdersPage(1));
    //   yield call(getKitchenProcessOrders);
    // }

    yield put(setReturnOrderSuccess(message));
    yield put(setReturnOrderLoading(false));
  } catch (error) {
    yield put(setReturnOrderFailed(error));
    yield put(setReturnOrderLoading(false));
  }
}

export function* _calculateReturnOrder({ payload: request }) {
  try {
    yield put(setCalculateReturnOrderLoading(true));

    const {
      meta: { message },
      data: order,
    } = yield call(calculateOrder, request);

    yield put(setIsCalculateReturnOrderHitted(true));
    yield put(setCalculateReturnOrder(order));

    yield put(setCalculateReturnOrderSuccess(message));
    yield put(setCalculateReturnOrderLoading(false));
  } catch (error) {
    yield put(setCalculateReturnOrderFailed(error));
    yield put(setCalculateReturnOrderLoading(false));
  }
}

export function* onFetchOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_ORDERS_START, _getOrders);
}

export function* onFetchWaitingOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_WAITING_ORDERS_START, _getWaitingOrders);
}

export function* onFetchProcessOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_PROCESS_ORDERS_START, _getProcessOrders);
}

export function* onFetchCompletedOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_COMPLETED_ORDERS_START, _getCompletedOrders);
}

export function* onFetchReportOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_REPORT_ORDERS_START, _getReportOrders);
}

export function* onFetchCashierOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_CASHIER_ORDERS_START, _getCashierOrders);
}

export function* onFetchCustomerOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_CUSTOMER_ORDERS_START, _getCustomerOrders);
}

export function* onFetchLatestOrdersStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_LATEST_ORDERS_START, _getLatestOrders);
}

export function* onFetchOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.FETCH_ORDER_START, _getOrder);
}

export function* onCalculateOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CALCULATE_ORDER_START, _calculateOrder);
}

export function* onCreateOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CREATE_ORDER_START, _createOrder);
}

export function* onUpdateOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.UPDATE_ORDER_START, _updateOrder);
}

export function* onDeleteOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.DELETE_ORDER_START, _deleteOrder);
}

export function* onCheckoutOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CHECKOUT_ORDER_START, _checkoutOrder);
}

export function* onCalculateCheckoutOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CALCULATE_CHECKOUT_ORDER_START, _calculateCheckoutOrder);
}

export function* onReturnOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.RETURN_ORDER_START, _returnOrder);
}

export function* onCalculateReturnOrderStart() {
  yield takeLatest(ORDER_ACTION_TYPES.CALCULATE_RETURN_ORDER_START, _calculateReturnOrder);
}

export function* orderSaga() {
  yield all([
    call(onFetchOrdersStart),
    call(onFetchWaitingOrdersStart),
    call(onFetchProcessOrdersStart),
    call(onFetchCompletedOrdersStart),
    call(onFetchReportOrdersStart),
    call(onFetchCashierOrdersStart),
    call(onFetchCustomerOrdersStart),
    call(onFetchLatestOrdersStart),
    call(onFetchOrderStart),
    call(onCalculateOrderStart),
    call(onCreateOrderStart),
    call(onUpdateOrderStart),
    call(onDeleteOrderStart),
    call(onCheckoutOrderStart),
    call(onCalculateCheckoutOrderStart),
    call(onReturnOrderStart),
    call(onCalculateReturnOrderStart),
  ]);
}
