import { combineReducers } from "redux";

import { adminReducer } from "./admin/admin.reducer";
import { advertisementReducer } from "./advertisement/advertisement.reducer";
import { announcementReducer } from "./announcement/announcement.reducer";
import { applicationReducer } from "./application/application.reducer";
import { attendanceReducer } from "./attendance/attendance.reducer";
import { authenticationReducer } from "./authentication/authentication.reducer";
import { branchReducer } from "./branch/branch.reducer";
import { cardReducer } from "./card/card.reducer";
import { cartReducer } from "./cart/cart.reducer";
import { componentReducer } from "./component/component.reducer";
import { countryReducer } from "./country/country.reducer";
import { customerReducer } from "./customer/customer.reducer";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { dashboardAdminReducer } from "./dashboard-admin/dashboard-admin.reducer";
import { dashboardMerchantReducer } from "./dashboard-merchant/dashboard-merchant.reducer";
import { dashboardSubscriberReducer } from "./dashboard-subscriber/dashboard-subscriber.reducer";
import { discountReducer } from "./discount/discount.reducer";
import { driverReducer } from "./driver/driver.reducer";
import { eventReducer } from "./event/event.reducer";
import { exportReducer } from "./export/export.reducer";
import { exportExcelReducer } from "./export-excel/export-excel.reducer";
import { exportPdfReducer } from "./export-pdf/export-pdf.reducer";
import { extendPeriodReducer } from "./extend-period/extend-period.reducer";
import { formCustomerReducer } from "./form-customer/form-customer.reducer";
import { gatewayReducer } from "./gateway/gateway.reducer";
import { globalReducer } from "./global/global.reducer";
import { headerBranchReducer } from "./header-branch/header-branch.reducer";
import { ingredientReducer } from "./ingredient/ingredient.reducer";
import { ingredientHistoryReducer } from "./ingredient-history/ingredient-history.reducer";
import { integrationReducer } from "./integration/integration.reducer";
import { kitchenReducer } from "./kitchen/kitchen.reducer";
import { kitchenGroupReducer } from "./kitchen-group/kitchen-group.reducer";
import { marketReducer } from "./market/market.reducer";
import { merchantReducer } from "./merchant/merchant.reducer";
import { msegatReducer } from "./msegat/msegat.reducer";
import { orderReducer } from "./order/order.reducer";
import { orderProductReducer } from "./order-product/order-product.reducer";
import { paymentDeviceReducer } from "./payment-device/payment-device.reducer";
import { paymentMethodReducer } from "./payment-method/payment-method.reducer";
import { paymentMethodSettingReducer } from "./payment-method-setting/payment-method-setting.reducer";
import { permissionAdminReducer } from "./permission-admin/permission-admin.reducer";
import { permissionMerchantReducer } from "./permission-merchant/permission-merchant.reducer";
import { permissionSubscriberReducer } from "./permission-subscriber/permission-subscriber.reducer";
import { pdfReducer } from "./pdf/pdf.reducer";
import { printerReducer } from "./printer/printer.reducer";
import { printerTypeReducer } from "./printer-type/printer-type.reducer";
import { productCategoryReducer } from "./product-category/product-category.reducer";
import { productCategorySortReducer } from "./product-category-sort/product-category-sort.reducer";
import { productModifierReducer } from "./product-modifier/product-modifier.reducer";
import { productReducer } from "./product/product.reducer";
import { productQuickReducer } from "./product-quick/product-quick.reducer";
import { purchaseReducer } from "./purchase/purchase.reducer";
import { recipeReducer } from "./recipe/recipe.reducer";
import { reportReducer } from "./report/report.reducer";
import { reportAttendanceReducer } from "./report-attendance/report-attendance.reducer";
import { reportMoreReducer } from "./report-more/report-more.reducer";
import { restockRequestReducer } from "./restock-request/restock-request.reducer";
import { scheduleReducer } from "./schedule/schedule.reducer";
import { sectorReducer } from "./sector/sector.reducer";
import { sectorCategoryReducer } from "./sector-category/sector-category.reducer";
import { selectBranchReducer } from "./select-branch/select-branch.reducer";
import { selectCashierReducer } from "./select-cashier/select-cashier.reducer";
import { selectCommonReducer } from "./select-common/select-common.reducer";
import { selectCountryReducer } from "./select-country/select-country.reducer";
import { selectCustomerReducer } from "./select-customer/select-customer.reducer";
import { selectIngredientReducer } from "./select-ingredient/select-ingredient.reducer";
import { selectMarketReducer } from "./select-market/select-market.reducer";
import { selectProductReducer } from "./select-product/select-product.reducer";
import { selectProductCategoryReducer } from "./select-product-category/select-product-category.reducer";
import { selectSupplierReducer } from "./select-supplier/select-supplier.reducer";
import { settingReducer } from "./setting/setting.reducer";
import { shortUrlReducer } from "./short-url/short-url.reducer";
import { stateSubscriptionReducer } from "./state-subscription/state-subscription.reducer";
import { subscriberReducer } from "./subscriber/subscriber.reducer";
import { subscriptionReducer } from "./subscription/subscription.reducer";
import { summaryReducer } from "./summary/summary.reducer";
import { supplierReducer } from "./supplier/supplier.reducer";
import { tableReducer } from "./table/table.reducer";
import { taxReducer } from "./tax/tax.reducer";
import { termConditionReducer } from "./term-condition/term-condition.reducer";
import { topUpReducer } from "./top-up/top-up.reducer";
import { userAdminReducer } from "./user-admin/user-admin.reducer";
import { userCustomerReducer } from "./user-customer/user-customer.reducer";
import { userDriverReducer } from "./user-driver/user-driver.reducer";
import { userMerchantReducer } from "./user-merchant/user-merchant.reducer";
import { userSubscriberReducer } from "./user-subscriber/user-subscriber.reducer";
import { userSupplierReducer } from "./user-supplier/user-supplier.reducer";
import { verificationReducer } from "./verification/verification.reducer";
import { wathqReducer } from "./wathq/wathq.reducer";
import { whatsappReducer } from "./whatsapp/whatsapp.reducer";

export const rootReducer = combineReducers({
  admin: adminReducer,
  advertisement: advertisementReducer,
  announcement: announcementReducer,
  application: applicationReducer,
  attendance: attendanceReducer,
  authentication: authenticationReducer,
  branch: branchReducer,
  card: cardReducer,
  cart: cartReducer,
  component: componentReducer,
  country: countryReducer,
  customer: customerReducer,
  dashboard: dashboardReducer,
  dashboardAdmin: dashboardAdminReducer,
  dashboardMerchant: dashboardMerchantReducer,
  dashboardSubscriber: dashboardSubscriberReducer,
  discount: discountReducer,
  driver: driverReducer,
  event: eventReducer,
  export: exportReducer,
  exportExcel: exportExcelReducer,
  exportPdf: exportPdfReducer,
  extendPeriod: extendPeriodReducer,
  formCustomer: formCustomerReducer,
  gateway: gatewayReducer,
  global: globalReducer,
  headerBranch: headerBranchReducer,
  ingredient: ingredientReducer,
  ingredientHistory: ingredientHistoryReducer,
  integration: integrationReducer,
  kitchen: kitchenReducer,
  kitchenGroup: kitchenGroupReducer,
  market: marketReducer,
  merchant: merchantReducer,
  msegat: msegatReducer,
  order: orderReducer,
  orderProduct: orderProductReducer,
  paymentDevice: paymentDeviceReducer,
  paymentMethod: paymentMethodReducer,
  selectBranch: selectBranchReducer,
  selectCashier: selectCashierReducer,
  selectCommon: selectCommonReducer,
  selectCountry: selectCountryReducer,
  selectCustomer: selectCustomerReducer,
  selectIngredient: selectIngredientReducer,
  selectMarket: selectMarketReducer,
  selectProduct: selectProductReducer,
  selectProductCategory: selectProductCategoryReducer,
  selectSupplier: selectSupplierReducer,
  paymentMethodSetting: paymentMethodSettingReducer,
  permissionAdmin: permissionAdminReducer,
  permissionMerchant: permissionMerchantReducer,
  permissionSubscriber: permissionSubscriberReducer,
  pdf: pdfReducer,
  printer: printerReducer,
  printerType: printerTypeReducer,
  productCategory: productCategoryReducer,
  productCategorySort: productCategorySortReducer,
  productModifier: productModifierReducer,
  product: productReducer,
  productQuick: productQuickReducer,
  purchase: purchaseReducer,
  recipe: recipeReducer,
  report: reportReducer,
  reportAttendance: reportAttendanceReducer,
  reportMore: reportMoreReducer,
  restockRequest: restockRequestReducer,
  schedule: scheduleReducer,
  sector: sectorReducer,
  sectorCategory: sectorCategoryReducer,
  setting: settingReducer,
  shortUrl: shortUrlReducer,
  stateSubscription: stateSubscriptionReducer,
  subscriber: subscriberReducer,
  subscription: subscriptionReducer,
  summary: summaryReducer,
  supplier: supplierReducer,
  table: tableReducer,
  tax: taxReducer,
  termCondition: termConditionReducer,
  topUp: topUpReducer,
  userAdmin: userAdminReducer,
  userCustomer: userCustomerReducer,
  userDriver: userDriverReducer,
  userMerchant: userMerchantReducer,
  userSubscriber: userSubscriberReducer,
  userSupplier: userSupplierReducer,
  verification: verificationReducer,
  wathq: wathqReducer,
  whatsapp: whatsappReducer,
});
