import { createSelector } from "reselect";

const dashboardSubscriberSelector = ({ dashboardSubscriber }) =>
  dashboardSubscriber;

export const getDashboardActiveBranches = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardActiveBranches }) => dashboardActiveBranches
);
export const getDashboardLatestOrders = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardLatestOrders }) => dashboardLatestOrders
);
export const getDashboardLatestReviews = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardLatestReviews }) => dashboardLatestReviews
);
export const getDashboardOrdersChart = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardOrdersChart }) => dashboardOrdersChart
);
export const getDashboardPopularProducts = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardPopularProducts }) => dashboardPopularProducts
);

export const getDashboardActiveBranchesLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardActiveBranchesLoading }) => dashboardActiveBranchesLoading
);
export const getDashboardLatestOrdersLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardLatestOrdersLoading }) => dashboardLatestOrdersLoading
);
export const getDashboardLatestReviewsLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardLatestReviewsLoading }) => dashboardLatestReviewsLoading
);
export const getDashboardOrdersChartLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardOrdersChartLoading }) => dashboardOrdersChartLoading
);
export const getDashboardPopularProductsLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ dashboardPopularProductsLoading }) => dashboardPopularProductsLoading
);

export const getFetchDashboardSubscriberFilterMarketId = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSubscriberFilterMarketId }) =>
    fetchDashboardSubscriberFilterMarketId
);
export const getFetchDashboardSubscriberFilterBranchId = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSubscriberFilterBranchId }) =>
    fetchDashboardSubscriberFilterBranchId
);
export const getFetchDashboardSubscriberFilterTimePeriod = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSubscriberFilterTimePeriod }) =>
    fetchDashboardSubscriberFilterTimePeriod
);
export const getFetchDashboardSubscriberLoading = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSubscriberLoading }) => fetchDashboardSubscriberLoading
);
export const getFetchDashboardSubscriberSuccess = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSubscriberSuccess }) => fetchDashboardSubscriberSuccess
);
export const getFetchDashboardSubscriberFailed = createSelector(
  [dashboardSubscriberSelector],
  ({ fetchDashboardSubscriberFailed }) => fetchDashboardSubscriberFailed
);

export const getIsFetchDashboardSubscriberHitted = createSelector(
  [dashboardSubscriberSelector],
  ({ isFetchDashboardSubscriberHitted }) => isFetchDashboardSubscriberHitted
);
