import {
  SUBSCRIPTION_TABS,
  SUBSCRIPTIONS_OPTION_KEYS,
} from "../../constants/subscription.constant";

import STATE_SUBSCRIPTION_ACTION_TYPES from "./state-subscription.type";

export const STATE_SUBSCRIPTION_INITIAL_STATE = {
  subscriptionTabs: [
    {
      label: "Plans",
      value: SUBSCRIPTION_TABS.PLANS,
    },
    {
      label: "Billing",
      value: SUBSCRIPTION_TABS.BILLING,
    },
    {
      label: "Usage",
      value: SUBSCRIPTION_TABS.USAGE,
    },
    {
      label: "Invoices",
      value: SUBSCRIPTION_TABS.INVOICES,
    },
  ],
  subscriptionTab: SUBSCRIPTION_TABS.PLANS,

  billingBranchesIds: [],
  billingPlans: [],
  billingPlan: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,
  billingCycle: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,
};

export const stateSubscriptionReducer = (
  state = STATE_SUBSCRIPTION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_TAB:
      return { ...state, subscriptionTab: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_BRANCHES_IDS:
      return { ...state, billingBranchesIds: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLANS:
      return { ...state, billingPlans: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLAN:
      return { ...state, billingPlan: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_CYCLE:
      return { ...state, billingCycle: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.RESET_STATE_SUBSCRIPTION_REDUCER:
      return STATE_SUBSCRIPTION_INITIAL_STATE;
    default:
      return state;
  }
};
