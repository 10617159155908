import GLOBAL_ACTION_TYPES from "./global.type";
import { createAction } from "../../utils/store.utils";

export const setIsRightToLeft = (isRightToLeft) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_RIGHT_TO_LEFT, isRightToLeft);
export const setIsGlobalInitialized = (isGlobalInitialized) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_INITIALIZED,
    isGlobalInitialized
  );
export const setIsGlobalLoading = (isGlobalLoading) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_LOADING, isGlobalLoading);
export const setCurrentLanguage = (currentLanguage) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_LANGUAGE, currentLanguage);

export const setAuthorizePages = (authorizePages) =>
  createAction(GLOBAL_ACTION_TYPES.SET_AUTHORIZE_PAGES, authorizePages);
export const setIsMarketAdministrator = (isMarketAdministrator) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_ADMINISTRATOR,
    isMarketAdministrator
  );
export const setIsCenterAdministrator = (isCenterAdministrator) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_CENTER_ADMINISTRATOR,
    isCenterAdministrator
  );

export const setCurrentMarket = (currentMarket) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET, currentMarket);
export const setCurrentBranch = (currentBranch) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH, currentBranch);
export const setCurrentMerchant = (currentMerchant) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT, currentMerchant);
export const setCurrentPermission = (currentPermission) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION, currentPermission);
export const setCurrentSchedules = (currentSchedules) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_SCHEDULES, currentSchedules);

export const setCurrentMarketId = (currentMarketId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MARKET_ID, currentMarketId);
export const setCurrentBranchId = (currentBranchId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_BRANCH_ID, currentBranchId);
export const setCurrentMerchantId = (currentMerchantId) =>
  createAction(GLOBAL_ACTION_TYPES.SET_CURRENT_MERCHANT_ID, currentMerchantId);
export const setCurrentPermissionId = (currentPermissionId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_CURRENT_PERMISSION_ID,
    currentPermissionId
  );

export const setPermissionLimitedPages = (permissionLimitedPages) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_PERMISSION_LIMITED_PAGES,
    permissionLimitedPages
  );
export const setIsCurrentKitchenActive = (isCurrentKitchenActive) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_CURRENT_KITCHEN_ACTIVE,
    isCurrentKitchenActive
  );

export const setTodaySchedule = (todaySchedule) =>
  createAction(GLOBAL_ACTION_TYPES.SET_TODAY_SCHEDULE, todaySchedule);
export const setIsTodayInSchedule = (isTodayInSchedule) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_TODAY_IN_SCHEDULE, isTodayInSchedule);

export const setTodayAttendance = (todayAttendance) =>
  createAction(GLOBAL_ACTION_TYPES.SET_TODAY_ATTENDANCE, todayAttendance);
export const setIsTodayHasAttendance = (isTodayHasAttendance) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_TODAY_HAS_ATTENDANCE,
    isTodayHasAttendance
  );

export const setMarketPlans = (marketPlans) =>
  createAction(GLOBAL_ACTION_TYPES.SET_MARKET_PLANS, marketPlans);
export const setBranchPlans = (branchPlans) =>
  createAction(GLOBAL_ACTION_TYPES.SET_BRANCH_PLANS, branchPlans);

export const setIsHasActivePlan = (isHasActivePlan) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_PLAN, isHasActivePlan);
export const setIsHasActiveAdvancedPlan = (isHasActiveAdvancedPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_ADVANCED_PLAN,
    isHasActiveAdvancedPlan
  );
export const setIsHasActiveBasicPlan = (isHasActiveBasicPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_BASIC_PLAN,
    isHasActiveBasicPlan
  );
export const setIsHasActiveTrialPlan = (isHasActiveTrialPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_HAS_ACTIVE_TRIAL_PLAN,
    isHasActiveTrialPlan
  );

export const setIsMarketHasPlan = (isMarketHasPlan) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_PLAN, isMarketHasPlan);
export const setIsMarketHasAdvancedPlan = (isMarketHasAdvancedPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_ADVANCED_PLAN,
    isMarketHasAdvancedPlan
  );
export const setIsMarketHasBasicPlan = (isMarketHasBasicPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_BASIC_PLAN,
    isMarketHasBasicPlan
  );
export const setIsMarketHasTrialPlan = (isMarketHasTrialPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_MARKET_HAS_TRIAL_PLAN,
    isMarketHasTrialPlan
  );

export const setIsBranchHasPlan = (isBranchHasPlan) =>
  createAction(GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_PLAN, isBranchHasPlan);
export const setIsBranchHasAdvancedPlan = (isBranchHasAdvancedPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_ADVANCED_PLAN,
    isBranchHasAdvancedPlan
  );
export const setIsBranchHasBasicPlan = (isBranchHasBasicPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_BASIC_PLAN,
    isBranchHasBasicPlan
  );
export const setIsBranchHasTrialPlan = (isBranchHasTrialPlan) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_BRANCH_HAS_TRIAL_PLAN,
    isBranchHasTrialPlan
  );

export const setPlanBillingCycles = (planBillingCycles) =>
  createAction(GLOBAL_ACTION_TYPES.SET_PLAN_BILLING_CYCLES, planBillingCycles);
export const setGlobalSubscriptions = (globalSubscriptions) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_GLOBAL_SUBSCRIPTIONS,
    globalSubscriptions
  );
export const appendGlobalSubscriptions = (globalSubscriptions) =>
  createAction(
    GLOBAL_ACTION_TYPES.APPEND_GLOBAL_SUBSCRIPTIONS,
    globalSubscriptions
  );
export const setIsGlobalSubscriptionsHasMore = (isGlobalSubscriptionsHasMore) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_GLOBAL_SUBSCRIPTIONS_HAS_MORE,
    isGlobalSubscriptionsHasMore
  );

export const setFetchCurrentMarketLoading = (fetchCurrentMarketLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_LOADING,
    fetchCurrentMarketLoading
  );
export const setFetchCurrentMarketSuccess = (fetchCurrentMarketSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_SUCCESS,
    fetchCurrentMarketSuccess
  );
export const setFetchCurrentMarketFailed = (fetchCurrentMarketFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MARKET_FAILED,
    fetchCurrentMarketFailed
  );

export const setFetchCurrentBranchLoading = (fetchCurrentBranchLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_LOADING,
    fetchCurrentBranchLoading
  );
export const setFetchCurrentBranchSuccess = (fetchCurrentBranchSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_SUCCESS,
    fetchCurrentBranchSuccess
  );
export const setFetchCurrentBranchFailed = (fetchCurrentBranchFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_BRANCH_FAILED,
    fetchCurrentBranchFailed
  );

export const setFetchCurrentMerchantLoading = (fetchCurrentMerchantLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_LOADING,
    fetchCurrentMerchantLoading
  );
export const setFetchCurrentMerchantSuccess = (fetchCurrentMerchantSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_SUCCESS,
    fetchCurrentMerchantSuccess
  );
export const setFetchCurrentMerchantFailed = (fetchCurrentMerchantFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_MERCHANT_FAILED,
    fetchCurrentMerchantFailed
  );

export const setFetchCurrentPermissionLoading = (
  fetchCurrentPermissionLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_LOADING,
    fetchCurrentPermissionLoading
  );
export const setFetchCurrentPermissionSuccess = (
  fetchCurrentPermissionSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_SUCCESS,
    fetchCurrentPermissionSuccess
  );
export const setFetchCurrentPermissionFailed = (fetchCurrentPermissionFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_PERMISSION_FAILED,
    fetchCurrentPermissionFailed
  );

export const setFetchCurrentSchedulesIncludes = (
  fetchCurrentSchedulesIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_INCLUDES,
    fetchCurrentSchedulesIncludes
  );
export const setFetchCurrentSchedulesFilterMarketId = (
  fetchCurrentSchedulesFilterMarketId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_MARKET_ID,
    fetchCurrentSchedulesFilterMarketId
  );
export const setFetchCurrentSchedulesFilterBranchId = (
  fetchCurrentSchedulesFilterBranchId
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FILTER_BRANCH_ID,
    fetchCurrentSchedulesFilterBranchId
  );
export const setFetchCurrentSchedulesLoading = (fetchCurrentSchedulesLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_LOADING,
    fetchCurrentSchedulesLoading
  );
export const setFetchCurrentSchedulesSuccess = (fetchCurrentSchedulesSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_SUCCESS,
    fetchCurrentSchedulesSuccess
  );
export const setFetchCurrentSchedulesFailed = (fetchCurrentSchedulesFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_CURRENT_SCHEDULES_FAILED,
    fetchCurrentSchedulesFailed
  );

export const setFetchTodayAttendanceMarketId = (fetchTodayAttendanceMarketId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_MARKET_ID,
    fetchTodayAttendanceMarketId
  );
export const setFetchTodayAttendanceBranchId = (fetchTodayAttendanceBranchId) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_BRANCH_ID,
    fetchTodayAttendanceBranchId
  );
export const setFetchTodayAttendanceLoading = (fetchTodayAttendanceLoading) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_LOADING,
    fetchTodayAttendanceLoading
  );
export const setFetchTodayAttendanceSuccess = (fetchTodayAttendanceSuccess) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_SUCCESS,
    fetchTodayAttendanceSuccess
  );
export const setFetchTodayAttendanceFailed = (fetchTodayAttendanceFailed) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_TODAY_ATTENDANCE_FAILED,
    fetchTodayAttendanceFailed
  );

export const setFetchGlobalSubscriptionsSearch = (
  fetchGlobalSubscriptionsSearch
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SEARCH,
    fetchGlobalSubscriptionsSearch
  );
export const setFetchGlobalSubscriptionsSort = (fetchGlobalSubscriptionsSort) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SORT,
    fetchGlobalSubscriptionsSort
  );
export const setFetchGlobalSubscriptionsKeyBy = (
  fetchGlobalSubscriptionsKeyBy
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_KEY_BY,
    fetchGlobalSubscriptionsKeyBy
  );
export const setFetchGlobalSubscriptionsPage = (fetchGlobalSubscriptionsPage) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PAGE,
    fetchGlobalSubscriptionsPage
  );
export const setFetchGlobalSubscriptionsPerPage = (
  fetchGlobalSubscriptionsPerPage
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_PER_PAGE,
    fetchGlobalSubscriptionsPerPage
  );
export const setFetchGlobalSubscriptionsIncludes = (
  fetchGlobalSubscriptionsIncludes
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_INCLUDES,
    fetchGlobalSubscriptionsIncludes
  );
export const setFetchGlobalSubscriptionsLoading = (
  fetchGlobalSubscriptionsLoading
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_LOADING,
    fetchGlobalSubscriptionsLoading
  );
export const setFetchGlobalSubscriptionsSuccess = (
  fetchGlobalSubscriptionsSuccess
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_SUCCESS,
    fetchGlobalSubscriptionsSuccess
  );
export const setFetchGlobalSubscriptionsFailed = (
  fetchGlobalSubscriptionsFailed
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_FETCH_GLOBAL_SUBSCRIPTIONS_FAILED,
    fetchGlobalSubscriptionsFailed
  );

export const setIsFetchCurrentMarketHitted = (isFetchCurrentMarketHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MARKET_HITTED,
    isFetchCurrentMarketHitted
  );
export const setIsFetchCurrentBranchHitted = (isFetchCurrentBranchHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_BRANCH_HITTED,
    isFetchCurrentBranchHitted
  );
export const setIsFetchCurrentMerchantHitted = (isFetchCurrentMerchantHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_MERCHANT_HITTED,
    isFetchCurrentMerchantHitted
  );
export const setIsFetchCurrentPermissionHitted = (
  isFetchCurrentPermissionHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_PERMISSION_HITTED,
    isFetchCurrentPermissionHitted
  );
export const setIsFetchCurrentSchedulesHitted = (
  isFetchCurrentSchedulesHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_CURRENT_SCHEDULES_HITTED,
    isFetchCurrentSchedulesHitted
  );
export const setIsFetchTodayAttendanceHitted = (isFetchTodayAttendanceHitted) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_TODAY_ATTENDANCE_HITTED,
    isFetchTodayAttendanceHitted
  );
export const setIsFetchGlobalSubscriptionsHitted = (
  isFetchGlobalSubscriptionsHitted
) =>
  createAction(
    GLOBAL_ACTION_TYPES.SET_IS_FETCH_GLOBAL_SUBSCRIPTIONS_HITTED,
    isFetchGlobalSubscriptionsHitted
  );

export const fetchCurrentMarketStart = (marketId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_MARKET_START, marketId);
export const fetchCurrentBranchStart = (branchId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_BRANCH_START, branchId);
export const fetchCurrentMerchantStart = (merchantId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_MERCHANT_START, merchantId);
export const fetchCurrentPermissionStart = (role, permissionId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_PERMISSION_START, {
    role,
    permissionId,
  });
export const fetchCurrentSchedulesStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_CURRENT_SCHEDULES_START);
export const fetchTodayAttendanceStart = (userId) =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_TODAY_ATTENDANCE_START, userId);
export const fetchGlobalSubscriptionsStart = () =>
  createAction(GLOBAL_ACTION_TYPES.FETCH_GLOBAL_SUBSCRIPTIONS_START);

export const resetGlobalReducer = () =>
  createAction(GLOBAL_ACTION_TYPES.RESET_GLOBAL_REDUCER);
