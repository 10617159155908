import { createSelector } from "reselect";

const globalSelector = ({ global }) => global;

export const getIsRightToLeft = createSelector(
  [globalSelector],
  ({ isRightToLeft }) => isRightToLeft
);
export const getIsGlobalInitialized = createSelector(
  [globalSelector],
  ({ isGlobalInitialized }) => isGlobalInitialized
);
export const getIsGlobalLoading = createSelector(
  [globalSelector],
  ({ isGlobalLoading }) => isGlobalLoading
);
export const getCurrentLanguage = createSelector(
  [globalSelector],
  ({ currentLanguage }) => currentLanguage
);

export const getAuthorizePages = createSelector(
  [globalSelector],
  ({ authorizePages }) => authorizePages
);
export const getIsMarketAdministrator = createSelector(
  [globalSelector],
  ({ isMarketAdministrator }) => isMarketAdministrator
);
export const getIsCenterAdministrator = createSelector(
  [globalSelector],
  ({ isCenterAdministrator }) => isCenterAdministrator
);

export const getCurrentMarket = createSelector(
  [globalSelector],
  ({ currentMarket }) => currentMarket
);
export const getCurrentBranch = createSelector(
  [globalSelector],
  ({ currentBranch }) => currentBranch
);
export const getCurrentMerchant = createSelector(
  [globalSelector],
  ({ currentMerchant }) => currentMerchant
);
export const getCurrentPermission = createSelector(
  [globalSelector],
  ({ currentPermission }) => currentPermission
);
export const getCurrentSchedules = createSelector(
  [globalSelector],
  ({ currentSchedules }) => currentSchedules
);

export const getCurrentMarketId = createSelector(
  [globalSelector],
  ({ currentMarketId }) => currentMarketId
);
export const getCurrentBranchId = createSelector(
  [globalSelector],
  ({ currentBranchId }) => currentBranchId
);
export const getCurrentMerchantId = createSelector(
  [globalSelector],
  ({ currentMerchantId }) => currentMerchantId
);
export const getCurrentPermissionId = createSelector(
  [globalSelector],
  ({ currentPermissionId }) => currentPermissionId
);

export const getPermissionLimitedPages = createSelector(
  [globalSelector],
  ({ permissionLimitedPages }) => permissionLimitedPages
);
export const getIsCurrentKitchenActive = createSelector(
  [globalSelector],
  ({ isCurrentKitchenActive }) => isCurrentKitchenActive
);

export const getTodaySchedule = createSelector(
  [globalSelector],
  ({ todaySchedule }) => todaySchedule
);
export const getIsTodayInSchedule = createSelector(
  [globalSelector],
  ({ isTodayInSchedule }) => isTodayInSchedule
);

export const getTodayAttendance = createSelector(
  [globalSelector],
  ({ todayAttendance }) => todayAttendance
);
export const getIsTodayHasAttendance = createSelector(
  [globalSelector],
  ({ isTodayHasAttendance }) => isTodayHasAttendance
);

export const getMarketPlans = createSelector(
  [globalSelector],
  ({ marketPlans }) => marketPlans
);
export const getBranchPlans = createSelector(
  [globalSelector],
  ({ branchPlans }) => branchPlans
);

export const getIsHasActivePlan = createSelector(
  [globalSelector],
  ({ isHasActivePlan }) => isHasActivePlan
);
export const getIsHasActiveAdvancedPlan = createSelector(
  [globalSelector],
  ({ isHasActiveAdvancedPlan }) => isHasActiveAdvancedPlan
);
export const getIsHasActiveBasicPlan = createSelector(
  [globalSelector],
  ({ isHasActiveBasicPlan }) => isHasActiveBasicPlan
);
export const getIsHasActiveTrialPlan = createSelector(
  [globalSelector],
  ({ isHasActiveTrialPlan }) => isHasActiveTrialPlan
);

export const getIsMarketHasPlan = createSelector(
  [globalSelector],
  ({ isMarketHasPlan }) => isMarketHasPlan
);
export const getIsMarketHasAdvancedPlan = createSelector(
  [globalSelector],
  ({ isMarketHasAdvancedPlan }) => isMarketHasAdvancedPlan
);
export const getIsMarketHasBasicPlan = createSelector(
  [globalSelector],
  ({ isMarketHasBasicPlan }) => isMarketHasBasicPlan
);
export const getIsMarketHasTrialPlan = createSelector(
  [globalSelector],
  ({ isMarketHasTrialPlan }) => isMarketHasTrialPlan
);

export const getIsBranchHasPlan = createSelector(
  [globalSelector],
  ({ isBranchHasPlan }) => isBranchHasPlan
);
export const getIsBranchHasAdvancedPlan = createSelector(
  [globalSelector],
  ({ isBranchHasAdvancedPlan }) => isBranchHasAdvancedPlan
);
export const getIsBranchHasBasicPlan = createSelector(
  [globalSelector],
  ({ isBranchHasBasicPlan }) => isBranchHasBasicPlan
);
export const getIsBranchHasTrialPlan = createSelector(
  [globalSelector],
  ({ isBranchHasTrialPlan }) => isBranchHasTrialPlan
);

export const getPlanBillingCycles = createSelector(
  [globalSelector],
  ({ planBillingCycles }) => planBillingCycles
);
export const getGlobalSubscriptions = createSelector(
  [globalSelector],
  ({ globalSubscriptions }) => globalSubscriptions
);
export const getIsGlobalSubscriptionsHasMore = createSelector(
  [globalSelector],
  ({ isGlobalSubscriptionsHasMore }) => isGlobalSubscriptionsHasMore
);

export const getFetchCurrentMarketLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketLoading }) => fetchCurrentMarketLoading
);
export const getFetchCurrentMarketSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketSuccess }) => fetchCurrentMarketSuccess
);
export const getFetchCurrentMarketFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentMarketFailed }) => fetchCurrentMarketFailed
);

export const getFetchCurrentBranchLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchLoading }) => fetchCurrentBranchLoading
);
export const getFetchCurrentBranchSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchSuccess }) => fetchCurrentBranchSuccess
);
export const getFetchCurrentBranchFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentBranchFailed }) => fetchCurrentBranchFailed
);

export const getFetchCurrentMerchantLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantLoading }) => fetchCurrentMerchantLoading
);
export const getFetchCurrentMerchantSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantSuccess }) => fetchCurrentMerchantSuccess
);
export const getFetchCurrentMerchantFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentMerchantFailed }) => fetchCurrentMerchantFailed
);

export const getFetchCurrentPermissionLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentPermissionLoading }) => fetchCurrentPermissionLoading
);
export const getFetchCurrentPermissionSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentPermissionSuccess }) => fetchCurrentPermissionSuccess
);
export const getFetchCurrentPermissionFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentPermissionFailed }) => fetchCurrentPermissionFailed
);

export const getFetchCurrentSchedulesIncludes = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesIncludes }) => fetchCurrentSchedulesIncludes
);
export const getFetchCurrentSchedulesFilterMarketId = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFilterMarketId }) =>
    fetchCurrentSchedulesFilterMarketId
);
export const getFetchCurrentSchedulesFilterBranchId = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFilterBranchId }) =>
    fetchCurrentSchedulesFilterBranchId
);
export const getFetchCurrentSchedulesLoading = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesLoading }) => fetchCurrentSchedulesLoading
);
export const getFetchCurrentSchedulesSuccess = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesSuccess }) => fetchCurrentSchedulesSuccess
);
export const getFetchCurrentSchedulesFailed = createSelector(
  [globalSelector],
  ({ fetchCurrentSchedulesFailed }) => fetchCurrentSchedulesFailed
);

export const getFetchTodayAttendanceMarketId = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceMarketId }) => fetchTodayAttendanceMarketId
);
export const getFetchTodayAttendanceBranchId = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceBranchId }) => fetchTodayAttendanceBranchId
);
export const getFetchTodayAttendanceLoading = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceLoading }) => fetchTodayAttendanceLoading
);
export const getFetchTodayAttendanceSuccess = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceSuccess }) => fetchTodayAttendanceSuccess
);
export const getFetchTodayAttendanceFailed = createSelector(
  [globalSelector],
  ({ fetchTodayAttendanceFailed }) => fetchTodayAttendanceFailed
);

export const getFetchGlobalSubscriptionsSearch = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsSearch }) => fetchGlobalSubscriptionsSearch
);
export const getFetchGlobalSubscriptionsSort = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsSort }) => fetchGlobalSubscriptionsSort
);
export const getFetchGlobalSubscriptionsKeyBy = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsKeyBy }) => fetchGlobalSubscriptionsKeyBy
);
export const getFetchGlobalSubscriptionsPage = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsPage }) => fetchGlobalSubscriptionsPage
);
export const getFetchGlobalSubscriptionsPerPage = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsPerPage }) => fetchGlobalSubscriptionsPerPage
);
export const getFetchGlobalSubscriptionsIncludes = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsIncludes }) => fetchGlobalSubscriptionsIncludes
);
export const getFetchGlobalSubscriptionsLoading = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsLoading }) => fetchGlobalSubscriptionsLoading
);
export const getFetchGlobalSubscriptionsSuccess = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsSuccess }) => fetchGlobalSubscriptionsSuccess
);
export const getFetchGlobalSubscriptionsFailed = createSelector(
  [globalSelector],
  ({ fetchGlobalSubscriptionsFailed }) => fetchGlobalSubscriptionsFailed
);

export const getIsFetchCurrentMarketHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentMarketHitted }) => isFetchCurrentMarketHitted
);
export const getIsFetchCurrentBranchHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentBranchHitted }) => isFetchCurrentBranchHitted
);
export const getIsFetchCurrentMerchantHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentMerchantHitted }) => isFetchCurrentMerchantHitted
);
export const getIsFetchCurrentPermissionHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentPermissionHitted }) => isFetchCurrentPermissionHitted
);
export const getIsFetchCurrentSchedulesHitted = createSelector(
  [globalSelector],
  ({ isFetchCurrentSchedulesHitted }) => isFetchCurrentSchedulesHitted
);
export const getIsFetchTodayAttendanceHitted = createSelector(
  [globalSelector],
  ({ isFetchTodayAttendanceHitted }) => isFetchTodayAttendanceHitted
);
export const getIsFetchGlobalSubscriptionsHitted = createSelector(
  [globalSelector],
  ({ isFetchGlobalSubscriptionsHitted }) => isFetchGlobalSubscriptionsHitted
);
