import { t } from "i18next";

import { getConstantFormat } from "../utils/formatter.utils";
import { isEmpty } from "../utils/validation.utils";

import { ReactComponent as CheckSvg } from "../assets/icons/Check.svg";
import { ReactComponent as ClockSvg } from "../assets/icons/Clock.svg";
import { ReactComponent as CloseSvg } from "../assets/icons/Close.svg";
import { ReactComponent as MoreCircleSvg } from "../assets/icons/MoreCircle.svg";
import { ReactComponent as SendSvg } from "../assets/icons/Send.svg";

export const ORDER_TABLE_COLUMNS = {
  NUMBER: "NUMBER",
  DATE_TIME: "DATE_TIME",
  CUSTOMER: "CUSTOMER",
  CASHIER: "CASHIER",
  SOURCE: "SOURCE",
  TYPE: "TYPE",
  SUBTOTAL: "SUBTOTAL",
  TAX: "TAX",
  DISCOUNT: "DISCOUNT",
  TOTAL: "TOTAL",
  STATUS: "STATUS",
};

export const ORDER_INCLUDES = {
  MARKET: "market",
  BRANCH: "branch",
  TABLE: "table",
  CUSTOMER: "customer",
  CASHIER: "cashier",
  TRANSACTION: "transaction",
  PRODUCTS: "products",
  RETURN_PRODUCTS: "returnProducts",
  CHANGED_PRODUCTS: "changedProducts",
  PRODUCT_FEES: "productFees",
  PRODUCT_DISCOUNT_FEES: "productDiscountFees",
  PRODUCT_TAX_FEES: "productTaxFees",
  PRODUCT_RATINGS: "productRatings",
  STATUS_LOGS: "statusLogs",
};

export const ORDER_SOURCES = {
  ADMIN_APP: "ADMIN_APP",
  ADMIN_WEB: "ADMIN_WEB",
  LANDING_APP: "LANDING_APP",
  LANDING_WEB: "LANDING_WEB",
};

export const ORDER_SERVICE_TYPES = {
  TAKE_AWAY: "TAKE_AWAY",
  DINING: "DINING",
  DRIVE_THROUGH: "DRIVE_THROUGH",
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};

export const ORDER_STATUSES = {
  WAITING: "WAITING",
  PROCESS: "PROCESS",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  ON_DELIVERY: "ON_DELIVERY",
  DELIVERED: "DELIVERED",
};

export const getSourceName = (source) =>
  ({
    [ORDER_SOURCES.ADMIN_APP]: t("Cashier App"),
    [ORDER_SOURCES.ADMIN_WEB]: t("Cashier Web"),
    [ORDER_SOURCES.LANDING_APP]: t("Flavours App"),
    [ORDER_SOURCES.LANDING_WEB]: t("Flavours Web"),
  }?.[source] ?? (!isEmpty(source) ? getConstantFormat(source) : "-"));

export const getServiceTypeName = (serviceType) =>
  ({
    [ORDER_SERVICE_TYPES.DINING]: t("Dining"),
    [ORDER_SERVICE_TYPES.TAKE_AWAY]: t("Take Away"),
    [ORDER_SERVICE_TYPES.DRIVE_THROUGH]: t("Drive Through"),
    [ORDER_SERVICE_TYPES.DELIVERY]: t("Delivery"),
    [ORDER_SERVICE_TYPES.PICKUP]: t("Pickup"),
  }?.[serviceType] ?? (!isEmpty(serviceType) ? getConstantFormat(serviceType) : "-"));

export const getTableColumnName = (column) =>
  ({
    [ORDER_TABLE_COLUMNS.NUMBER]: "Order Number",
    [ORDER_TABLE_COLUMNS.DATE_TIME]: "Date & Time",
    [ORDER_TABLE_COLUMNS.CUSTOMER]: "Customer Info",
    [ORDER_TABLE_COLUMNS.CASHIER]: "Cashier Info",
    [ORDER_TABLE_COLUMNS.SOURCE]: "Order Source",
    [ORDER_TABLE_COLUMNS.TYPE]: "Service Type",
    [ORDER_TABLE_COLUMNS.SUBTOTAL]: "Subtotal",
    [ORDER_TABLE_COLUMNS.TAX]: "Tax",
    [ORDER_TABLE_COLUMNS.DISCOUNT]: "Discount",
    [ORDER_TABLE_COLUMNS.TOTAL]: "Total",
    [ORDER_TABLE_COLUMNS.STATUS]: "Order Status",
  }?.[column] ?? "-");

export const getStatusIcon = (status) =>
  ({
    [ORDER_STATUSES.WAITING]: MoreCircleSvg,
    [ORDER_STATUSES.PROCESS]: ClockSvg,
    [ORDER_STATUSES.COMPLETED]: CheckSvg,
    [ORDER_STATUSES.CANCELED]: CloseSvg,
    [ORDER_STATUSES.ON_DELIVERY]: SendSvg,
    [ORDER_STATUSES.DELIVERED]: CheckSvg,
  }?.[status] ?? MoreCircleSvg);

export const ORDER_WAITING_DISPLAYS = {
  DISPLAY_1: "DISPLAY_1",
  DISPLAY_2: "DISPLAY_2",
};
