import { takeLatest, put, all, call, select } from "redux-saga/effects";

import MARKET_ACTION_TYPES from "./market.type";

import {
  appendMarkets,
  appendSelectMarkets,
  appendSubscriptionMarkets,
  setAppendSelectMarketsFailed,
  setAppendSelectMarketsLoading,
  setAppendSelectMarketsSuccess,
  setCreateMarketFailed,
  setCreateMarketLoading,
  setCreateMarketSuccess,
  setDeleteMarketFailed,
  setDeleteMarketLoading,
  setDeleteMarketSuccess,
  setFetchMarketFailed,
  setFetchMarketLoading,
  setFetchMarketsFailed,
  setFetchMarketsLoading,
  setFetchMarketsSuccess,
  setFetchMarketSuccess,
  setFetchSelectMarketsFailed,
  setFetchSelectMarketsLoading,
  setFetchSelectMarketsSuccess,
  setFetchSubscriptionMarketsFailed,
  setFetchSubscriptionMarketsLoading,
  setFetchSubscriptionMarketsSuccess,
  setIsAppendSelectMarketsHitted,
  setIsCreateMarketHitted,
  setIsDeleteMarketHitted,
  setIsFetchMarketHitted,
  setIsFetchMarketsHitted,
  setIsFetchSelectMarketsHitted,
  setIsFetchSubscriptionMarketsHitted,
  setIsMarketsHasMore,
  setIsSelectMarketsHasMore,
  setIsSubscriptionMarketsHasMore,
  setIsUpdateMarketHitted,
  setMarket,
  setMarkets,
  setSubscriptionMarkets,
  setUpdateMarketFailed,
  setUpdateMarketLoading,
  setUpdateMarketSuccess,
} from "./market.action";
import {
  getAppendSelectMarketsFilterCreatedBy,
  getAppendSelectMarketsFilterMarketsIds,
  getAppendSelectMarketsFilterMerchantId,
  getAppendSelectMarketsFilterResellerId,
  getAppendSelectMarketsFilterSectorId,
  getAppendSelectMarketsIncludes,
  getAppendSelectMarketsKeyBy,
  getAppendSelectMarketsPage,
  getAppendSelectMarketsPerPage,
  getAppendSelectMarketsSearch,
  getAppendSelectMarketsSort,
  getFetchMarketsFilterCreatedBy,
  getFetchMarketsFilterMarketsIds,
  getFetchMarketsFilterMerchantId,
  getFetchMarketsFilterResellerId,
  getFetchMarketsFilterSectorId,
  getFetchMarketsIncludes,
  getFetchMarketsKeyBy,
  getFetchMarketsPage,
  getFetchMarketsPerPage,
  getFetchMarketsSearch,
  getFetchMarketsSort,
  getFetchSelectMarketsFilterCreatedBy,
  getFetchSelectMarketsFilterMarketsIds,
  getFetchSelectMarketsFilterMerchantId,
  getFetchSelectMarketsFilterResellerId,
  getFetchSelectMarketsFilterSectorId,
  getFetchSelectMarketsIncludes,
  getFetchSelectMarketsKeyBy,
  getFetchSelectMarketsPage,
  getFetchSelectMarketsPerPage,
  getFetchSelectMarketsSearch,
  getFetchSelectMarketsSort,
  getFetchSubscriptionMarketsFilterCreatedBy,
  getFetchSubscriptionMarketsFilterMarketsIds,
  getFetchSubscriptionMarketsFilterMerchantId,
  getFetchSubscriptionMarketsFilterResellerId,
  getFetchSubscriptionMarketsFilterSectorId,
  getFetchSubscriptionMarketsIncludes,
  getFetchSubscriptionMarketsKeyBy,
  getFetchSubscriptionMarketsPage,
  getFetchSubscriptionMarketsPerPage,
  getFetchSubscriptionMarketsSearch,
  getFetchSubscriptionMarketsSort,
  getIsFetchMarketsHitted,
  getIsFetchSelectMarketsHitted,
  getIsFetchSubscriptionMarketsHitted,
} from "./market.selector";
import { setCurrentMarket } from "../global/global.action";
import { getCurrentMarketId } from "../global/global.selector";

import {
  getMarkets,
  getMarket,
  updateMarket,
  deleteMarket,
  createMarket,
} from "../../api/market.api";

export function* _getMarkets() {
  try {
    yield put(setFetchMarketsLoading(true));

    const search = yield select(getFetchMarketsSearch);
    const sort = yield select(getFetchMarketsSort);
    const key_by = yield select(getFetchMarketsKeyBy);
    const page = yield select(getFetchMarketsPage);
    const per_page = yield select(getFetchMarketsPerPage);
    const includes = yield select(getFetchMarketsIncludes);
    const markets_ids = yield select(getFetchMarketsFilterMarketsIds);
    const sector_id = yield select(getFetchMarketsFilterSectorId);
    const merchant_id = yield select(getFetchMarketsFilterMerchantId);
    const reseller_id = yield select(getFetchMarketsFilterResellerId);
    const created_by = yield select(getFetchMarketsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        markets_ids,
        sector_id,
        merchant_id,
        reseller_id,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: markets },
    } = yield call(getMarkets, parameters);

    yield put(setIsFetchMarketsHitted(true));
    yield put(setIsMarketsHasMore(markets.length > 0));

    if (page > 1) {
      yield put(appendMarkets(markets));
    } else {
      yield put(setMarkets(markets));
    }

    yield put(setFetchMarketsSuccess(message));
    yield put(setFetchMarketsLoading(false));
  } catch (error) {
    yield put(setFetchMarketsFailed(error));
    yield put(setFetchMarketsLoading(false));
  }
}
export function* _getSelectMarkets() {
  try {
    yield put(setFetchSelectMarketsLoading(true));

    const search = yield select(getFetchSelectMarketsSearch);
    const sort = yield select(getFetchSelectMarketsSort);
    const key_by = yield select(getFetchSelectMarketsKeyBy);
    const page = yield select(getFetchSelectMarketsPage);
    const per_page = yield select(getFetchSelectMarketsPerPage);
    const includes = yield select(getFetchSelectMarketsIncludes);
    const markets_ids = yield select(getFetchSelectMarketsFilterMarketsIds);
    const sector_id = yield select(getFetchSelectMarketsFilterSectorId);
    const merchant_id = yield select(getFetchSelectMarketsFilterMerchantId);
    const reseller_id = yield select(getFetchSelectMarketsFilterResellerId);
    const created_by = yield select(getFetchSelectMarketsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        markets_ids,
        sector_id,
        merchant_id,
        reseller_id,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: markets },
    } = yield call(getMarkets, parameters);

    yield put(setIsFetchSelectMarketsHitted(true));
    yield put(setIsSelectMarketsHasMore(markets.length > 0));
    yield put(appendSelectMarkets(markets));

    yield put(setFetchSelectMarketsSuccess(message));
    yield put(setFetchSelectMarketsLoading(false));
  } catch (error) {
    yield put(setFetchSelectMarketsFailed(error));
    yield put(setFetchSelectMarketsLoading(false));
  }
}
export function* _getSubscriptionMarkets() {
  try {
    yield put(setFetchSubscriptionMarketsLoading(true));

    const search = yield select(getFetchSubscriptionMarketsSearch);
    const sort = yield select(getFetchSubscriptionMarketsSort);
    const key_by = yield select(getFetchSubscriptionMarketsKeyBy);
    const page = yield select(getFetchSubscriptionMarketsPage);
    const per_page = yield select(getFetchSubscriptionMarketsPerPage);
    const includes = yield select(getFetchSubscriptionMarketsIncludes);
    const markets_ids = yield select(
      getFetchSubscriptionMarketsFilterMarketsIds
    );
    const sector_id = yield select(getFetchSubscriptionMarketsFilterSectorId);
    const merchant_id = yield select(
      getFetchSubscriptionMarketsFilterMerchantId
    );
    const reseller_id = yield select(
      getFetchSubscriptionMarketsFilterResellerId
    );
    const created_by = yield select(getFetchSubscriptionMarketsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        markets_ids,
        sector_id,
        merchant_id,
        reseller_id,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: markets },
    } = yield call(getMarkets, parameters);

    yield put(setIsFetchSubscriptionMarketsHitted(true));
    yield put(setIsSubscriptionMarketsHasMore(markets.length > 0));

    if (page > 1) {
      yield put(appendSubscriptionMarkets(markets));
    } else {
      yield put(setSubscriptionMarkets(markets));
    }

    yield put(setFetchSubscriptionMarketsSuccess(message));
    yield put(setFetchSubscriptionMarketsLoading(false));
  } catch (error) {
    yield put(setFetchSubscriptionMarketsFailed(error));
    yield put(setFetchSubscriptionMarketsLoading(false));
  }
}
export function* _appendSelectMarkets() {
  try {
    yield put(setAppendSelectMarketsLoading(true));

    const search = yield select(getAppendSelectMarketsSearch);
    const sort = yield select(getAppendSelectMarketsSort);
    const key_by = yield select(getAppendSelectMarketsKeyBy);
    const page = yield select(getAppendSelectMarketsPage);
    const per_page = yield select(getAppendSelectMarketsPerPage);
    const includes = yield select(getAppendSelectMarketsIncludes);
    const markets_ids = yield select(getAppendSelectMarketsFilterMarketsIds);
    const sector_id = yield select(getAppendSelectMarketsFilterSectorId);
    const merchant_id = yield select(getAppendSelectMarketsFilterMerchantId);
    const reseller_id = yield select(getAppendSelectMarketsFilterResellerId);
    const created_by = yield select(getAppendSelectMarketsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        markets_ids,
        sector_id,
        merchant_id,
        reseller_id,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: markets },
    } = yield call(getMarkets, parameters);

    yield put(setIsAppendSelectMarketsHitted(true));
    yield put(appendSelectMarkets(markets));

    yield put(setAppendSelectMarketsSuccess(message));
    yield put(setAppendSelectMarketsLoading(false));
  } catch (error) {
    yield put(setAppendSelectMarketsFailed(error));
    yield put(setAppendSelectMarketsLoading(false));
  }
}
export function* _getMarket({ payload: marketId }) {
  try {
    yield put(setFetchMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, marketId);

    yield put(setIsFetchMarketHitted(true));
    yield put(setMarket(market));

    yield put(setFetchMarketSuccess(message));
    yield put(setFetchMarketLoading(false));
  } catch (error) {
    yield put(setFetchMarketFailed(error));
    yield put(setFetchMarketLoading(false));
  }
}
export function* _createMarket({ payload: request }) {
  try {
    yield put(setCreateMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(createMarket, request);

    yield put(setIsCreateMarketHitted(true));
    yield put(setMarket(market));

    const isFetchMarketsHitted = yield select(getIsFetchMarketsHitted);
    const isFetchSelectMarketsHitted = yield select(
      getIsFetchSelectMarketsHitted
    );
    const isFetchSubscriptionMarketsHitted = yield select(
      getIsFetchSubscriptionMarketsHitted
    );

    if (isFetchMarketsHitted) {
      yield put(setIsFetchMarketsHitted(1));
      yield call(_getMarkets);
    }
    if (isFetchSelectMarketsHitted) {
      yield put(setIsFetchSelectMarketsHitted(1));
      yield call(_getSelectMarkets);
    }
    if (isFetchSubscriptionMarketsHitted) {
      yield put(setIsFetchSubscriptionMarketsHitted(1));
      yield call(_getSubscriptionMarkets);
    }

    yield put(setCreateMarketSuccess(message));
    yield put(setCreateMarketLoading(false));
  } catch (error) {
    yield put(setCreateMarketFailed(error));
    yield put(setCreateMarketLoading(false));
  }
}
export function* _updateMarket({ payload: { marketId, request } }) {
  try {
    yield put(setUpdateMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(updateMarket, marketId, request);

    yield put(setIsUpdateMarketHitted(true));
    yield put(setMarket(market));

    const currentMarketId = yield select(getCurrentMarketId);
    if (marketId === currentMarketId) yield put(setCurrentMarket(market));

    const isFetchMarketsHitted = yield select(getIsFetchMarketsHitted);
    const isFetchSelectMarketsHitted = yield select(
      getIsFetchSelectMarketsHitted
    );
    const isFetchSubscriptionMarketsHitted = yield select(
      getIsFetchSubscriptionMarketsHitted
    );

    if (isFetchMarketsHitted) {
      yield put(setIsFetchMarketsHitted(1));
      yield call(_getMarkets);
    }
    if (isFetchSelectMarketsHitted) {
      yield put(setIsFetchSelectMarketsHitted(1));
      yield call(_getSelectMarkets);
    }
    if (isFetchSubscriptionMarketsHitted) {
      yield put(setIsFetchSubscriptionMarketsHitted(1));
      yield call(_getSubscriptionMarkets);
    }

    yield put(setUpdateMarketSuccess(message));
    yield put(setUpdateMarketLoading(false));
  } catch (error) {
    yield put(setUpdateMarketFailed(error));
    yield put(setUpdateMarketLoading(false));
  }
}
export function* _deleteMarket({ payload: marketId }) {
  try {
    yield put(setDeleteMarketLoading(true));

    const {
      meta: { message },
    } = yield call(deleteMarket, marketId);

    yield put(setIsDeleteMarketHitted(true));

    const isFetchMarketsHitted = yield select(getIsFetchMarketsHitted);
    const isFetchSelectMarketsHitted = yield select(
      getIsFetchSelectMarketsHitted
    );
    const isFetchSubscriptionMarketsHitted = yield select(
      getIsFetchSubscriptionMarketsHitted
    );

    if (isFetchMarketsHitted) {
      yield put(setIsFetchMarketsHitted(1));
      yield call(_getMarkets);
    }
    if (isFetchSelectMarketsHitted) {
      yield put(setIsFetchSelectMarketsHitted(1));
      yield call(_getSelectMarkets);
    }
    if (isFetchSubscriptionMarketsHitted) {
      yield put(setIsFetchSubscriptionMarketsHitted(1));
      yield call(_getSubscriptionMarkets);
    }

    yield put(setDeleteMarketSuccess(message));
    yield put(setDeleteMarketLoading(false));
  } catch (error) {
    yield put(setDeleteMarketFailed(error));
    yield put(setDeleteMarketLoading(false));
  }
}

export function* onFetchMarketsStart() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_MARKETS_START, _getMarkets);
}
export function* onFetchSelectMarketsStart() {
  yield takeLatest(
    MARKET_ACTION_TYPES.FETCH_SELECT_MARKETS_START,
    _getSelectMarkets
  );
}
export function* onFetchSubscriptionMarketsStart() {
  yield takeLatest(
    MARKET_ACTION_TYPES.FETCH_SUBSCRIPTION_MARKETS_START,
    _getSubscriptionMarkets
  );
}
export function* onFetchMarketStart() {
  yield takeLatest(MARKET_ACTION_TYPES.FETCH_MARKET_START, _getMarket);
}
export function* onCreateMarketStart() {
  yield takeLatest(MARKET_ACTION_TYPES.CREATE_MARKET_START, _createMarket);
}
export function* onUpdateMarketStart() {
  yield takeLatest(MARKET_ACTION_TYPES.UPDATE_MARKET_START, _updateMarket);
}
export function* onDeleteMarketStart() {
  yield takeLatest(MARKET_ACTION_TYPES.DELETE_MARKET_START, _deleteMarket);
}
export function* onAppendSelectMarketsStart() {
  yield takeLatest(
    MARKET_ACTION_TYPES.APPEND_SELECT_MARKETS_START,
    _appendSelectMarkets
  );
}

export function* marketSaga() {
  yield all([
    call(onFetchMarketsStart),
    call(onFetchSelectMarketsStart),
    call(onFetchSubscriptionMarketsStart),
    call(onFetchMarketStart),
    call(onCreateMarketStart),
    call(onUpdateMarketStart),
    call(onDeleteMarketStart),
    call(onAppendSelectMarketsStart),
  ]);
}
