import DASHBOARD_SUBSCRIBER_ACTION_TYPES from "./dashboard-subscriber.type";
import { createAction } from "../../utils/store.utils";

export const setDashboardActiveBranches = (dashboardActiveBranches) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ACTIVE_BRANCHES,
    dashboardActiveBranches
  );
export const setDashboardLatestOrders = (dashboardLatestOrders) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_ORDERS,
    dashboardLatestOrders
  );
export const setDashboardLatestReviews = (dashboardLatestReviews) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_REVIEWS,
    dashboardLatestReviews
  );
export const setDashboardOrdersChart = (dashboardOrdersChart) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART,
    dashboardOrdersChart
  );
export const setDashboardPopularProducts = (dashboardPopularProducts) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_POPULAR_PRODUCTS,
    dashboardPopularProducts
  );

export const setDashboardActiveBranchesLoading = (
  dashboardActiveBranchesLoading
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ACTIVE_BRANCHES_LOADING,
    dashboardActiveBranchesLoading
  );
export const setDashboardLatestOrdersLoading = (dashboardLatestOrdersLoading) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_ORDERS_LOADING,
    dashboardLatestOrdersLoading
  );
export const setDashboardLatestReviewsLoading = (
  dashboardLatestReviewsLoading
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_REVIEWS_LOADING,
    dashboardLatestReviewsLoading
  );
export const setDashboardOrdersChartLoading = (dashboardOrdersChartLoading) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART_LOADING,
    dashboardOrdersChartLoading
  );
export const setDashboardPopularProductsLoading = (
  dashboardPopularProductsLoading
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_POPULAR_PRODUCTS_LOADING,
    dashboardPopularProductsLoading
  );

export const setFetchDashboardSubscriberFilterMarketId = (
  fetchDashboardSubscriberFilterMarketId
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID,
    fetchDashboardSubscriberFilterMarketId
  );
export const setFetchDashboardSubscriberFilterBranchId = (
  fetchDashboardSubscriberFilterBranchId
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID,
    fetchDashboardSubscriberFilterBranchId
  );
export const setFetchDashboardSubscriberFilterTimePeriod = (
  fetchDashboardSubscriberFilterTimePeriod
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TIME_PERIOD,
    fetchDashboardSubscriberFilterTimePeriod
  );
export const setFetchDashboardSubscriberLoading = (
  fetchDashboardSubscriberLoading
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING,
    fetchDashboardSubscriberLoading
  );
export const setFetchDashboardSubscriberSuccess = (
  fetchDashboardSubscriberSuccess
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS,
    fetchDashboardSubscriberSuccess
  );
export const setFetchDashboardSubscriberFailed = (
  fetchDashboardSubscriberFailed
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED,
    fetchDashboardSubscriberFailed
  );

export const setIsFetchDashboardSubscriberHitted = (
  isFetchDashboardSubscriberHitted
) =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED,
    isFetchDashboardSubscriberHitted
  );

export const fetchDashboardSubscriberStart = () =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.FETCH_DASHBOARD_SUBSCRIBER_START
  );

export const resetDashboardSubscriberReducer = () =>
  createAction(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.RESET_DASHBOARD_SUBSCRIBER_REDUCER
  );
