export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const BASE_CHANNEL_URL = process.env.REACT_APP_BASE_CHANNEL_URL;

export const POS_PAGE_URL = process.env.REACT_APP_POS_PAGE_URL;
export const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;
export const ZATCA_BASE_URL = process.env.REACT_APP_ZATCA_BASE_URL;

export const TAP_PAYMENT_PUBLIC_KEY =
  process.env.REACT_APP_TAP_PAYMENT_PUBLIC_KEY;
export const TAP_PAYMENT_MERCHANT_ID =
  process.env.REACT_APP_TAP_PAYMENT_MERCHANT_ID;
export const TAP_PAYMENT_MERCHANT_DOMAIN =
  process.env.REACT_APP_TAP_PAYMENT_MERCHANT_DOMAIN;

export const TIDIO_PUBLIC_KEY = process.env.REACT_APP_TIDIO_PUBLIC_KEY;
export const GOOGLE_ANALYTICS_MEASUREMENT_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
export const BALLURH_API_TOKEN = process.env.REACT_APP_BALLURH_API_TOKEN;

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const PUSHER_APP_ID = process.env.REACT_APP_PUSHER_APP_ID;
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;
export const PUSHER_APP_HOST = process.env.REACT_APP_PUSHER_APP_HOST;
export const PUSHER_APP_PORT = process.env.REACT_APP_PUSHER_APP_PORT;
export const PUSHER_APP_TLS = process.env.REACT_APP_PUSHER_APP_TLS;
export const PUSHER_APP_ENCRYPTED = process.env.REACT_APP_PUSHER_APP_ENCRYPTED;
export const PUSHER_APP_STATS = process.env.REACT_APP_PUSHER_APP_STATS;

export const PUSHER_APP_CONFIG = {
  wsHost: PUSHER_APP_HOST,
  wsPort: PUSHER_APP_PORT,
  forceTLS: PUSHER_APP_TLS,
  encrypted: PUSHER_APP_ENCRYPTED,
  disableStats: PUSHER_APP_STATS,
  enabledTransports: ["ws", "wss"],
  cluster: PUSHER_APP_CLUSTER,
};
