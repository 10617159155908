import { parseToInteger } from "./parser.utils";

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  value === "" ||
  value === "null" ||
  value === "undefined" ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === "object" &&
    !(value instanceof File) &&
    Object.keys(value).length === 0);

export const isValidJson = (jsonStringify) => {
  try {
    JSON.parse(jsonStringify);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidUrl = (stringUrl) => {
  try {
    new URL(stringUrl);
    return true;
  } catch (e) {
    return false;
  }
};

export const isInvalidNumber = (number) =>
  isEmpty(number) ||
  isNaN(number) ||
  number === Number.POSITIVE_INFINITY ||
  number === Number.NEGATIVE_INFINITY;

const getLowercase = (char) =>
  char === char.toUpperCase() ? ` ${char}`.toLowerCase() : char;

const getFieldFormatted = (field) => {
  field = field.replaceAll("_id", " ");
  field = field.replaceAll("_", " ");
  field = field.split("").reduce((a, b) => `${a}${getLowercase(b)}`, "");

  return field;
};

export const rule = {
  required: () => (value, field) =>
    isEmpty(value) && {
      rule: "required",
      message: `The ${field} field is required.`,
    },
  minLength: (min) => (value, field) =>
    !isEmpty(value) &&
    `${value}`.length < parseToInteger(min) && {
      rule: "minLength",
      message: `The ${field} must be at least ${min} characters.`,
    },
  notEmptyArray: () => (value, field) =>
    Array.isArray(value) &&
    value.length === 0 && {
      rule: "notEmptyArray",
      message: `The ${field} must have at least 1 items.`,
    },
  email: () => (value, field) =>
    !isEmpty(value) &&
    !/\S+@\S+\.\S+/.test(value) && {
      rule: "email",
      message: `The ${field} must be a valid email address.`,
    },
  hasLowerCase: () => (value, field) =>
    !isEmpty(value) &&
    !/[a-z]/.test(value) && {
      rule: "hasLowerCase",
      message: `The ${field} must contain at least one lowercase character.`,
    },
  hasUpperCase: () => (value, field) =>
    !isEmpty(value) &&
    !/[A-Z]/.test(value) && {
      rule: "hasUpperCase",
      message: `The ${field} must contain at least one uppercase character.`,
    },
  hasNumber: () => (value, field) =>
    !isEmpty(value) &&
    !/[0-9]/.test(value) && {
      rule: "hasNumber",
      message: `The ${field} must contain at least one number.`,
    },
  hasSpecial: () => (value, field) =>
    !isEmpty(value) &&
    !/[@$!%*?&#]/.test(value) && {
      rule: "hasSpecial",
      message: `The ${field} must contain at least one special character.`,
    },
};

export const getValidationErrors = (fieldValues, fieldValidators) => {
  const errors = [];
  Object.entries(fieldValidators).forEach(([field, validators]) => {
    [validators].flat().forEach((validator) => {
      const result = validator(fieldValues[field], getFieldFormatted(field));
      if (result?.message) errors.push({ ...result, field });
    });
  });

  return errors;
};
