import { createSelector } from "reselect";

const reportAttendanceSelector = ({ reportAttendance }) => reportAttendance;

export const getReportTotals = createSelector(
  [reportAttendanceSelector],
  ({ reportTotals }) => reportTotals
);
export const getReportAttendances = createSelector(
  [reportAttendanceSelector],
  ({ reportAttendances }) => reportAttendances
);

export const getIsReportAttendancesHasMore = createSelector(
  [reportAttendanceSelector],
  ({ isReportAttendancesHasMore }) => isReportAttendancesHasMore
);

export const getFetchReportsFilterMarketId = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportsFilterMarketId }) => fetchReportsFilterMarketId
);
export const getFetchReportsFilterBranchId = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportsFilterBranchId }) => fetchReportsFilterBranchId
);
export const getFetchReportsFilterUserId = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportsFilterUserId }) => fetchReportsFilterUserId
);
export const getFetchReportsFilterStartAtAfter = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportsFilterStartAtAfter }) => fetchReportsFilterStartAtAfter
);
export const getFetchReportsFilterEndAtBefore = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportsFilterEndAtBefore }) => fetchReportsFilterEndAtBefore
);

export const getFetchReportTotalsLoading = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportTotalsLoading }) => fetchReportTotalsLoading
);
export const getFetchReportTotalsSuccess = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportTotalsSuccess }) => fetchReportTotalsSuccess
);
export const getFetchReportTotalsFailed = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportTotalsFailed }) => fetchReportTotalsFailed
);

export const getFetchReportAttendancesSearch = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesSearch }) => fetchReportAttendancesSearch
);
export const getFetchReportAttendancesSort = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesSort }) => fetchReportAttendancesSort
);
export const getFetchReportAttendancesKeyBy = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesKeyBy }) => fetchReportAttendancesKeyBy
);
export const getFetchReportAttendancesPage = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesPage }) => fetchReportAttendancesPage
);
export const getFetchReportAttendancesPerPage = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesPerPage }) => fetchReportAttendancesPerPage
);
export const getFetchReportAttendancesIncludes = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesIncludes }) => fetchReportAttendancesIncludes
);
export const getFetchReportAttendancesLoading = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesLoading }) => fetchReportAttendancesLoading
);
export const getFetchReportAttendancesSuccess = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesSuccess }) => fetchReportAttendancesSuccess
);
export const getFetchReportAttendancesFailed = createSelector(
  [reportAttendanceSelector],
  ({ fetchReportAttendancesFailed }) => fetchReportAttendancesFailed
);

export const getIsFetchReportTotalsHitted = createSelector(
  [reportAttendanceSelector],
  ({ isFetchReportTotalsHitted }) => isFetchReportTotalsHitted
);
export const getIsFetchReportAttendancesHitted = createSelector(
  [reportAttendanceSelector],
  ({ isFetchReportAttendancesHitted }) => isFetchReportAttendancesHitted
);
