import apiService from "./api";

export const getOrders = async (parameters) => (await apiService.get("/v2/orders", parameters)).data;

export const getOrder = async (id) => (await apiService.get(`/v2/orders/${id}`)).data;

export const calculateOrder = async (request) => (await apiService.post("/v2/orders/calc", request)).data;

export const createOrder = async (request) => (await apiService.post("/v2/orders", request)).data;

export const updateOrder = async (id, request) => (await apiService.post(`/v2/orders/${id}`, request)).data;

export const deleteOrder = async (id) => (await apiService.delete(`/order/${id}`)).data;

export const checkoutOrder = async (id, request) => (await apiService.post(`/v2/orders/${id}/checkout`, request)).data;

export const returnOrder = async (id, request) => (await apiService.post(`/v2/orders/${id}/return`, request)).data;
