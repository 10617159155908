import { takeLatest, put, all, call, select } from "redux-saga/effects";

import DASHBOARD_SUBSCRIBER_ACTION_TYPES from "./dashboard-subscriber.type";

import {
  setFetchDashboardSubscriberFailed,
  setFetchDashboardSubscriberLoading,
  setFetchDashboardSubscriberSuccess,
  setIsFetchDashboardSubscriberHitted,
} from "./dashboard-subscriber.action";
import {
  getFetchDashboardSubscriberFilterBranchId,
  getFetchDashboardSubscriberFilterMarketId,
  getFetchDashboardSubscriberFilterTimePeriod,
} from "./dashboard-subscriber.selector";

import { getDashboardSubscriber } from "../../api/dashboard.api";

export function* _getDashboardSubscriber() {
  try {
    yield put(setFetchDashboardSubscriberLoading(true));

    const market_id = yield select(getFetchDashboardSubscriberFilterMarketId);
    const branch_id = yield select(getFetchDashboardSubscriberFilterBranchId);
    const time_period = yield select(
      getFetchDashboardSubscriberFilterTimePeriod
    );

    const parameters = {
      filter: {
        market_id,
        branch_id,
        time_period,
      },
    };

    const {
      meta: { message },
    } = yield call(getDashboardSubscriber, parameters);

    yield put(setIsFetchDashboardSubscriberHitted(true));

    yield put(setFetchDashboardSubscriberSuccess(message));
    yield put(setFetchDashboardSubscriberLoading(false));
  } catch (error) {
    yield put(setFetchDashboardSubscriberFailed(error));
    yield put(setFetchDashboardSubscriberLoading(false));
  }
}

export function* onFetchDashboardSubscriberStart() {
  yield takeLatest(
    DASHBOARD_SUBSCRIBER_ACTION_TYPES.FETCH_DASHBOARD_SUBSCRIBER_START,
    _getDashboardSubscriber
  );
}

export function* dashboardSubscriberSaga() {
  yield all([call(onFetchDashboardSubscriberStart)]);
}
