import apiService from "./api";

export const getSubscriptions = async (parameters) =>
  (await apiService.get("/v2/subscriptions", parameters)).data;
export const getSubscription = async (subscriptionId) =>
  (await apiService.get(`/v2/subscriptions/${subscriptionId}`)).data;
export const createSubscription = async (request) =>
  (await apiService.post("/v2/subscriptions", request)).data;
export const updateSubscription = async (subscriptionId, request) =>
  (await apiService.post(`/v2/subscriptions/${subscriptionId}`, request)).data;
export const deleteSubscription = async (subscriptionId) =>
  (await apiService.delete(`/v2/subscriptions/${subscriptionId}`)).data;
