const SUBSCRIPTION_ACTION_TYPES = {
  RESET_SUBSCRIPTION_REDUCER: "subscription/RESET_SUBSCRIPTION_REDUCER",

  SET_IS_MODAL_AUTO_CLOSE: "subscription/SET_IS_MODAL_AUTO_CLOSE",
  SET_EXTEND_SUBSCRIPTION: "subscription/SET_EXTEND_SUBSCRIPTION",
  SET_EXTEND_SUBSCRIPTION_KEY: "subscription/SET_EXTEND_SUBSCRIPTION_KEY",
  SET_EXTEND_SUBSCRIPTION_OPTION_KEY:
    "subscription/SET_EXTEND_SUBSCRIPTION_OPTION_KEY",

  SET_SUBSCRIPTIONS: "subscription/SET_SUBSCRIPTIONS",
  SET_SUBSCRIPTION: "subscription/SET_SUBSCRIPTION",

  SET_IS_SUBSCRIPTIONS_HAS_MORE: "subscription/SET_IS_SUBSCRIPTIONS_HAS_MORE",

  SET_FETCH_SUBSCRIPTIONS_SEARCH: "subscription/SET_FETCH_SUBSCRIPTIONS_SEARCH",
  SET_FETCH_SUBSCRIPTIONS_SORT: "subscription/SET_FETCH_SUBSCRIPTIONS_SORT",
  SET_FETCH_SUBSCRIPTIONS_KEY_BY: "subscription/SET_FETCH_SUBSCRIPTIONS_KEY_BY",
  SET_FETCH_SUBSCRIPTIONS_PAGE: "subscription/SET_FETCH_SUBSCRIPTIONS_PAGE",
  SET_FETCH_SUBSCRIPTIONS_PER_PAGE:
    "subscription/SET_FETCH_SUBSCRIPTIONS_PER_PAGE",
  SET_FETCH_SUBSCRIPTIONS_INCLUDES:
    "subscription/SET_FETCH_SUBSCRIPTIONS_INCLUDES",
  SET_FETCH_SUBSCRIPTIONS_LOADING:
    "subscription/SET_FETCH_SUBSCRIPTIONS_LOADING",
  SET_FETCH_SUBSCRIPTIONS_SUCCESS:
    "subscription/SET_FETCH_SUBSCRIPTIONS_SUCCESS",
  SET_FETCH_SUBSCRIPTIONS_FAILED: "subscription/SET_FETCH_SUBSCRIPTIONS_FAILED",

  SET_FETCH_SUBSCRIPTION_LOADING: "subscription/SET_FETCH_SUBSCRIPTION_LOADING",
  SET_FETCH_SUBSCRIPTION_SUCCESS: "subscription/SET_FETCH_SUBSCRIPTION_SUCCESS",
  SET_FETCH_SUBSCRIPTION_FAILED: "subscription/SET_FETCH_SUBSCRIPTION_FAILED",

  SET_CREATE_SUBSCRIPTION_LOADING:
    "subscription/SET_CREATE_SUBSCRIPTION_LOADING",
  SET_CREATE_SUBSCRIPTION_SUCCESS:
    "subscription/SET_CREATE_SUBSCRIPTION_SUCCESS",
  SET_CREATE_SUBSCRIPTION_FAILED: "subscription/SET_CREATE_SUBSCRIPTION_FAILED",

  SET_UPDATE_SUBSCRIPTION_LOADING:
    "subscription/SET_UPDATE_SUBSCRIPTION_LOADING",
  SET_UPDATE_SUBSCRIPTION_SUCCESS:
    "subscription/SET_UPDATE_SUBSCRIPTION_SUCCESS",
  SET_UPDATE_SUBSCRIPTION_FAILED: "subscription/SET_UPDATE_SUBSCRIPTION_FAILED",

  SET_DELETE_SUBSCRIPTION_LOADING:
    "subscription/SET_DELETE_SUBSCRIPTION_LOADING",
  SET_DELETE_SUBSCRIPTION_SUCCESS:
    "subscription/SET_DELETE_SUBSCRIPTION_SUCCESS",
  SET_DELETE_SUBSCRIPTION_FAILED: "subscription/SET_DELETE_SUBSCRIPTION_FAILED",

  APPEND_SUBSCRIPTIONS: "subscription/APPEND_SUBSCRIPTIONS",

  SET_IS_FETCH_SUBSCRIPTIONS_HITTED:
    "subscription/SET_IS_FETCH_SUBSCRIPTIONS_HITTED",
  SET_IS_FETCH_SUBSCRIPTION_HITTED:
    "subscription/SET_IS_FETCH_SUBSCRIPTION_HITTED",
  SET_IS_CREATE_SUBSCRIPTION_HITTED:
    "subscription/SET_IS_CREATE_SUBSCRIPTION_HITTED",
  SET_IS_UPDATE_SUBSCRIPTION_HITTED:
    "subscription/SET_IS_UPDATE_SUBSCRIPTION_HITTED",
  SET_IS_DELETE_SUBSCRIPTION_HITTED:
    "subscription/SET_IS_DELETE_SUBSCRIPTION_HITTED",

  FETCH_SUBSCRIPTIONS_START: "subscription/FETCH_SUBSCRIPTIONS_START",
  FETCH_SUBSCRIPTION_START: "subscription/FETCH_SUBSCRIPTION_START",
  CREATE_SUBSCRIPTION_START: "subscription/CREATE_SUBSCRIPTION_START",
  UPDATE_SUBSCRIPTION_START: "subscription/UPDATE_SUBSCRIPTION_START",
  DELETE_SUBSCRIPTION_START: "subscription/DELETE_SUBSCRIPTION_START",
};

export default SUBSCRIPTION_ACTION_TYPES;
