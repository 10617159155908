import { createSelector } from "reselect";

const dashboardSelector = ({ dashboard }) => dashboard;

export const getDashboard = createSelector([dashboardSelector], ({ dashboard }) => dashboard);

export const getFetchDashboardFilterMarketId = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterMarketId }) => fetchDashboardFilterMarketId
);
export const getFetchDashboardFilterBranchId = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterBranchId }) => fetchDashboardFilterBranchId
);
export const getFetchDashboardFilterTimePeriod = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterTimePeriod }) => fetchDashboardFilterTimePeriod
);
export const getFetchDashboardFilterCreatedAtBefore = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterCreatedAtBefore }) => fetchDashboardFilterCreatedAtBefore
);
export const getFetchDashboardFilterCreatedAtAfter = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterCreatedAtAfter }) => fetchDashboardFilterCreatedAtAfter
);
export const getFetchDashboardFilterCurrency = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFilterCurrency }) => fetchDashboardFilterCurrency
);
export const getFetchDashboardLoading = createSelector(
  [dashboardSelector],
  ({ fetchDashboardLoading }) => fetchDashboardLoading
);
export const getFetchDashboardSuccess = createSelector(
  [dashboardSelector],
  ({ fetchDashboardSuccess }) => fetchDashboardSuccess
);
export const getFetchDashboardFailed = createSelector(
  [dashboardSelector],
  ({ fetchDashboardFailed }) => fetchDashboardFailed
);

export const getIsFetchDashboardHitted = createSelector(
  [dashboardSelector],
  ({ isFetchDashboardHitted }) => isFetchDashboardHitted
);
