const STATE_SUBSCRIPTION_ACTION_TYPES = {
  RESET_STATE_SUBSCRIPTION_REDUCER:
    "state-subscription/RESET_STATE_SUBSCRIPTION_REDUCER",

  SET_SUBSCRIPTION_TAB: "state-subscription/SET_SUBSCRIPTION_TAB",

  SET_BILLING_BRANCHES_IDS: "state-subscription/SET_BILLING_BRANCHES_IDS",
  SET_BILLING_PLANS: "state-subscription/SET_BILLING_PLANS",
  SET_BILLING_PLAN: "state-subscription/SET_BILLING_PLAN",
  SET_BILLING_CYCLE: "state-subscription/SET_BILLING_CYCLE",
};

export default STATE_SUBSCRIPTION_ACTION_TYPES;
