import DASHBOARD_SUBSCRIBER_ACTION_TYPES from "./dashboard-subscriber.type";

export const DASHBOARD_SUBSCRIBER_INITIAL_STATE = {
  dashboardActiveBranches: [],
  dashboardLatestOrders: [],
  dashboardLatestReviews: [],
  dashboardOrdersChart: null,
  dashboardPopularProducts: [],

  dashboardActiveBranchesLoading: false,
  dashboardLatestOrdersLoading: false,
  dashboardLatestReviewsLoading: false,
  dashboardOrdersChartLoading: false,
  dashboardPopularProductsLoading: false,

  fetchDashboardSubscriberFilterMarketId: null,
  fetchDashboardSubscriberFilterBranchId: null,
  fetchDashboardSubscriberFilterTimePeriod: null,
  fetchDashboardSubscriberLoading: false,
  fetchDashboardSubscriberSuccess: null,
  fetchDashboardSubscriberFailed: null,

  isFetchDashboardSubscriberHitted: false,
};

export const dashboardSubscriberReducer = (
  state = DASHBOARD_SUBSCRIBER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ACTIVE_BRANCHES:
      return { ...state, dashboardActiveBranches: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_ORDERS:
      return { ...state, dashboardLatestOrders: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_REVIEWS:
      return { ...state, dashboardLatestReviews: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART:
      return { ...state, dashboardOrdersChart: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_POPULAR_PRODUCTS:
      return { ...state, dashboardPopularProducts: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ACTIVE_BRANCHES_LOADING:
      return { ...state, dashboardActiveBranchesLoading: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_ORDERS_LOADING:
      return { ...state, dashboardLatestOrdersLoading: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_LATEST_REVIEWS_LOADING:
      return { ...state, dashboardLatestReviewsLoading: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_ORDERS_CHART_LOADING:
      return { ...state, dashboardOrdersChartLoading: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_DASHBOARD_POPULAR_PRODUCTS_LOADING:
      return { ...state, dashboardPopularProductsLoading: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_MARKET_ID:
      return {
        ...state,
        fetchDashboardSubscriberFilterMarketId: payload,
      };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_BRANCH_ID:
      return {
        ...state,
        fetchDashboardSubscriberFilterBranchId: payload,
      };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FILTER_TIME_PERIOD:
      return { ...state, fetchDashboardSubscriberFilterTimePeriod: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_LOADING:
      return { ...state, fetchDashboardSubscriberLoading: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_SUCCESS:
      return { ...state, fetchDashboardSubscriberSuccess: payload };
    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_FETCH_DASHBOARD_SUBSCRIBER_FAILED:
      return { ...state, fetchDashboardSubscriberFailed: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_DASHBOARD_SUBSCRIBER_HITTED:
      return { ...state, isFetchDashboardSubscriberHitted: payload };

    case DASHBOARD_SUBSCRIBER_ACTION_TYPES.RESET_DASHBOARD_SUBSCRIBER_REDUCER:
      return DASHBOARD_SUBSCRIBER_INITIAL_STATE;
    default:
      return state;
  }
};
