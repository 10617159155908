import { STATUS_BADGE_TYPES } from "../widgets/status-badge/status-badge.widget";
import { EXTEND_PERIOD_STATUSES } from "./extend-period.constant";

export const SUBSCRIPTION_TABS = {
  PLANS: "PLANS",
  BILLING: "BILLING",
  USAGE: "USAGE",
  INVOICES: "INVOICES",
};

export const SUBSCRIPTION_PLANS = {
  TRIAL: "TRIAL",
  BASIC: "BASIC",
  ADVANCED: "ADVANCED",
  MIX: "MIX",
};

export const SUBSCRIPTION_PERIODS = {
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
};

export const SUBSCRIPTION_PHASES = {
  SUBSCRIBED: "SUBSCRIBED",
  ALMOST_FINISHED: "ALMOST_FINISHED",
  FINISHED: "FINISHED",
};

export const SUBSCRIPTIONS_KEYS = {
  ADVANCED: "ADVANCED",
  BASIC: "BASIC",
  TRIAL: "TRIAL",
};

export const SUBSCRIPTIONS_OPTION_KEYS = {
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
  WEEKLY: "WEEKLY",
};

export const getSubscriptionKey = (subscriptionKey) =>
  ({
    [SUBSCRIPTIONS_KEYS.ADVANCED]: "Advanced",
    [SUBSCRIPTIONS_KEYS.BASIC]: "Basic",
    [SUBSCRIPTIONS_KEYS.TRIAL]: "Trial",
  }?.[subscriptionKey] ?? null);

export const getSubscriptionOptionShort = (subscriptionOptionKey) =>
  ({
    [SUBSCRIPTIONS_OPTION_KEYS.YEARLY]: "Yearly",
    [SUBSCRIPTIONS_OPTION_KEYS.MONTHLY]: "3 Months",
    [SUBSCRIPTIONS_OPTION_KEYS.WEEKLY]: "Weekly",
  }?.[subscriptionOptionKey] ?? null);

export const getSubscriptionOptionFull = (subscriptionOptionKey) =>
  ({
    [SUBSCRIPTIONS_OPTION_KEYS.YEARLY]: "Yearly Subscription",
    [SUBSCRIPTIONS_OPTION_KEYS.MONTHLY]: "3 Months Subscription",
    [SUBSCRIPTIONS_OPTION_KEYS.WEEKLY]: "Weekly Subscription",
  }?.[subscriptionOptionKey] ?? null);

export const getSubscriptionStatusLabel = (subscriptionStatus) =>
  ({
    [EXTEND_PERIOD_STATUSES.PROCESS]: "Process",
    [EXTEND_PERIOD_STATUSES.COMPLETED]: "Completed",
    [EXTEND_PERIOD_STATUSES.CANCELED]: "Canceled",
  }?.[subscriptionStatus] ?? "Undefined");

export const getSubscriptionStatusBadgeType = (subscriptionStatus) =>
  ({
    [EXTEND_PERIOD_STATUSES.PROCESS]: STATUS_BADGE_TYPES.WARNING,
    [EXTEND_PERIOD_STATUSES.COMPLETED]: STATUS_BADGE_TYPES.SUCCESS,
    [EXTEND_PERIOD_STATUSES.CANCELED]: STATUS_BADGE_TYPES.DANGER,
  }?.[subscriptionStatus] ?? STATUS_BADGE_TYPES.LIGHT);

export const getSubscriptionPlanLabel = (subscriptionPlan) =>
  ({
    [SUBSCRIPTION_PLANS.TRIAL]: "Trial",
    [SUBSCRIPTION_PLANS.BASIC]: "Basic",
    [SUBSCRIPTION_PLANS.ADVANCED]: "Advanced",
    [SUBSCRIPTION_PLANS.MIX]: "Mix",
  }?.[subscriptionPlan] ?? "Undefined");

export const getSubscriptionPlanBadgeType = (subscriptionPlan) =>
  ({
    [SUBSCRIPTION_PLANS.TRIAL]: STATUS_BADGE_TYPES.LIGHT,
    [SUBSCRIPTION_PLANS.BASIC]: STATUS_BADGE_TYPES.WARNING,
    [SUBSCRIPTION_PLANS.ADVANCED]: STATUS_BADGE_TYPES.SUCCESS,
    [SUBSCRIPTION_PLANS.MIX]: STATUS_BADGE_TYPES.PRIMARY,
  }?.[subscriptionPlan] ?? STATUS_BADGE_TYPES.LIGHT);

export const getSubscriptionPhaseLabel = (subscriptionPhase) =>
  ({
    [SUBSCRIPTION_PHASES.SUBSCRIBED]: "Subscribed",
    [SUBSCRIPTION_PHASES.ALMOST_FINISHED]: "Almost Finished",
    [SUBSCRIPTION_PHASES.FINISHED]: "Finished",
  }?.[subscriptionPhase] ?? "Undefined");

export const getSubscriptionPhaseBadgeType = (subscriptionPhase) =>
  ({
    [SUBSCRIPTION_PHASES.SUBSCRIBED]: STATUS_BADGE_TYPES.WARNING,
    [SUBSCRIPTION_PHASES.ALMOST_FINISHED]: STATUS_BADGE_TYPES.LIGHT,
    [SUBSCRIPTION_PHASES.FINISHED]: STATUS_BADGE_TYPES.DANGER,
  }?.[subscriptionPhase] ?? STATUS_BADGE_TYPES.DANGER);

export const getSubscriptionPeriodLabel = (subscriptionPeriod) =>
  ({
    [SUBSCRIPTION_PERIODS.YEARLY]: "Yearly",
    [SUBSCRIPTION_PERIODS.MONTHLY]: "Monthly",
    [SUBSCRIPTION_PERIODS.WEEKLY]: "Weekly",
  }?.[subscriptionPeriod] ?? "Undefined");
